module.exports = {
	"lang" : "th",
	"sidebar.section.main_menu": "เมนูหลัก",
	"sidebar.fleet_monitoring": "Fleet Monitoring",
	"sidebar.dashboard": "Dashboard",
	"sidebar.dashboard_fleetgroup": "Fleet Group Dashboard",
	"sidebar.dashboard_fuel": "Fuel Monitoring Dashboard",
	"sidebar.dashboard_notification": "Notification Dashboard",
	"sidebar.dashboard_fleetconcrete": "Concrete Dashboard",
	"sidebar.delivery_order" : "แผนงาน DO",
	"sidebar.plants" : "แพล้นท์ปูน",
	"sidebar.customer_sites" : "ไซด์งาน",
	"sidebar.documents" : "เอกสารและใบบันทึก",
	"sidebar.weight_checkpoint" : "ใบบันทึกด่านชั่งน้ำหนัก",
	"sidebar.plans" : "แผนงาน",
	"sidebar.plan_group" : "กำหนดการแบบกลุ่ม",
	"sidebar.plan_schedule" : "กำหนดการวิ่งรถ",
	"sidebar.plan_schedule_import" : "นำเข้าข้อมูลกำหนดการวิ่งรถ",
	"sidebar.plan_line" : "สายเดินรถ",
	"sidebar.plan_route" : "แผนเส้นทาง",
	"sidebar.settings": "การตั้งค่า",
	"sidebar.settings_fleet": "รถที่ติดตั้ง GPS (กลุ่มรถ)",
	"sidebar.settings_fleet_s1": "ความเร็วรถ, ชม. เครื่องยนต์",
	"sidebar.settings_fleet_group": "กลุ่มรถ (User)",
	"sidebar.settings_fleet_category": "กลุ่มประเภทรถ",
	"sidebar.settings_fleet_division": "กลุ่มหน่วยรถ",
	"sidebar.settings_fleet_zone": "กลุ่มโซนพื้นที่รถ",
	"sidebar.settings_fleet_channel": "กลุ่ม Channel ขนส่ง",
	"sidebar.settings_user": "ผู้ใช้งานระบบ",
	"sidebar.settings_notification": "การแจ้งเตือน",
	"sidebar.settings_notifytoken": "กลุ่มแจ้งเตือน (Line, App, etc.)",
	"sidebar.concrete":"งานโม่ปูน",
	"sidebar.concrete_plant_search":"ค้นหาแพล้นท์ใกล้เคียง",
	"sidebar.concrete_list":"รายการ DO",
	"sidebar.concrete_customer_sites":"ไซด์งาน",
	"sidebar.concrete_projects":"ติดตามสถานะไซด์งาน",
	"sidebar.concrete_plants":"ติดตามสถานะแพล้นท์",

	"sidebar.report": "รายงาน",
	"sidebar.report.trip": "การวิ่งรถ",
	"sidebar.report.trip_default": "การวิ่งรถ",
	"sidebar.report.trip_consumption": "การวิ่งรถ+การใช้เชื้อเพลิง",
	"sidebar.report.trip_parking": "สถานที่ที่รถจอด",
	"sidebar.report.summary": "สรุปและวิเคราะห์",
	"sidebar.report.trip_summarybehavior": "ประเมินการขับขี่",
	"sidebar.report.summary_daily": "การใช้งานรถรายวัน",
	"sidebar.report.notification_event": "การแจ้งเตือน-เหตุการณ์",
	"sidebar.report.notification_action": "การแจ้งเตือนและตรวจสอบ",
	"sidebar.report.policy": "นโยบายการใช้รถ",
	"sidebar.report.policy_overspeed": "ความเร็วเกินกำหนด (โดยละเอียด)",
	"sidebar.report.policy_overspeed_daily": "ความเร็วเกินกำหนด (สรุปรายวัน)",
	"sidebar.report.policy_overspeed_monthly": "ความเร็วเกินกำหนด (สรุปรายเดือน)",
	"sidebar.report.policy_idleoverlimited": "จอดติดเครื่องนานเกินกำหนด",
	"sidebar.report.detail": "ข้อมูล GPS",
	"sidebar.report.detail_engine_state": "ประวัติสถานะเครื่องยนต์",
	"sidebar.report.detail_gpsinfo": "ข้อมูลจากอุปกรณ์โดยละเอียด",
	"sidebar.report.detail_gpsinfo_signal": "ข้อมูลตรวจสอบระดับสัญญาณ",
	"sidebar.report.detail_gpsinfo_event": "ข้อมูลเฉพาะเหตุการณ์",
	"sidebar.report.detail_gpsinfo_address": "ข้อมูลตำแหน่งที่อยู่",
	"sidebar.report.bus_work": "งานรถทัวร์",
	"sidebar.report.bus_tripplan": "การเดินทาง (เทียบกับแผนงาน)",
	"sidebar.report.bus_parkingplan": "รายงานการจอดรถตามพื้นที่จุดจอด",
	"sidebar.report.bus_summarytripidle": "รายงานสรุปการจอดติดเครื่องยนต์",
	"sidebar.report.bus_summarytripscore": "รายงานผลการขับ-รายเที่ยว",
	"sidebar.report.bus_summarytripdriverscore": "รายงานผลการขับ-รายบุคคล",
	"sidebar.report.bus_summaryontime": "รายงานสรุปรถถึงปลายทางล่าช้า",
	"sidebar.report.bus_summarytripdelay": "รายงานรถออกต้นทางช้าเกิน 5 นาที",
	"sidebar.report.bus_summarytripdelayearly": "รถถึงปลายทางช้า-เร็ว",
	"sidebar.report.bus_parkingoverplan": "รถจอดเกินเวลาตามแผน (รายสาขา) เกิน 5 นาที",
	"sidebar.report.bus_parkingoveratdest": "รถจอดติดเครื่องต่อเนื่องที่ปลายทางเกินกำหนด (มากกว่า 5 นาที)",
	"sidebar.report.bus_parkingoutplan": "รถจอดนอกจุดจอดตามแผน",
	"sidebar.report.bus_notparkingonplan": "รถไม่จอดตามจุดที่แผนกำหนด",
	"sidebar.report.bus_checkingdaily": "ตรวจเช็คสัญญาณประจำวัน (ระยะทางตามคิว)",
	"sidebar.report.bus_summarytripdelaycarpot": "รถออกอู่ช้าเกิน (15 นาที)",
	"sidebar.report.bus_summarytripplan": "การใช้รถ",
	"sidebar.report.bus_loginonplan": "การรูดบัตร พขร. (ตามคิวรถในวัน)",
	"sidebar.report.bus_outrouteplan": "รถวิ่งออกนอกเส้นทาง",
	"sidebar.report.concrete_work": "งานรถโม่ปูน",
	"sidebar.report.concrete_work_by_do_time": "เวลาการทำงานของรถโม่ปูน (DO)",
	"sidebar.report.concrete_work_by_do": "การปฏิบัติงานตาม DO",
	"sidebar.report.concrete_work_by_vehicle": "การปฏิบัติงานของรถโม่",
	"sidebar.report.concrete_work_summary_monthly" : "สรุปข้อมูลรายเดือน",
	"sidebar.report.concrete_work_trip" : "การขับรถออกนอกเส้นทาง",
	"sidebar.report.concrete_workdodistance": "เปรียบเทียบระยะทางจากแผนงาน",
	"sidebar.report.driving_behavior": "พฤติกรรมการขับขี่",
	"sidebar.report.fuel_consumption": "น้ำมันเชื้อเพลิงของรถ",
	"sidebar.report.oiler": "แท็งก์น้ำมัน-รถOiler",
	"sidebar.report.sensor_option": "อุปกรณ์เสริมอื่นๆ",
	"sidebar.report.documents": "เอกสารและใบบันทึก",
	"sidebar.report.documents_weight_checkpoint" : "บันทึกด่านชั่งน้ำหนัก",
	"sidebar.report.graph_oiler_level": "กราฟแท็งก์น้ำมัน",
	"sidebar.report.fuel_oiler_level": "ตรวจจับการเติม-จ่ายน้ำมัน",
	"sidebar.report.chart_fuel_level": "กราฟน้ำมัน",
	"sidebar.report.fuel_level_analysis": "ตรวจจับการเติม-ขโมยน้ำมัน",
	"sidebar.report.fuel_refilling": "ใบบันทึกการเติมน้ำมัน",
	"sidebar.report.work": "การปฏิบัติงาน",
	"sidebar.report.work_service": "สรุปการทำงานของรถบริการ",

	"status" : "สถานะ",
	"status.All": "ทั้งหมด",
	"status.Draft": "รอตรวจสอบ",
	"status.Checking": "กำลังตรวจสอบ",
	"status.Verified": "ยืนยันข้อมูล",
	"status.Cancelled": "ยกเลิก",
	"status.Inactive": "Inactive",
	"status.Active": "Active",
	"status.Y": "Active",
	"status.N": "Inactive",
	"button.checking" : "ตรวจสอบ",
	"button.verified" : "ยืนยันข้อมูล",

	"dashboard.fleet_status" : "สถานะปัจจุบัน",
	"dashboard.fleet_status.harvesting" : "กำลังตัด",
	"dashboard.fleet_status.running" : "วิ่งรถ",
	"dashboard.fleet_status.moving" : "ย้ายแปลง",
	"dashboard.fleet_status.idle" : "จอดติดเครื่อง",
	"dashboard.fleet_status.off" : "ดับเครื่อง",
	"dashboard.fleet_status.offline" : "สัญญาณหาย",
	"dashboard.fleet_status.not_update" : "ไม่อัพเดท",

	"dashboard.fleet_location" : "พื้นที่",
	"dashboard.hour_harvest_accu" : "ชม.การตัดสะสม",
	"dashboard.hour_engine_acc" : "ชม.การเดินทาง",
	"dashboard.distance_out_field" : "ระยะทางย้ายแปลง",
	"dashboard.hour_plow_accu" : "ชม.การตัดไถ",
	"dashboard.harvest_accu" : "ปริมาณการตัดสะสม",
	"dashboard.fuel_consumption_accu" : "ปริมาณการใช้น้ำมันเชื้อเพลิง",
	"dashboard.fleet_category" : "ประเภทรถ",
	"dashboard.oiler_draining" : "กำลังจ่ายน้ำมัน",
	"dashboard.service_progress" : "กำลังซ่อมบำรุง",
	"dashboard.service_progress_hour" : "ซ่อมมาแล้ว",

	"dashboard_fuel.current_volume" : "CURRENT FUEL VOLUME",
	"dashboard_fuel.history_volume" : "FUEL VOLUME HISTORY",
	"dashboard_fuel.station_fusage" : "ปริมาณการจ่ายน้ำมันจากสถานีให้รถระบุโดยพิกัด",
	"dashboard_fuel.station_fusage_1usage" : "ปริมาณที่จ่าย (ลิตร)",
	"dashboard_fuel.station_dusage" : "ปริมาณการจ่ายน้ำมันจากสถานีให้ผู้ขับระบุตัวตน",
	"dashboard_fuel.station_dusage_1usage" : "ปริมาณที่จ่าย (ลิตร)",
	"dashboard_fuel.fusage" : "ปริมาณการจ่ายน้ำมันจากรถขนน้ำมันให้รถระบุโดยพิกัด",
	"dashboard_fuel.fusage_1usage" : "ปริมาณที่จ่าย (ลิตร)",
	"dashboard_fuel.dusage" : "ปริมาณการจ่ายน้ำมันจากรถขนน้ำมันให้ผู้ขับระบุตัวตน",
	"dashboard_fuel.dusage_1usage" : "ปริมาณที่จ่าย (ลิตร)",
	"dashboard_fuel.olierrefill" : "ปริมาณการเติมน้ำมันให้รถขนน้ำมัน (รายวัน)",
	"dashboard_fuel.olierrefill_1refilltooiler" : "เติมเข้ารถขนน้ำมัน (ลิตร)",
	"dashboard_fuel.olierrefill_2drainfromstation" : "จ่ายออกจากสถานี (ลิตร)",
	"dashboard_fuel.fuelstationrefill" : "ปริมาณการเติมน้ำมันเข้าสถานี (รายวัน)",
	"dashboard_fuel.fuelstationrefill_1volume" : "ปริมาณที่เติม (ลิตร)",
	"dashboard_fuel.fuelstationrefill_value" : "มูลค่า (1000 บาท)",
	"dashboard_fuel.total_fuel_volume" : "ปริมาณน้ำมันทั้งหมด",
	"dashboard_fuel.total_fuel_value" : "มูลค่าน้ำมันทั้งหมด",
	"dashboard_fuel.current_volume_fuelstation" : "สถานีน้ำมัน",
	"dashboard_fuel.current_volume_oiler" : "รถน้ำมัน",
	"dashboard_fuel.fuel": "เชื้อเพลิง",
	"dashboard_fuel.capable": "ความจุ (ลิตร)",
	"dashboard_fuel.volume": "คงเหลือ (ลิตร)",

	"dashboard_notification.state": "การตรวจสอบ",
	"dashboard_notification.plan": "เที่ยวรถ",
	"dashboard_notification.driver_name": "ผู้ขับ",
	"dashboard_notification.driver_phone": "เบอร์โทร",
	"dashboard_notification.notify_range": "เวลาเกิดเหตุ",

	"fleet.title" : "การตั้งค่าตัวรถ",
	"fleet.title_info" : "ข้อมูลรถ",
	"fleet.title_group" : "การตั้งค่ากลุ่มรถ",
	"fleet.title_driver" : "ข้อมูลผู้ขับขี่",
	"fleet.title_fuel" : "การตั้งค่าน้ำมัน",
	"fleet.title_policy" : "การตั้งค่าความเร็ว, ชม เครื่องยนต์ และอื่นๆ",
	"fleet.fleet_group" : "กลุ่มรถ (User)",
	"fleet.fleet_category" : "ประเภทรถ",
	"fleet.fleet_division" : "หน่วยรถ",
	"fleet.regist_no" : "เบอร์รถ",
	"fleet.extend": "ทะเบียนพ่วง",
	"fleet.imei" : "IMEI / Serial No",
	"fleet.phone_no" : "เบอร์ซิม",
	"fleet.gps_model" : "รุ่นเครื่อง",
	"fleet.status.Active":"เปิดใช้งาน",
	"fleet.status.Inactive":"ถอด (ยกเลิก)",
	"fleet.vehicle_role": "การติดตั้ง",
	"fleet.chassis_no": "เลขตัวถัง",
	"fleet.vehicle_brand": "ยี่ห้อรถ",
	"fleet.zone": "โซนพื้นที่รถ",
	"fleet.channel": "Channel ขนส่ง",
	"fleet.driver": "ผู้ขับขี่หลัก",
	"fleet.driver_phone": "เบอร์โทรผู้ขับ",
	"fleet.fuel_capable": "ขนาดถัง",
	"fleet.vehicle_model": "ค่าน้ำมัน (รุ่นรถ)",
	"fleet.fuel_cost": "ต้นทุนค่าเชื้อเพลิง",
	"fleet.fuel_rate": "อัตราสิ้นเปลืองเชื้อเพลิง",
	"fleet.fuel_hour_rate": "อัตราสิ้นเปลืองเชื้อเพลิง (ขณะจอดแช่)",
	"fleet.limited_speed": "ความเร็วเกินกำหนด",
	"fleet.limited_speed_time": "เป็นระยะเวลา",
	"fleet.limited_stop": "จอดติดเครื่องเกินกำหนด",
	"fleet.limited_work": "วิ่งต่อเนื่องเกินกำหนด",
	"fleet.rest_time": "โดยไม่หยุดพัก",
	"fleet.limited_work_daily": "ระยะเวลาวิ่งรถในรอบ 24 ชม.เกิน",
	"fleet.limited_rpm": "รอบเครื่องเกินกำหนด",
	"fleet.limited_rpm_time": "เป็นระยะเวลา",

	"custombox.notifications" : "การแจ้งเตือน",
	"custombox.plans" : "กำหนดการเดินรถ",
	"custombox.historys" : "ประวัติย้อนหลัง",
	"custombox.map" : "แผนที่",
	"custombox.plan_info" : "เที่ยวรถ",

	"report.query_operation" : "เรียกดูข้อมูล",
	"report.print_date" : "วันที่พิมพ์",
	"report.start_date" : "จากวันที่",
	"report.end_date" : "ถึงวันที่",
	"report.start_time" : "ตั้งแต่เวลา",
	"report.end_time" : "ถึงเวลา",
	"report.vehicle" : "เบอร์/ทะเบียนรถ",
	"report.limited_stop" : "ระยะเวลา (นาที)",
	"report.parking_state" : "ประเภทการจอด",
	"report.trip_option" : "รูปแบบรายงาน",
	"report.busline" : "สายการเดินรถ",
	"report.group_id" : "กลุ่มรถ",
	"report.duration_time" : "ระยะเวลาไม่น้อยกว่า",
	"report.duration_hint" : "hh:mm:ss",
	"report.park_location": "สถานที่",
	"report.notification_text": "หัวข้อแจ้งเตือน",

	"coordinate.lat": "พิกัดละติจูด",
	"coordinate.lng": "พิกัดลองจิจูด",

	"farmer.information": "ข้อมูลชาวไร่",
	"farmer.address_no" : "บ้านเลขที่",
	"farmer.quota": "โควต้า",
	"farmer.name": "ชื่อ-สกุล",
	"farmer.address": "ที่อยู่",
	"farmer.phone": "เบอร์โทร",
	"farmer.card": "บัตรประชาชน",

	"staff.profile": "ข้อมูลเจ้าหน้าที่",
	"staff.code": "รหัสพนักงาน",
	"staff.name": "ชื่อ-สกุลเจ้าหน้าที่",
	"staff.division": "หน่วย",
	"staff.department": "แผนก",
	"staff.phone": "เบอร์โทร",
	"staff.email": "อีเมลล์",
	"staff.profile_image": "รูปถ่าย",

	"transportation.no" : "รหัสเที่ยวรถ",
	"transportation.vehicle" : "เบอร์รถบรรทุก",
	"transportation.start_date_time" : "ออกจากแปลง",
	"transportation.end_date_time" : "ถึงโรงงาน",
	"transportation.start_date" : "วันที่ออกจากแปลง",
	"transportation.start_time" : "เวลา",
	"transportation.end_date" : "วันที่ถึงโรงงาน",
	"transportation.end_time" : "เวลา",
	"transportation.distance" : "ระยะทาง",
	"transportation.duration" : "ระยะเวลา",
	"transportation.speed_avg" : "ความเร็วเฉลี่ย",
	"transportation.speed_max" : "ความเร็วสูงสุด",
	"transportation.field" : "แปลงปลูก",
	"transportation.ton" : "จำนวนตันชั่ง",

	"weight_checkpoint.title": "แบบบันทึกการขนส่งสินค้าเข้าออก",
	"weight_checkpoint.title_photos_in": "ภาพบันทึกการเข้า",
	"weight_checkpoint.title_photos_out": "ภาพบันทึกการออก",
	"weight_checkpoint.photos_in": "รูปภาพขาเข้า (น้ำหนัก, บน, หน้า, หลัง)",
	"weight_checkpoint.photos_out": "รูปภาพขาออก (น้ำหนัก, บน, หน้า, หลัง)",
	"weight_checkpoint.doc_no": "เลขที่เอกสาร",
	"weight_checkpoint.station": "สถานี",
	"weight_checkpoint.doc_date": "วันที่",
	"weight_checkpoint.in_time": "เวลาเข้า",
	"weight_checkpoint.out_time": "เวลาออก",
	"weight_checkpoint.vehicle_no": "เบอร์รถ",
	"weight_checkpoint.customer": "ลูกค้า",
	"weight_checkpoint.info_product": "ข้อมูลสินค้า",
	"weight_checkpoint.remark": "ข้อมูลผู้เกี่ยวข้อง",
	"weight_checkpoint.verified_by": "ผู้ตรวจสอบ",
	"weight_checkpoint.verified_date": "วันที่ตรวจสอบ",
	"weight_checkpoint.vehicle_qrcode": "ระบุตัวตนรถบรรทุก",
	"weight_checkpoint.doc_qrcode": "อ้างอิงเอกสาร",
	"weight_checkpoint.status": "สถานะ",
	"weight_checkpoint.status.All": "ทั้งหมด",
	"weight_checkpoint.status.Draft": "รอตรวจสอบ",
	"weight_checkpoint.status.Verified": "ตรวจสอบแล้ว",
	"weight_checkpoint.status.Cancelled": "ยกเลิก",

	"plan_group.title": "กำหนดการแบบกลุ่ม",
	"plan_group.doc_date": "ตั้งแต่วันที่",
	"plan_group.end_doc_date": "ถึงวันที่",
	"plan_group.code": "สายเดินรถ",
	"plan_group.item_vehicle": "เบอร์รถ",
	"plan_group.item_sdate": "วันที่ออกจากต้นทาง",
	"plan_group.item_stime": "เวลาออกจากต้นทาง",
	"plan_group.item_ddate": "วันที่ถึงปลายทาง",
	"plan_group.item_dtime": "เวลาถึงปลายทาง",
	"plan_group.item_s2date": "วันที่ออกจากปลายทาง",
	"plan_group.item_s2time": "เวลาออกจากปลายทาง",
	"plan_group.item_edate": "วันที่กลับถึงต้นทาง",
	"plan_group.item_etime": "เวลากลับถึงต้นทาง",
	"plan_group.item_rest": "จอดปลายทาง (นาที)",
	"plan_group.item_trip": "ประเภท Trip",
	"plan_group.item_plan_route1": "แผนเส้นทาง (ขาไป)",
	"plan_group.item_plan_route2": "แผนเส้นทาง (ขากลับ)",

	"plan_schedule.title": "กำหนดการวิ่งรถ",
	"plan_schedule.startdate": "วันที่ออก",
	"plan_schedule.enddate": "วันที่ถึง",
	"plan_schedule.start_time": "กำหนดออก",
	"plan_schedule.end_time": "กำหนดถึง",
	"plan_schedule.actual_time": "เวลาจริง",
	"plan_schedule.duration": "เวลาเดินทางรวม",
	"plan_schedule.rest": "เวลาจอดพักรวม",
	"plan_schedule.vehicle": "เบอร์รถ",
	"plan_schedule.driver": "ผู้ขับหลัก",
	"plan_schedule.driver1": "ผู้ขับเสริม 1",
	"plan_schedule.driver2": "ผู้ขับเสริม 2",
	"plan_schedule.busline_name": "สายเดินรถ",
	"plan_schedule.plan_name": "แผนเส้นทาง",
	"plan_schedule.line": "สายเดินรถ",
	"plan_schedule.item_seq": "จุดที่",
	"plan_schedule.item_node": "จุดจอด",
	"plan_schedule.item_sdate": "วันที่ถึง",
	"plan_schedule.item_stime": "เวลาถึง",
	"plan_schedule.item_edate": "วันที่ออก",
	"plan_schedule.item_etime": "เวลาออก",
	"plan_schedule.item_rest": "จอดพัก",
	"plan_schedule.save_hint": "กรุณากดบันทึกเพื่ออัพเดทข้อมูลตารางสถานที่จุดจอดและการคำนวณเวลา",

	"plan_line.title": "สายเดินรถ",
	"plan_line.code": "รหัสสายเดินรถ",
	"plan_line.line_name": "ชื่อสายเดินรถ",
	"plan_line.name": "ขาไป",
	"plan_line.name2": "ขากลับ",

	"plan_route.title": "แผนเส้นทาง",
	"plan_route.ref_time": "เวลาออกอ้างอิง",
	"plan_route.plan_name": "รหัสเส้นทาง",
	"plan_route.route_name": "ชื่อเส้นทาง",
	"plan_route.line": "สายเดินรถ",
	"plan_route.routes": "จุดจอด",
	"plan_route.duration": "เวลาเดินทางรวม",
	"plan_route.rest": "เวลาจอดพักรวม",
	"plan_route.duration_hint": "** การคำนวณ ไม่นับเวลาการจอดพักที่จุดแรก **",
	"plan_route.duration_map": "เวลาเดินทางรวม (แผนที่)",
	"plan_route.distance": "ระยะทางรวม (แผนที่)",
	"plan_route.map_hint": "** คำนวณโดยประมาณจาก Google Map หากมีการสร้างเส้นทางไว้ในระบบ **",
	"plan_route.item_seq": "จุดที่",
	"plan_route.item_node": "จุดจอด",
	"plan_route.item_triphr": "เดินทาง (ชม)",
	"plan_route.item_tripmin": "เดินทาง (นาที)",
	"plan_route.item_resthr": "จอดพัก (ชม)",
	"plan_route.item_restmin": "จอดพัก (นาที)",
	"plan_route.item_stime": "เวลาถึง",
	"plan_route.item_etime": "เวลาออก",

	"customer_site.title":"ไซด์งาน",
	"customer_site.code":"รหัสไซด์งาน",
	"customer_site.name":"ชื่อไซด์งาน",
	"customer_site.cust_code":"ลูกค้า",
	"customer_site.email":"อีเมลล์",
	"customer_site.line_token":"Line Token",
	"customer_site.link":"Link Code",
	"status.customer_site.notify":"แจ้ง Link",

	"fleet_group.title" : "กลุ่มรถ (User)",
	"fleet_group.name" : "ชื่อกลุ่มรถ",
	"fleet_category.title" : "ประเภทรถ",
	"fleet_category.name" : "ชื่อประเภทรถ",
	"fleet_zone.code" : "รหัสโซนพื้นที่",
	"fleet_zone.text" : "ชื่อโซนพื้นที่รถ",
	"fleet_channel.code" : "รหัส Channel",
	"fleet_channel.text" : "ชื่อ Channel",
	"fleet_division.code" : "รหัสหน่วย",
	"fleet_division.text" : "ชื่อหน่วย",

	"concrete.plant":"แพล้นท์",
	"concrete.site":"ไซด์งาน",

	"concrete_project.title": "โครงการ",
	"concrete_project.no": "เลขที่",
	"concrete_project.customer_site": "ไซด์งาน",

	"concrete_plant_search.plant_num":"จำนวนแพล้นท์ที่ค้นหา",
	"concrete_plant_search.distance":"ระยะทาง",
	"concrete_plant_search.duration":"ประมาณเวลา",
	"concrete_plant_search.lat":"ละติจูด",
	"concrete_plant_search.lng":"ลองจิจูด",

	"concrete_plants.title":"แพล้นท์",
	"concrete_plants.plant": "แพล้นท์งาน",
	"concrete_plants.produce" : "ยอดผลิตรวม",
	"concrete_plants.order" : "ยอดสั่งรวม",
	"concrete_plants.produce_site" : "ยอดผลิต(คิว)",
	"concrete_plants.order_site" : "ยอดสั่ง(คิว)",
	"concrete_plants.no" : "No",
	"concrete_plants.do_no" : "เลขที่ DO",
	"concrete_plants.site" : "ไซด์งาน",
	"concrete_plants.booking": "คิว",
	"concrete_plants.vehicle_no": "เลขรถ",
	"concrete_plants.amount":"จำนวน",
	"concrete_plants.booking_no":"Booking No",
	"concrete_plants.customer_name":"บริษัท",
	"concrete_plants.project_name" : "โครงการ",
	"concrete_plants.sale_name" : "Sale",
	"concrete_plants.sale_phone" : "เบอร์โทร",
	"concrete_plants.contact_site" : "ช่าง",
	"concrete_plants.mix_code" : "รหัสสินค้า",
	"concrete_plants.strength" : "Strength",
	"concrete_plants.slump" : "Slump",
	"concrete_plants.estimate_time": "วันที่-เวลา",
	"concrete_plants.produce_time": "เวลาผลิต",
	"concrete_plants.comment": "Description",
	"concrete_plants.plant_no": "รหัสแพล้นท์",
	"concrete_plants.viewonmap": "ดูบนแผนที่",

	"concrete_projects.title" : "โครงการ",
	"concrete_projects.customer_site" : "ไซด์งาน",
	"concrete_projects.produce" : "ยอดผลิตรวม",
	"concrete_projects.order" : "ยอดสั่งรวม",
	"concrete_projects.no" : "No",
	"concrete_projects.do_no" : "เลขที่ DO",
	"concrete_projects.booking": "คิว",
	"concrete_projects.vehicle_no": "เลขรถ",
	"concrete_projects.amount":"จำนวน",
	"concrete_projects.booking_no":"Booking No",
	"concrete_projects.customer_name":"บริษัท",
	"concrete_projects.project_name" : "โครงการ",
	"concrete_projects.sale_name" : "Sale",
	"concrete_projects.sale_phone" : "เบอร์โทร",
	"concrete_projects.contact_site" : "ช่าง",
	"concrete_projects.mix_code" : "รหัสสินค้า",
	"concrete_projects.strength" : "Strength",
	"concrete_projects.slump" : "Slump",
	"concrete_projects.estimate_time": "วันที่-เวลา",
	"concrete_projects.produce_time": "เวลาผลิต",
	"concrete_projects.comment": "Description",
	"concrete_projects.plant_no": "แพล้นท์",
	"concrete_projects.viewonmap": "ดูบนแผนที่",

	"error.info_not_ready" : "ข้อมูลไม่พร้อมใช้งาน อาจเนื่องจากยังไม่ได้บันทึกข้อมูลพิกัดสถานที่ในระบบ",

	"user.title" : "ผู้ใช้งานระบบ",
	"user.title_profile" : "ข้อมูลผู้ใช้งานระบบ",
	"user.title_authorized" : "สิทธิ์การใช้งาน",
	"user.username" : "Username",
	"user.password" : "รหัสผ่าน",
	"user.role" : "บทบาทในระบบ",
	"user.name" : "ชื่อ-สกุล",
	"user.email" : "อีเมลล์",
	"user.phone" : "เบอร์โทร",
	"user.info_group" : "สิทธิ์การใช้งานระบบ",
	"user.user_authorized" : "ระดับสิทธิ์ในระบบ Fleet",
	"user.group_ids" : "กลุ่ม (ดูรถเฉพาะกลุ่ม)",
	"user.division_ids" : "หน่วย (ดูรถเฉพาะหน่วย)",
	"user.role_hint" :  "00 : เข้าถึงทุกสิทธิ์ทุกเมนูรวมทั้งการตั้งค่ารถและUser<br/>10 : เข้าถึงทุกเมนู<br/>19 : จัดการข้อมูลแปลงพื้นที่,วางแผน,สั่งการ,ตรวจสอบ,ดูรายงาน,ติดตามรถ<br/>20 : จัดการข้อมูลแปลงพื้นที่<br/>30 : วางแผน,สั่งการ,ตรวจสอบ,ดูรายงาน,ติดตามรถ<br/>40 : ตรวจสอบ,ดูรายงาน,ติดตามรถ<br/>50 : ติดตามรถ",
	"user.user_authorized_hint" :  "Standard : แผนที่ Longdo<br/>Gold : แผนที่ดาวเทียม Google, POI<br/>Platinum : แผนที่ดาวเทียม Google, POI, แผนงาน, เส้นทาง, พื้นที่ Polygon",

	"ns.title" : "ลงทะเบียนการแจ้งเตือน",
	"ns.title_info" : "ตั้งค่าแจ้งเตือน",
	"ns.title_condition" : "เงื่อนไขเพิ่มเติม",
	"ns.notification_type":"การแจ้งเตือน",
	"ns.subscriber":"ช่องทางแจ้งเตือน",
	"ns.color":"ระดับ (สี)",
	"ns.notify_filter":"แจ้งเตือนเฉพาะ",
	"ns.notify_filter_hint":"แจ้งเตือนทุกรายการ",
	"ns.notify_key":"รหัสอ้างอิง",
	"ns.notify_key_hint":"รหัสกรองข้อมูลเฉพาะ",
	"ns.notify_to":"แจ้งเตือนไปยัง",
	"ns.notify_name":"ผู้รับการแจ้งเตือน",
	"ns.notify_token":"Token ของผู้รับการแจ้งเตือน",
	"ns.msg":"ข้อความแจ้งเตือน",
	"ns.msg_hint":"ใช้ข้อความแจ้งเตือนของระบบ",
	"ns.msg_scheme":"ข้อความแจ้งเตือนของระบบ",

	"ntk.title" : "กลุ่มแจ้งเตือน",
	"ntk.token_type":"ประเภท Token",
	"ntk.name":"ชื่อกลุ่ม/บุคคล",
	"ntk.token":"Token ของผู้รับการแจ้งเตือน",
	"ntk.status.A":"Active",
	"ntk.status.N":"Inactive",

	"field.role" : "ประเภท",
	"information" : "ข้อมูล",

	"v_state.RUN" : "วิ่ง",
  "v_state.ON_RUN" : "กำลังวิ่ง",
  "v_state.ON_RUN_EXCEED" : "วิ่งเร็วเกิน",
  "v_state.ON_STOP" : "จอดติดเครื่อง",
  "v_state.OFF" : "จอดดับเครื่อง",
  "v_state.Offline" : "สัญญาณหาย",
	"v_state.NO_UPDATE" : "ไม่อัพเดท",
  "v_state.Unknown" : "ไม่ทราบสถานะ",

	"working_state.elevating" : "ลำเลียง",
	"working_state.transporting" : "กำลังขนส่ง",
	"working_state.transported" : "รอคิว",

	"concrete_status" : "สถานะรถโม่ปูน",

	"notify.please_relogin_to_apply_change" : "เปลี่ยนแปลงข้อมูลการตั้งค่าเรียบร้อย กรุณาLogin ใหม่อีกครั้ง เพื่อใช้งานการตั้งค่าใหม่",
	"warning.gps_offline_over":"การเชื่อมต่อขาดหายเกิน 7 วัน กรุณานำรถเข้าตรวจเช็ค",

	"map.fields" : "แปลงปลูก",

	"filter.start_date" : "จากวันที่",
	"filter.end_date" : "ถึงวันที่",

	"text.field_address" : "ที่ตั้งแปลง",
	"text.district" : "ตำบล",
	"text.city" : "อำเภอ",
	"text.province" : "จังหวัด",
	"text.total_distance" : "ระยะทางรวม",
	"text.total_hour" : "ระยะเวลารวม",
	"text.total_work_hour" : "ชั่วโมงทำงานรวม",
	"text.speed_avg" : "ความเร็วเฉลี่ย",
	"text.speed_max" : "ความเร็วสูงสุด",
	"text.time_range_travel" : "ช่วงเวลาที่เดินทาง",
	"text.time_range_in_out_field" : "ช่วงเวลาที่เข้าออก-ออกแปลง",
	"text.please_specify_vehicle_datetime_range" : "กรุณาเลือกรถและช่วงเวลาที่ต้องการเรียกดูข้อมูล",
	"text.farmer_name" : "ชื่อเกษตรกร",
	"text.factory" : "โรงงาน",
	"text.ton" : "ตัน",
	"text.rai" : "ไร่",
	"text.km" : "กม",
	"text.hr" : "ชม",
	"text.minute" : "นาที",
	"text.meter" : "ม.",
	"text.kk" : "กก",
	"text.trunk" : "ลำ",
	"text.day" : "วัน",
	"text.field" : "แปลง",
	"text.round" : "เที่ยว",
	"text.person" : "ราย",
	"text.v" : "คัน",
	"text.liter" : "ลิตร",
	"text.baht" : "บาท",
	"text.work" : "งาน",
	"text.r" : "รอบ",
	"on_route":"อยู่บนเส้นทาง",
	"unidentify_driver": "ผู้ขับไม่รูดบัตร",
	"vehicle_name" : "ชื่อรถ",
	"current_vehicle_track": "ตำแหน่งปัจจุบัน",
	"driver":"ผู้ขับ",
	"notify_when" : "แจ้งเตือน",
	"total_time" : "รวมเวลา",
	"description" : "รายละเอียด"
}
