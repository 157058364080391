const moduleName = "aux"

export default {
  name : moduleName,
  api : {
    server : "http://api.magbizcloud.com:8887/",
    app_module : moduleName,
  },
  title : "AUX Air-Conditioner (Thailand)",
  description : "MagbizCloud - Management solution for smart business",
  logo : "assets/"+moduleName+"/logo.png",                // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  logo_head_text : "AUX Air Service",
  website:"https://www.facebook.com/auxthailandofficial",
  orgId:"3",
  theme : {
    primary: {
      //light: "#2ECC71",
      //main: "#229954",
      //dark: "#1E8449",
      light: "hsl(225, 100%, 40%)",
      main: "hsl(225, 100%, 30%)",
      dark: "hsl(225, 100%, 25%)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(255,255,255,0.9)",
      main: "rgb(255,255,255,0.95)",
      dark: "rgb(255,255,255,1)",
      contrastText: '#424242'
    }
  },
  menu:{
    main_menu: {
  		title: "sidebar.section.main_menu",
  		list: [
        {"menu_title": "sidebar.sites_auxproductregistration", "menu_icon": "collection-plus", "path": "/"+moduleName+"/sites/auxproductregistration"},
        {"menu_title": "sidebar.services_air", "menu_icon": "settings-square", "path": "/"+moduleName+"/services/air",},
        {"menu_title": "sidebar.services_aircalendar", "menu_icon": "calendar-alt","path": "/"+moduleName+"/services/aircalendar",},
        {"menu_title": "sidebar.warehouse", "menu_icon": "store", allowUserRole: ["admin","stock","stock_admin"], "child_routes": [
          {"menu_title": "sidebar.warehouse_PRD", "path": "/"+moduleName+"/warehouse/PRD",},
          {"menu_title": "sidebar.warehouse_PRD_type", "path": "/"+moduleName+"/warehouse/PRD_type",},
          {"menu_title": "sidebar.warehouse_PRD_brand", "path": "/"+moduleName+"/warehouse/PRD_brand",},
          {"menu_title": "sidebar.warehouse_PRD_model", "path": "/"+moduleName+"/warehouse/PRD_model",},
        ]},
      ]
  	}
  },
}
