const moduleName = "saleorder"

export default {
  name : moduleName,
  api : {
    server : "http://192.168.1.130:8890/",
    app_module : "magbiz",
  },
  title : "Magbiz Sale Order",
  description : "Sale order management system on cloud",
  logo : "assets/"+moduleName+"/logo.png",               // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  theme : {
    primary: {
      light: "rgba(66,165,245,0.8)",
      main: "rgba(66,165,245,1)",
      dark: "rgba(47,152,238,1)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(255,255,255,0.9)",
      main: "rgb(255,255,255,0.95)",
      dark: "rgb(255,255,255,1)",
      contrastText: '#424242'
    }
  },
  menu:{
    main_menu: {
  		title: "sidebar.section.main_menu",
  		list: [
  			{
  				"menu_title": "sidebar.dashboard",
  				"menu_icon": "view-dashboard",
  				"path": "/dashboard",
  				"child_routes": null
  			},
  			{
  				"menu_title": "sidebar.letter_to_dlt",
  				"menu_icon": "file-text",
  				"path": "/letter_to_dlt",
  				"child_routes": null
  			},
  			{
  				"menu_title": "sidebar.report",
  				"menu_icon": "file",
  				"path": "/report",
  				"child_routes": [
  					{
  						"menu_title": "sidebar.dashboard",
  						"menu_icon": "view-dashboard",
  						"path": "/report",
  						"child_routes": null
  					},
  					{
  						"menu_title": "sidebar.letter_to_dlt",
  						"menu_icon": "file-text",
  						"path": "/report",
  						"child_routes": null
  					},
  					{
  						"menu_title": "sidebar.report",
  						"menu_icon": "file",
  						"path": "/report",
  						"child_routes": null
  					}
  				]
  			}
  		]
  	}
  }
}
