const moduleName = "airvenger"

export default {
  name : moduleName,
  api : {
    server : "http://api.magbizcloud.com:8887/",  //"http://192.168.109.146:8887/", //
    app_module : moduleName,
    timeout: 30000
  },
  title : "Airvenger",
  description : "Management solution for smart business",
  logo : "assets/"+moduleName+"/logo.png",                // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  logo_head_text : "Airvenger Job Service",
  theme : {
    primary: {
      light: "rgba(255,147,25,0.8)",
      main: "rgba(255,147,25,1)",
      dark: "rgba(255,134,16,1)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(250,250,250,0.9)",
      main: "rgb(250,250,250,0.95)",
      dark: "rgb(250,250,250,1)",
      contrastText: '#424242'
    }
  },
  menu:{
    main_menu: {
  		title: "sidebar.section.main_menu",
  		list: [
        {"menu_title": "sidebar.services_air", "menu_icon": "settings-square", "path": "/"+moduleName+"/services/air",},
        //{"menu_title": "sidebar.services_aircalendar", "menu_icon": "calendar-check","path": "/"+moduleName+"/services/aircalendar",},
        {"menu_title": "sidebar.services_calendar", "menu_icon": "calendar-alt","path": "/"+moduleName+"/services/calendar",},
        {"menu_title": "sidebar.services_deliver_doc", "menu_icon": "receipt","path": "/"+moduleName+"/services/deliver_doc",},
      ]
  	}
  },
}
