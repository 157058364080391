const moduleName = "thanachart"

export default {
  name : moduleName,
  api : {
    server : "http://api.magbizcloud.com:8890/",
    app_module : "thanachart",
  },
  title : "Thanachart",
  description : "Job Service",
  logo : "assets/"+moduleName+"/logo.png",               // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  theme : {
    primary: {
      light: "rgba(255,147,25,0.8)",
      main: "rgba(255,147,25,1)",
      dark: "rgba(255,134,16,1)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(250,250,250,0.9)",
      main: "rgb(250,250,250,0.95)",
      dark: "rgb(250,250,250,1)",
      contrastText: '#424242'
    }
  },
  menu:{
    main_menu: {
  		title: "sidebar.section.main_menu",
  		list: [
  			//{
  			//	"menu_title": "sidebar.dashboard",
  			//	"menu_icon": "view-dashboard",
  			//	"path": "/"+moduleName+"/dashboard",
  			//	"child_routes": null
  			//},
        {
  				"menu_title": "sidebar.services",
  				"menu_icon": "settings-square",
  				"path": "/"+moduleName+"/services",
  				"child_routes": null
  			},
  		]
  	}
  }
}
