import React, { Component, Fragment } from 'react'
import {Map,TileLayer,Polygon,Polyline,Marker,Popup,Tooltip} from 'react-leaflet'

import { connect} from 'react-redux';
import {actionPerform as actionPerformApp} from '../../redux/App'

import {Geo} from '../../utils/BGO'

class LeafletMap extends Component<{}> {

  shouldComponentUpdate(nextProps, nextState){
    if(this.props.value !== nextProps.value){return true}
    return false
  }

  render() {
    if(this.props.value && this.props.geoinfo){

      let bounds = []
      let geom = []
      let geomStart = []
      let geomEnd = []
      let geomBound = []
      let geomWork = []
      let geomWork2 = []
      let markers = []
      let boundOptions = {padding: [10,10]}

      switch (this.props.geoinfo.type) {
        case "field":
          const path = Geo.getPathFromPolygon(this.props.value)
          geom = Object.values(path).reduce((objects, item) => {
            const arrObj = [item.lat, item.lng]
            objects.push(arrObj)
            return objects
          }, [])
          bounds = geom
          break;
        case "harvest": case "plow":
          const theinfo = this.props.value
          if(theinfo.paths){
            if(theinfo.paths.length > 1) {
              geom = theinfo.paths.reduce((objects, item) => {
                const arrObj = [item.lat, item.lng]
                objects.push(arrObj)
                return objects
              }, [])
            }
          }
          if(theinfo.field){
            geomBound = Object.values(Geo.getPathFromPolygon(theinfo.field)).reduce((objects, item) => {
              const arrObj = [item.lat, item.lng]
              objects.push(arrObj)
              return objects
            }, [])
            bounds = geomBound
          }else if (geom.length > 0) {
            bounds = geom
          }
          if(theinfo.working){
            geomWork = Object.values(Geo.getPathFromPolygon(theinfo.working)).reduce((objects, item) => {
              const arrObj = [item.lat, item.lng]
              objects.push(arrObj)
              return objects
            }, [])
          }
          if(theinfo.field_working){
            geomWork2 = Object.values(Geo.getPathFromPolygon(theinfo.field_working)).reduce((objects, item) => {
              const arrObj = [item.lat, item.lng]
              objects.push(arrObj)
              return objects
            }, [])
          }
          break;
        case "route":
          boundOptions = {padding: [5,5]}
          const route_info = this.props.value
          if(route_info.paths.length > 1) {
            geom = route_info.paths.reduce((objects, item) => {
              const arrObj = [item.lat, item.lng]
              objects.push(arrObj)
              return objects
            }, [])
            bounds = geom

            geomStart = Object.values(Geo.getPathFromPolygon(route_info.start_field)).reduce((objects, item) => {
              const arrObj = [item.lat, item.lng]
              objects.push(arrObj)
              return objects
            }, [])
            geomEnd = Object.values(Geo.getPathFromPolygon(route_info.end_field)).reduce((objects, item) => {
              const arrObj = [item.lat, item.lng]
              objects.push(arrObj)
              return objects
            }, [])
            markers = route_info.park_markers
          }
          break;
        default:
          return null;
      }

      /*const startIcon = (<Icon iconUrl={window.location.origin+"/assets/"+settings.name+"/img/blank.jpg"} iconSize={[38, 95]}
      iconAnchor={[22, 94]}
      popupAnchor={[-3, -76]} />)*/
      if(bounds.length === 0){
        return null
      }

      return (
        <Map bounds={bounds} zoomControl={false} scrollWheelZoom={this.props.zoomable} boundsOptions={boundOptions} style={this.props.style} >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {this.props.geoinfo.type === "field" &&
          <Polygon color="green" positions={geom} />}
          {(this.props.geoinfo.type === "harvest" || this.props.geoinfo.type === "plow") &&
            <Fragment>
              <Polygon color="green" positions={geomBound} />
              <Polyline color="#B1DD2B" weight={5} positions={geom} />
              <Polygon color="#E4F0A8" positions={geomWork} />
              <Polygon color="orange" positions={geomWork2} />
            </Fragment>}
          {this.props.geoinfo.type === "route" &&
            <Fragment>
              <Polygon color="green" positions={geomStart} />
              <Polygon color="#3366cc" positions={geomEnd} />
              <Polyline color="purple" positions={geom} />
              {markers && markers.length > 0 && markers.map((item, index) => {
                return (
                  <Marker key={index} position={[item.lat, item.lng]}>
                    <Popup>{item.text}</Popup>
                    <Tooltip>{item.text}</Tooltip>
                  </Marker>
                )
              })}
            </Fragment>}
        </Map>
      )

    }else{
      return (
        <div style={{...this.props.style, backgroundColor:"#f2f2f2"}} ></div>
      )
    }
  }

}

export default connect(({app, info}) => {
	return {app, info};
}, {
  actionPerformApp
})(LeafletMap)
