import React, { useEffect, useRef } from 'react';
import flvjs from 'flv.js';

const KTFLVPlayer = ({ streamURL }) => {
  const videoRef = useRef(null);
  const flvPlayerRef = useRef(null);

  useEffect(() => {
    // Initialize FLV.js player
    if (flvjs.isSupported()) {
      const flvPlayer = flvjs.createPlayer({
        type: 'flv',
        url: streamURL,
      });

      flvPlayer.attachMediaElement(videoRef.current);
      flvPlayer.load();
      flvPlayer.play();

      flvPlayerRef.current = flvPlayer;

      return () => {
        flvPlayerRef.current.pause();
        flvPlayerRef.current.unload();
        flvPlayerRef.current.detachMediaElement();
      };
    } else {
      console.error('FLV.js is not supported in this browser');
    }
  }, [streamURL]);

  return <video ref={videoRef} controls style={{ width: '100%', maxWidth: '800px' }} />;
};

export default KTFLVPlayer;
