import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {TextField} from '@material-ui/core';
import MaterialIcon from 'react-material-iconic-font'

import settings from '../../settings';

const styles = theme => ({
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `#fff!important`,
      borderWidth: '1px',
    }
  },
  cssFocused: {},
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(255,255,255,0.5)!important'
  },
});

const normalStyle = {
  input: {
    paddingTop:14,
    paddingBottom:13,
  },
  inputWithIcon: {
    paddingLeft:48
  },
  icon:{
    position:'absolute',
    left:16,
    top:28,
    color:settings.theme.primary.main
  },
  label:{
    fontSize:'0.9rem'
  },
  inputBase:{
    fontSize:'0.9rem'
  }
};

const smallStyle = {
  input: {
    paddingTop:10,
    paddingLeft:7,
    paddingRight:7,
    paddingBottom:10
  },
  inputWithIcon: {
    paddingLeft:30
  },
  icon:{
    position:'absolute',
    left:10,
    top:13,
    color:settings.theme.primary.main
  },
  label:{
    fontSize:'0.8rem'
  },
  inputBase:{
    fontSize:'0.7rem'
  }
};

class KTTextField extends React.Component {
  render() {

    let fieldStyle = (this.props.small ? smallStyle : normalStyle )
    let outlineStyle = {}
    if(this.props.light){
      fieldStyle.icon = {...fieldStyle.icon, color: '#888'}
      fieldStyle.label = {...fieldStyle.label, color: '#fff'}
      fieldStyle.inputBase = {...fieldStyle.inputBase, color: '#fff'}
      fieldStyle.input = {...fieldStyle.input, color: '#fff', backgroundColor: "transparent"}
      outlineStyle = {
        root: this.props.classes.cssOutlinedInput,
        focused: this.props.classes.cssFocused,
        notchedOutline: this.props.classes.notchedOutline
      }
    }
    if(this.props.align){
      fieldStyle.input = {...fieldStyle.input, textAlign: this.props.align}
    }

    return (
      <div style={{...{position:'relative'}, ...this.props.style}}>
        {this.props.icon && <div style={fieldStyle.icon}>
          {this.props.small && <MaterialIcon type={this.props.icon} small />}
          {!this.props.small && <MaterialIcon type={this.props.icon} large />}
        </div>}
        <TextField
          required={this.props.required}
          name={this.props.name}
          label={this.props.label}
          disabled={this.props.disabled}
          type={this.props.type}
          defaultValue={this.props.defaultValue}
          placeholder={this.props.placeholder}
          helperText={this.props.helperText}
          fullWidth
          margin={this.props.small ? "dense" : "normal"}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
            style: fieldStyle.label
          }}
          InputProps={{
            style: fieldStyle.inputBase,
            inputProps:{
              style: {...fieldStyle.input, paddingLeft:(this.props.icon ? fieldStyle.inputWithIcon.paddingLeft : fieldStyle.input.padding)}
            },
            classes:outlineStyle
          }}
          onChange={this.props.onChange}
          onFocus={this.props.onFocus}
        />
      </div>
    );
  }
}

KTTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(KTTextField);
