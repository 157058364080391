import { useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import Swal from 'sweetalert2'

import {actionPerform as actionPerformInfo} from '../redux/Info'
import {actionPerform as actionPerformApp} from '../redux/App'
import {actionPerform as actionPerformUser} from '../redux/User'
import {translate} from '../lang'

export const useInterval = (callback, delay, running_page) => {
  const savedCallback = useRef()
  const page = useSelector(state => state.app.page)

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if(savedCallback.current && (!running_page || page === running_page)) {
        savedCallback.current()
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [page, delay])

}

export const useInfoOperation = () => {
  const dispatch = useDispatch()
  function perform(payload) {
    dispatch(actionPerformInfo("INFO_OPERATION", payload))
  }
  return perform
}

export const useInfo = () => {
  const dispatch = useDispatch()
  function perform(action, payload) {
    dispatch(actionPerformInfo(action, payload))
  }
  return perform
}

export const useApp = () => {
  const dispatch = useDispatch()
  function perform(action, payload) {
    dispatch(actionPerformApp(action, payload))
  }
  return perform
}

export const useUser = () => {
  const dispatch = useDispatch()
  function perform(action, payload) {
    dispatch(actionPerformUser(action, payload))
  }
  return perform
}

export const useUserOperation = () => {
  const dispatch = useDispatch()
  function perform(payload) {
    dispatch(actionPerformUser("USER_OPERATION", payload))
  }
  return perform
}

export const useLocalStorage = () => {

  const getStorage = (key, default_value) => {
    if(!localStorage.hasOwnProperty(key)){
      return default_value
    }
    let ldata = localStorage.getItem(key)
    try {
      const ldataObject = JSON.parse(ldata)
      ldata = ldataObject
    } catch(e) {}
    return ldata
  }

  const setStorage = (key, ldata) => {
    let savedata = ldata
    if(typeof ldata === "object"){
      savedata = JSON.stringify(ldata)
    }
    if(savedata === null){
      localStorage.removeItem(key)
    }else{
      localStorage.setItem(key, savedata)
    }
  }

  return {get:getStorage, set:setStorage}
}

export const useStorage = () => {

  const getStorage = (key, default_value) => {
    if(!localStorage.hasOwnProperty(key)){
      return default_value
    }
    let ldata = localStorage.getItem(key)
    try {
      const ldataObject = JSON.parse(ldata)
      ldata = ldataObject
    } catch(e) {}
    return ldata
  }

  const setStorage = (key, ldata) => {
    let savedata = ldata
    if(typeof ldata === "object"){
      savedata = JSON.stringify(ldata)
    }
    if(savedata === null){
      localStorage.removeItem(key)
    }else{
      localStorage.setItem(key, savedata)
    }
  }

  return [getStorage, setStorage]
}

export const useStatusButton = (activity, serveractivity, prefix, callback) => {
  const dispatch = useDispatch()
  const info = useSelector(state => state.info[activity+"_detail"])

  const onChangeStatus = (new_status, master) => {
		Swal.fire({
			title: translate("confirmation") + " ?",
			text: translate("question_confirm_operation_status") + " : " + translate((prefix ? prefix+"." : "" )+"status."+new_status) ,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: translate("no"),
			confirmButtonText: translate("yes_confirm")
		}).then((result) => {
			if (result.value) {
				let fields = {
					 id : info.id,
					 status : new_status
				}
				if(master){
					fields.master = master
				}
        dispatch(actionPerformInfo("INFO_OPERATION", {
            action: (serveractivity ? serveractivity : activity)+"/status",
            body: fields,
            fdata: activity+"_operation",
            callback:((success) => {
    					if(success){
    						dispatch(actionPerformInfo("INFO_OPERATION", {action: (serveractivity ? serveractivity : activity)+"/detail", body:fields, fdata:activity+"_detail",
                callback:((success, info) => {
                  if(callback) {
                    callback(info)
                  }
                })}))
    					}
  				  })
          }
        ))
			}
		})
	}

  function generate(actions, objects, master) {
    let buttons = []
    if(info){
      for (let k = 0; k < actions.length; k++) {
        buttons.push({
          name: "status_"+actions[k],
          text: translate((prefix ? prefix+"." : "" )+"status."+actions[k]),
          operation:()=>{
            onChangeStatus(actions[k], master)
          },
          color: (objects[actions[k]] ? objects[actions[k]].color : "")
        })
      }
    }

    return buttons
  }

  return generate
}

export const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
      playing ? audio.play().catch(() => {console.log("Can't play audio without user interaction")}) : audio.pause();
  },[playing])

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
}
