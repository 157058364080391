import React from "react";
import { connect} from 'react-redux';
import classNames from 'classnames'
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import MomentUtils from '@date-io/moment'
import { DatePicker } from 'material-ui-pickers';
import settings from '../../settings'

const styles = theme => ({
  root: {
    padding: "0px 0px 0px 0px",
    verticalAlign:"top"
  },
  nakedInput: {
    fontSize: "0.7rem",
    lineHeight:"20px",
    minHeight:20,
    padding: "1px 5px",
    width: "calc(100% - 10px)",
    backgroundColor:"#fafafa",
    borderBottom: "1px solid #f2f2f2",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 2,
      boxShadow: "0 0 0 2px rgba(0,123,255,.25)"
    }
  },
  dotField:{
    borderBottom: "1px solid #BDBDBD",
  },
  disabled: {
    color: "black",
    backgroundColor:"transparent",
  },
  disabledNoLine: {
    color: "black",
    backgroundColor:"transparent",
    borderBottom: "none",
  },
  inputCenter:{
    textAlign:"center"
  },
  inputRight:{
    textAlign:"right"
  },
  inputBackgroudColor:{
    backgroundColor:"rgb(255, 230, 230)"
  },
  inputMythemeBackgroudColor:{
    backgroundColor:(settings.theme.mytheme ? settings.theme.mytheme.input : "transparent")
  },
  outline:{
    borderBottom:"none",
    backgroundColor:"transparent",
    lineHeight:"28px",
    minHeight:28,
    marginTop:-4,
    "&:focus": {
      boxShadow: "none"
    }
  },
  light:{
    color:"white"
  },
  fieldset:{
    paddingLeft:8,
    paddingRight:8,
    border:'1px solid rgba(255,255,255,0.5)!important',
    borderRadius: 5,
    "&:focus": {
      border:'1px solid rgba(255,255,255,1)!important',
    }
  },
  legend:{
    fontSize:10,
    lineHeight:"11px"
  }
});

class KTDateField extends React.Component {

  //state = {
  //  selectedDate: (this.props.defaultValue ? (this.props.defaultValue.startsWith("0000-00-00") ? null : moment(this.props.defaultValue,"YYYY-MM-DD").toDate()) : null )
  //}

  shouldComponentUpdate(nextProps, nextState){
    if(this.props !== nextProps){
      return true
    }else if(this.state !== nextState){
      return true
    }
    return false
  }

  _onChange = (date) => {
    this.setState({ selectedDate: (date ? date.toDate() : null ) });
    const obj = {
      name : this.props.name,
      value : (date ? date.format("YYYY-MM-DD") : ""),
      dateValue : (date ? date.toDate() : null )
    }
    this.props.onChange(obj)
  }

  render(){

    const { classes } = this.props;
    const selectedDate = (this.props.defaultValue ? (this.props.defaultValue.startsWith("0000-00-00") ? null : moment(this.props.defaultValue,"YYYY-MM-DD").toDate()) : null )

    let clsArr = [classes.nakedInput]

    if(this.props.mytheme){
      if(!this.props.disabled){
        clsArr.push(classes.inputMythemeBackgroudColor)
      }
    }else{
      if (this.props.required) {
        clsArr.push(classes.inputBackgroudColor)
      }
    }

    if (this.props.center) {
      clsArr.push(classes.inputCenter)
    }else if (this.props.align) {
      if (this.props.align === "center") {
        clsArr.push(classes.inputCenter)
      }else if (this.props.align === "right") {
        clsArr.push(classes.inputRight)
      }
    }
    if(this.props.outline){
      clsArr.push(classes.outline)
    }
    if(this.props.light){
      clsArr.push(classes.light)
    }
    if(this.props.dotField){
      clsArr.push(classes.dotField)
    }

    const fieldData = (<DatePicker
      name={this.props.name}
      clearable autoOk
      disableFuture={this.props.disableFuture}
      disabled={this.props.disabled}
      format={"DD/MM/YYYY"}//{(this.props.app.lang === "th" ? "D MMM YYYY" : "DD/MM/YYYY")}
      placeholder={this.props.placeholder}
      value={selectedDate}
      onChange={this._onChange}
      style={{
        width: this.props.width ? this.props.width : "100%"
      }}
      InputProps={{
        disableUnderline:true,
        classes:{
          root: classes.root,
          input: classNames(clsArr),
          disabled: (this.props.fieldOnTable ? classes.disabledNoLine : classes.disabled)
        }
      }}
    />)

    if(this.props.outline){
      return (
        <fieldset className={classes.fieldset} >
          <legend className={classes.legend} >{this.props.label}</legend>
          {fieldData}
        </fieldset>
      )
    }else{
      return fieldData
    }

  }

}

MomentUtils.prototype.startOfMonth=MomentUtils.prototype.getStartOfMonth

export default connect(({app}) => {
	return {app};
}, {

})(withStyles(styles, { withTheme: true })(KTDateField));
