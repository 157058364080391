import axios from 'axios';
import settings from '../settings'

function to(promise) {
   return promise.then(result => {
     if(result.data.what === "ok"){
       //console.log("Return => ", result.data.info)
       return {
         error: null,
         info: (Object.keys(result.data).length && result.data.info ? result.data.info : result.data)
       }
     }else{
       if(typeof result.data !== 'object'){
         return {error: new Error("Session expired")}
       }else{
         return {error: new Error(result.data.result_text)}
       }
     }
   })
   .catch(err => {
     return {
       error: err
     }
   })
}

const apiMagbiz = async (cmd, payload) => {

    let body = {payload:payload}
    if(localStorage.hasOwnProperty("userdata")){
      const userdata = JSON.parse(localStorage.getItem("userdata"))
      body.authorization = userdata.session_id
      body.role = userdata.role
      body.user_authorized = userdata.user_authorized
    }
    if(payload.anonymous){
      body.anonymous = payload.anonymous
      delete payload['anonymous']
    }

    let timeout = (settings.api.timeout ? settings.api.timeout : 480000)
    if(payload.request_timeout){
      timeout = payload.request_timeout
      delete payload['request_timeout']
    }

    let url = settings.api.server+settings.api.app_module+"/"+cmd
    if(cmd.startsWith("http")){
      url = cmd+"&session_id="+body.authorization
      for(let k in body.payload){
        url += "&"+k+"="+body.payload[k]
      }
    }else {
      if(cmd.startsWith("/")){
        url = settings.api.server+cmd.substring(1,cmd.length)
      }else if(cmd.startsWith("#")){
        url = cmd.substring(1,cmd.length)
      }
      if(url.indexOf("?") !== -1){
        const paramArr = url.substring(url.indexOf("?")+1,url.length)
        const params = paramArr.split("&")
        for(let i in params){
          const param = params[i].split("=")
          if(param.length === 2){
            body.payload[param[0]] = param[1]
          }
        }
        url = url.substring(0,url.indexOf("?"))
      }
    }
    //console.log("Request["+timeout+"] => "+cmd, body)
    //const startRunTime = (new Date()).getTime()
    let result = await to(axios({
      url: url,
      method: 'post',
      data: body,
      timeout: timeout,
      config: { headers: {'Content-Type': 'application/json'  }}
    }))
    //console.log("["+cmd+"] ",+Math.round(((new Date()).getTime() - startRunTime) / 1000)+"s")
    if (result.error) {
      console.log("Error => "+result.error.message)
      if(result.error.message.startsWith("Session expired")){
        if(localStorage.hasOwnProperty("userdata")){
          localStorage.removeItem("userdata")
          window.location = "/"
        }
      }
    }
    return result
}
export default apiMagbiz
