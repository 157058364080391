import React from 'react';
import ReactDOM from 'react-dom';
//import "antd/dist/antd.min.css";
import "./pages/erp/WMS/resources/antd.css";
//import "./pages/erp/WMS/resources/anti-antd.css";
//import "./pages/erp/WMS/resources/document.css";
import './theme/css/index.css';

import App from './App';

import api from './api'
import {KTLoadingStandalone} from  './KT';

import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
setChonkyDefaults({ iconComponent: ChonkyIconFA });

require('react-web-vector-icons/fonts');

if(window.location.search && window.location.search.startsWith("?siid=")){
  ReactDOM.render(<KTLoadingStandalone message="Logging in, Please wait..." />, document.getElementById('root'));
  const preuserdata = {session_id:window.location.search.split('?siid=').join('')}
  localStorage.setItem("userdata", JSON.stringify(preuserdata))
  api("user/info",{}).then((result) => {
    if(!result.error){
      localStorage.setItem("userdata", JSON.stringify(result.info))
    }
    window.location = "/"
  }).catch((error) => {
    window.location = "/"
  })
}else{
  ReactDOM.render(<App />, document.getElementById('root'));
}
