const moduleName = "alldayallnight"

export default {
  name : moduleName,
  api : {
    server : "http://api.magbizcloud.com:8889/",
    app_module : "alldayallnight",
  },
  title : "All Day All Night",
  description : "Resort & Hotel Management System",
  logo : "assets/"+moduleName+"/logo.png",               // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  theme : {
    primary: {
      light: "rgba(66,165,245,0.8)",
      main: "rgba(66,165,245,1)",
      dark: "rgba(47,152,238,1)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(250,250,250,0.9)",
      main: "rgb(250,250,250,0.95)",
      dark: "rgb(250,250,250,1)",
      contrastText: '#424242'
    }
  },
  menu:{
    main_menu: {
  		title: "sidebar.section.main_menu",
  		list: [
  			{
  				"menu_title": "sidebar.dashboard",
  				"menu_icon": "view-dashboard",
  				"path": "/"+moduleName+"/dashboard"
  			},
        {
  				"menu_title": "sidebar.bookings",
  				"menu_icon": "hotel",
  				"path": "/"+moduleName+"/bookings"
  			},
        {
  				"menu_title": "sidebar.income_expenses",
  				"menu_icon": "money-box",
  				"path": "/"+moduleName+"/income_expenses"
  			},
        {
  				"menu_title": "sidebar.rooms",
  				"menu_icon": "store",
  				"path": "/"+moduleName+"/rooms"
  			},
        {
  				"menu_title": "sidebar.customers",
  				"menu_icon": "male-female",
  				"path": "/"+moduleName+"/customers"
  			},
        {
  				"menu_title": "sidebar.report",
  				"menu_icon": "file",
  				"path": "/"+moduleName+"/report"
  			}
  		]
  	},
    setting_menu: {
      allowUser:["admin"],
  		title: "sidebar.section.setting_menu",
  		list: [
  			{
  				"menu_title": "sidebar.users",
  				"menu_icon": "account-circle",
  				"path": "/"+moduleName+"/users",
  				"child_routes": null,
          allowUser: ["admin"],
  			},
  		]
  	}
  },
  report_menu:{
    report: {
      list: [
        {
          "menu_title": "sidebar.report.income_expenses",
          "menu_icon": "money-box",
          "path": "report/account_incomeexpenses",
          allowUser: ["admin"],
        }
      ]
    }
  },
}
