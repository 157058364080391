import React,{Fragment} from 'react';
import { useSelector } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';

import SectionList from './components/SectionList';
import settings from  '../../settings';

const drawerWidth = 240

const AppDrawer = ({visible, onItemSelected, classes}) => {

  const userdata = useSelector(state => state.user.userdata)

  return (
    <Drawer
      open={visible}
      onClose={() => onItemSelected(null)}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar className={classes.toolBar} >
        {settings.logo_head_text && <div style={{color:"white", fontSize:18, fontWeight:500}}>
          {settings.logo_head_text}
        </div>}
        {!settings.logo_head_text &&
          <img src={(userdata && userdata.logo ? userdata.logo : window.location.origin+"/"+settings.logo_head)} alt="Logo" style={{width:'auto',height:30}} />
        }
      </Toolbar>
      <div
        tabIndex={0}
        role="button"
        onKeyDown={() => onItemSelected(null)}
      >
        <div>
          {Object.entries(settings.menu).map((item, index) => {
            return (
              <Fragment key={index}>
                <Divider />
                <SectionList menu={item[1]} type="link" onItemSelected={onItemSelected} />
              </Fragment>
            )
          })}
        </div>
      </div>
    </Drawer>
  )
}

const styles = theme => {
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor:theme.palette.secondary.main
    },
    textColor:{
      color:theme.palette.primary.contrastText
    },
    toolBar:{
      backgroundColor:theme.palette.primary.dark
    }
  }
}

export default withStyles(styles, { withTheme: true })(AppDrawer)
