import styled from 'styled-components';
import {createMuiTheme} from '@material-ui/core/styles';
import settings from '../settings'

export const DivSW = styled.div`
  button.accent{
    background-color: #4caf50!important;
    color: #fff!important;
  }
  button.accent:hover{
    background-color: #6fbf73!important;
  }
`

export const appTheme = createMuiTheme({
  palette: {
    primary: (settings.theme && settings.theme.primary ? settings.theme.primary : {
      light: "rgba(0,0,0,0.8)",
      main: "rgba(0,0,0,0.9)",
      dark: "rgba(0,0,0,1)",
      contrastText: '#fff'
    }),
    secondary: (settings.theme && settings.theme.secondary ? settings.theme.secondary : {
      light: "rgb(211,47,47,0.9)",
      main: "rgb(211,47,47,1)",
      dark: "rgb(230,47,47,1)",
      contrastText: '#fff'
    })
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Roboto", sans-serif'/*'K2D,"Kanit", "Helvetica Neue", sans-serif'*/,
  },
});
