import React from 'react'
import { injectIntl } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import Measure from 'react-measure';
import {Tooltip,CircularProgress,Grid} from '@material-ui/core';
import axios , { post } from 'axios';
import {KTButton} from '../../KT'
import {MagbizGeneral} from '../../utils/Magbiz';

import settings from '../../settings';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

class KTFileUpload extends React.Component {

  state = {
    loading:false,
    filepathurl:(this.props.src ? this.props.src : this.props.defaultSrc),
    buttonSize:{width:0, height:0}
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props !== nextProps){
      if(nextProps.src !== this.state.filepathurl){
        if(nextProps.src && nextProps.src.startsWith("http")){
          this.setState({filepathurl: nextProps.src})
          return false
        }else if(!Boolean(nextProps.src)){
          this.setState({filepathurl: this.props.defaultSrc})
          return false
        }
      }
      return true
    }
    if(this.state !== nextState){return true}
    return false
  }

  _onCancel = () => {
      source.cancel()
      this.setState({loading:false})
  }

  _onMeasureButton = (contentRect) => {
    this.setState({buttonSize:{
      width:MagbizGeneral.round(contentRect.bounds.width,0),
      height:MagbizGeneral.round(contentRect.bounds.height,0)
    }});
  }

  _onClear = () => {
    if(this.props.onImageChanged){
      this.props.onImageChanged(this.props.name,this.props.defaultSrc,"")
    }
  }

  _onUpload = () => {
    this.myFormRef[this.props.name].click()
  }

  _onChange = (e) => {
    if(!e.target.files[0]) return
    let fileTypes = {
      "image/jpeg" : "jpg",
      "image/png" : "png",
      "application/pdf" : "pdf",
      "application/vnd.ms-excel" : "xls",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "xlsx",
      "video/mp4" : "mp4",
    }
    if(this.props.fileTypes){fileTypes = this.props.fileTypes}
    if(!fileTypes[e.target.files[0].type]){
      const uniquefileTypes = [...new Set( Object.values(fileTypes).map(obj => obj)) ]
      NotificationManager.error("Only "+uniquefileTypes.join(", ")+" file is allowed")
      return
  /*  }else if(e.target.files[0].type !== "image/jpeg" && e.target.files[0].type !== "image/png" &&
      e.target.files[0].type !== "application/pdf" && e.target.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ){
      NotificationManager.error("Only jpg, png, pdf, xlsx file is allowed")
      return*/
    }else if(e.target.files[0].size > 20 * 1024 * 1024){
      NotificationManager.error("Only less than 20 MB file is allowed")
      return
    }
    this.setState({loading:true})
    const me = this
    this.fileUpload(e.target.files[0])
    .then((response) => {
      me.myFormRef.reset();
      if(response.data.what === "ok" && response.data.fileurls && response.data.fileurls.length > 0){
        me.setState({loading:false,
          filepathurl:response.data.fileurls[0].filepathurl,
          filepath:response.data.fileurls[0].filepath})
        if(me.props.onImageChanged){
          me.props.onImageChanged(me.props.name,response.data.fileurls[0].filepathurl,response.data.fileurls[0].filepath)
        }
      }else{
        me.setState({loading:false,filepathurl:me.props.defaultSrc})
        if(me.props.onImageChanged){
          me.props.onImageChanged(me.props.name,me.props.defaultSrc,"")
        }
      }
    }).catch(function (error){
      if(axios.isCancel(error)){
        console.log('Uploading cancelled by user');
      }else{
        console.error(error);
        NotificationManager.error("Upload failed");
      }
      me.myFormRef.reset();
      me.setState({loading:false,filepathurl:me.props.defaultSrc})
      if(me.props.onImageChanged){
        me.props.onImageChanged(me.props.name,me.props.defaultSrc,"")
      }
    });
  }

  addDefaultSrc = (ev) => {
    ev.target.src = this.props.defaultSrc
  }

  fileUpload = (file) => {
    const url = settings.api.server+settings.api.app_module+"/upload";
    const formData = new FormData();
    formData.append('folder_name',this.props.folder_name)
    formData.append('uid',this.props.uid)
    if(localStorage.hasOwnProperty("userdata")){
      formData.append('authorization', JSON.parse(localStorage.getItem("userdata")).session_id)
    }
    formData.append('files',file)
    const config = {
        headers: {
          'content-type': 'multipart/form-data'
        },
        cancelToken: source.token
    }
    return  post(url, formData,config)
  }

  render() {
    let src = this.state.filepathurl
    const ext = this.state.filepathurl.substr(this.state.filepathurl.lastIndexOf("."),this.state.filepathurl.length)
    if(ext.startsWith(".pdf")){
      src = window.location.origin+"/assets/"+settings.name+"/img/pdf.png"
    }else if(ext.startsWith(".xls")){
      src = window.location.origin+"/assets/"+settings.name+"/img/excel.png"
    }else if(ext.startsWith(".doc")){
      src = window.location.origin+"/assets/"+settings.name+"/img/word.png"
    }else if(ext.startsWith(".ppt")){
      src = window.location.origin+"/assets/"+settings.name+"/img/ppt.png"
    }else if(ext.startsWith(".mp4")){
      src = window.location.origin+"/assets/"+settings.name+"/img/video.png"
    }
    //(src === this.props.defaultSrc ? (this.props.containDefault ? this.props.containDefault : "fill") : "contain" )
    //const tlang = this.props.intl.formatMessage;
    return (
      <form ref={(el) => this.myFormRef = el} style={{...this.props.style, width:(this.props.width ? this.props.width : "100%"), height:(this.props.height ? this.props.height : "100%"), position:'relative'}}>
        <a href={(this.props.disabled  && this.state.filepathurl !== this.props.defaultSrc ? this.state.filepathurl : "#")} target={(this.props.disabled && this.state.filepathurl !== this.props.defaultSrc ? "_blank" : "_self")}>
          <img src={src} onError={this.addDefaultSrc} alt="" style={{width:'100%', height:'100%', objectFit:(this.props.contain ? this.props.contain : "contain") , backgroundColor:'#fff'}} />
        </a>
        <input name={this.props.name} type="file" onChange={this._onChange} style={{display:'none'}}/>
        {!this.props.disabled && <Measure bounds onResize={this._onMeasureButton} >
          {({ measureRef }) =>
          <div ref={measureRef} style={{ position:'absolute', top: "calc(50% - "+MagbizGeneral.round(this.state.buttonSize.height/2,0)+"px)", left: "calc(50% - "+MagbizGeneral.round(this.state.buttonSize.width/2,0)+"px)" }}>
            {!this.state.loading && !this.props.disabled &&
              <Grid container direction="row" justify="center" alignItems="center" >
                <Tooltip title="Upload Image">
                  <div onClick={this._onUpload} style={{cursor:"pointer"}}>
                    <span className="zmdi-hc-stack" style={{color:"green",fontSize:(this.props.iconFontSize ? this.props.iconFontSize : "1.5rem")}}>
                      <i className="zmdi zmdi-circle zmdi-hc-stack-1x" style={{fontSize:"2em"}} ></i>
                      <i className="zmdi zmdi-cloud-upload zmdi-hc-stack-1x zmdi-hc-inverse" style={{fontSize:"1em"}}></i>
                    </span>
                  </div>
                </Tooltip>
                {this.state.filepathurl !== this.props.defaultSrc &&
                <Tooltip title="Clear Image">
                  <div onClick={this._onClear} style={{cursor:"pointer"}}>
                    <span className="zmdi-hc-stack" style={{color:"rgba(255,33,33,0.8)",fontSize:(this.props.iconFontSize ? this.props.iconFontSize : "1.5rem")}}>
                      <i className="zmdi zmdi-circle zmdi-hc-stack-1x" style={{fontSize:"2em"}} ></i>
                      <i className="zmdi zmdi-delete zmdi-hc-stack-1x zmdi-hc-inverse" style={{fontSize:"1em"}}></i>
                    </span>
                  </div>
                </Tooltip>}
              </Grid>
            }
            {this.state.loading && <Grid container direction="column" justify="center" alignItems="center">
              <CircularProgress color="primary" />
              <KTButton onClick={this._onCancel} >
                Cancel
              </KTButton>
            </Grid>}
          </div>}
        </Measure>}
        {!this.props.disabled && !this.props.nohint  &&
          <span style={{position:'absolute', bottom:5, left: 5, fontSize: 10, color:"#888", textShadow: "1px 1px #fff"}}>
            {"Allow JPG, PNG file (10 MB maximum size)"}
          </span>}
    </form>
   )
  }
}

export default injectIntl(KTFileUpload)
