import React from 'react';

const styles = {
  container: {
    height:"100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems: 'center',
    backgroundColor:'#f2f2f2',
  },
  text: {
    color:'#888',
  },
};


export default function KTMessagePage(props){

  return (
    <div style={styles.container}>
      <span style={styles.text}>{props.message}</span>
    </div>
  )

}
