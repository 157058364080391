import React,{Fragment} from 'react';
import {Grid,Typography} from '@material-ui/core';

import DocDefaultContent from './DocDefaultContent'
const containerWidth = 600

class DocDefaultHeader extends React.Component {

  shouldComponentUpdate(nextProps, nextState){
    if(this.props !== nextProps){
      return true
    }
    return false
  }

  _onFieldChange = (event) => {
    this.props.onFieldChange(event)
  }

  _onDateTimeChange = (dateObj) => {
    this.props.onDateTimeChange(dateObj)
  }

  _onFieldSelect = (name, selectId) => {
    this.props.onFieldSelect(name, selectId)
  }

  render(){
    //console.log("Render DocDefaultHeader")
    const {header, fields, mode, width} = this.props

    if(!Boolean(header)) return null

    return (
      <div className={`document-header`}  >
        {header.header_image &&
        <div style={{width:"100%",marginBottom:(header.header_image.marginBottom ? header.header_image.marginBottom : 0)}}>
            <img src={header.header_image.src} alt="" style={{width:"100%", height:"auto"}} />
        </div>}
        <Grid container >
          {width >= containerWidth &&
          <Grid item xs={(header.balance ? 4 : 3)}>
            {header.left &&
            <Fragment>
              {header.left.map((item, index) => {
                return (
                  <div key={index} style={{fontSize:'0.6rem', lineHeight:'0.9rem', minHeight: '0.9rem'}}>
                    {item}
                  </div>
                )
              })}
            </Fragment>}
            {header.left_fields &&
              <DocDefaultContent contentType="header" groupObj={header.left_fields} width={width} mode={mode} fields={fields}
                onFieldChange={this._onFieldChange}
                onDateTimeChange={this._onDateTimeChange}
                onFieldSelect={this._onFieldSelect} />
            }
          </Grid>}
          <Grid item xs={(width >= containerWidth ? (header.balance ? 4 : 6) : 12)} style={{marginTop:(typeof header.marginTop !== "undefined" ? header.marginTop : 30), marginBottom:(typeof header.marginBottom !== "undefined" ? header.marginBottom : 20)}}>
            <Typography variant="h6" align="center">
              <b>{header.title}</b>
            </Typography>
            <Typography variant="caption" align="center" style={{ fontWeight:400, fontSize:'0.7rem', lineHeight:'1rem', minHeight: '1rem'}} >
              {header.subtitle}
            </Typography>
          </Grid>
          {width >= containerWidth &&
          <Grid item xs={(header.balance ? 4 : 3)}>
            {header.right &&
            <Fragment>
               {header.right.map((item, index) => {
                 return (
                   <div key={index} style={{fontSize:'0.6rem', textAlign:"right", lineHeight:'0.9rem', minHeight: '0.9rem'}}>
                     {item}
                   </div>
                 )
               })}
             </Fragment>}
             {header.right_fields &&
               <DocDefaultContent contentType="header" groupObj={header.right_fields} width={width} mode={mode} fields={fields}
                 onFieldChange={this._onFieldChange}
                 onDateTimeChange={this._onDateTimeChange}
                 onFieldSelect={this._onFieldSelect} />
             }
          </Grid>}
        </Grid>
      </div>
    )
  }
}

export default DocDefaultHeader
