import React,{useState} from "react";

export default function CheckBox(props){

  const [checked, setChecked] = useState(props.defaultValue)

  const handleChange = (event) => {
    setChecked(!checked)
    if(props.onChange){
      props.onChange(event.target.checked)
    }
  }

  return (
    <label style={{ display:"inline-block", marginRight:10}}>
      <input type="checkbox" disabled={props.disabled} checked={checked}
        onChange={handleChange} style={{marginRight:5}} />
      <span style={{fontSize:props.size}}>
        {props.label}
      </span>
    </label>
  )

}
