import React,{Fragment, useMemo} from 'react';
import {useSelector} from 'react-redux';
import MaterialIcon from 'react-material-iconic-font'

import {AppBar, Typography, Toolbar, Grid, CircularProgress} from '@material-ui/core';

import settings from '../../settings'
import {translate} from '../../lang'
import {KTSelectField, KTDateField, KTTimeField, KTButton, KTLoadingStandalone} from '../../KT'
import {MagbizGeneral, MagbizUI} from '../../utils/Magbiz'
import {useInfoOperation} from '../../utils/MagbizHook'


export default function UILogViewer(props){

  const activity = "logviewer"
  const userdata = useSelector(state => state.user.userdata)

  useMemo(() => {

  },[])

  if(MagbizGeneral.isEmptyObject(userdata)) return (<KTLoadingStandalone/>)
  return <MemoView activity={activity} userdata={userdata} module={props.module}
    width={props.width} height={props.height} size={props.size} />
}

const MemoView = React.memo(props => {

  const module = props.module
  const performInfoOperation = useInfoOperation()
  const action = useSelector(state => state.info.action[module.name])
  const filters = useSelector(state => state.info.filters)
  const data = useSelector(state => state.info[module.name])
  const appscreen = useSelector(state => state.app.screen)
  //const appinterface = useSelector(state => state.app.interface)
  const page_name = translate("sidebar.logviewer")
  const docWidth = (props.width ? props.width : appscreen.width)
  const docContentWith = docWidth
  const docContentHeight = (props.height ? props.height : window.innerHeight - appscreen.appBarSize.height*2)
  const filter = (filters[module.name] ? filters[module.name] : {...module.filters})

  /*let columns = null
  if(data){
    columns = data.cols;
    if(data.cols2){
      columns = data.cols2;
    }
  }

  const createMarkup = (text) => {
    return {__html: text};
  }*/

  const loadData = () => {
    const filter_fields = MagbizUI.getDataFromFilterItem(filter)
    performInfoOperation({action:filter_fields.name, body:filter_fields, fdata:module.name})
  }
  console.log(props.module)
  return (
    <Fragment>
      <AppBar position="sticky" style={{backgroundColor:settings.theme.primary.dark,zIndex: 900}}>
        <Toolbar >
          <div style={{flexDirection:'column', justifyContent:'center', marginRight:10}}>
            <Grid container direction="column" justify="center">
              <Typography variant="subtitle1" color="inherit" >
                {page_name}
              </Typography>
            </Grid>
          </div>
          <div style={{marginLeft:10, marginRight:10}}>
            <MaterialIcon type={"filter-list"} />
          </div>

          {filter && Object.values(filter).map((item, index) => {
            if(item === null) return null
            switch(item.type) {
               case "select": {
                 return (
                   <div key={index} style={{marginLeft:5, marginRight:5, width:item.width+'px'}}>
                     <KTSelectField name={item.name} label={translate(item.caption ? item.caption : item.name)} placeholder=" " align="center"
                       icon={item.icon} defaultValue={(filters[module.name] ? filters[module.name][item.name].value : item.value)}
                       suggestions={item.list} async={Boolean(item.async)}
                       onChange={(e) => this._onFilterSelectChange(e)} small light />
                   </div>
                 )
               }
               case "time": {
                 return (
                   <div key={index} style={{marginLeft:5, marginRight:5, width:item.width+'px'}}>
                     <KTTimeField type="time" name={item.name} label={translate(item.caption ? item.caption : item.name)} placeholder=" "  align="center"
                       defaultValue={(filters[module.name] ? filters[module.name][item.name].value : item.value)}
                       onChange={(e) => this._onFilterDateTimeChange(e)} small light outline notblank />
                   </div>
                 )
               }
               case "date" : {
                 return (
                   <div key={index} style={{marginLeft:5, marginRight:5, width:item.width+'px'}}>
                     <KTDateField type="date" name={item.name} label={translate(item.caption ? item.caption : item.name)} placeholder=" "  align="center"
                       defaultValue={(filters[module.name] ? filters[module.name][item.name].value : item.value)}
                       onChange={(e) => this._onFilterDateTimeChange(e)} small light outline />
                   </div>
                 )
               }
               default: {
                 return null
               }
            }
          })}
          <div style={{display:'flex', marginLeft:5, marginRight:5}}>
            <KTButton size="small" onClick={loadData} style={{fontSize:"0.7rem", backgroundColor:settings.theme.primary.light}} loading={action}  >
              {translate("button.query")}
            </KTButton>
          </div>
          <div style={{flexGrow: 1}} />

        </Toolbar>
      </AppBar>

      <div style={{flexGrow:1}}>

        <div style={{
          width:docContentWith,
          height:docContentHeight,
          overflow:"auto",
          background: "rgb(204,204,204)"
        }}>

          <div  className={`document-container`}  >

            {action &&
            <Grid container direction="row" justify="center" alignItems="center" style={{width:'100%',height:'400px'}} >
              <CircularProgress />
            </Grid>}

            {data && !action &&
            <div className={`document `+(props.size ? props.size : `A4landscape`)} style={{paddingTop:0, paddingBottom:0}}>
              <div className={`document-page `+(props.size ? props.size : `A4landscape`)} >
                Test {data.length}
              </div>
            </div>}

          </div>

        </div>

      </div>
    </Fragment>
  )

})
