module.exports = {
	"sidebar.section.main_menu": "เมนูหลัก",
	"sidebar.dashboard": "Dashboard",
	"sidebar.report": "รายงาน",
	"sidebar.services": "รายการแจ้งซ่อม",
	"sidebar.services_appointment": "นัดหมายซ่อม",

	"button.verified" : "ตรวจสอบ",

	"service.title_for_technician" : "รายละเอียดการปฏิบัติงานช่าง",
	"service.title_photos" : "รูปภาพการปฏิบัติงาน",
	"service.detail": "ใบแจ้งซ่อม / ติดตั้งอุปกรณ์",
	"service.service_type" : "ประเภทงาน",
	"service.service_type_desc" : "ระบุประเภทงาน (อื่นๆ)",
	"service.price" : "ราคาที่เสนอ",
	"service.br_code" : "BR No.",
	"service.technician" : "เจ้าหน้าที่",
	"service.phone" : "เบอร์ติดต่อ",
	"service.bank" : "ธนาคาร",
	"service.branch" : "สาขา",
	"service.category" : "อาการเสีย",
	"service.request_no" : "เลขงาน",
	"service.photo_request1" : "ภาพประกอบ 1",
	"service.photo_request2" : "ภาพประกอบ 2",
	"service.service_date" : "วันที่นัดหมาย",
	"service.service_description" : "รายละเอียดการซ่อม",
	"service.remark"  : "หมายเหตุ (สำหรับเจ้าหน้าที่ Admin)",
	"service.remark_technician"  : "หมายเหตุ (สำหรับเจ้าหน้าที่ช่าง)",
	"service.status": "สถานะ",
	"service.doc_status": "สถานะงาน",
	"service.job_status": "สถานะเอกสาร",
	"service.status.All": "ทั้งหมด",
	"service.status.Opened": "เปิดงาน",
	"service.status.OnWork": "รับงาน",
	"service.status.MakeAppointment": "นัดหมาย",
	"service.status.FollowAppointment": "เข้าตามนัดหมาย",
	"service.status.Closed": "ปิดงาน",
	"service.status.ClosedByTech": "ช่างปิดงาน",
	"service.status.Verified": "ตรวจสอบแล้ว",
	"service.status.Cancelled": "ยกเลิก",
	"service.created_time" : "่แจ้งงาน",
	"service.closed_time" : "ปิดงาน",
	"service.opened_date" : "วันที่แจ้งงาน",
	"service.opened_by" : "ผู้แจ้ง",

	"service.item_no" : "ลำดับ",
	"service.item_type" : "ประเภท",
	"service.item_info" : "รายการ",
	"service.item_price" : "ราคา",
	"service.item_total_price" : "รวม"
}
