import React from 'react';
import { connect} from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Grid, Paper, Typography } from '@material-ui/core';
import { browserName, browserVersion } from "react-device-detect";

import {KTButton, KTTextField} from '../KT'
import {MagbizEvent, MagbizGeneral} from '../utils/Magbiz'
import {actionPerform as actionPerformUser} from '../redux/User'
import {actionPerform as actionPerformInfo} from '../redux/Info'
import settings from  '../settings';

const styles = theme => ({
  container:{
    height: '100vh',
    padding:20,
    backgroundColor: theme.palette.primary.main
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 5,
    maxWidth: 400
  },
  textFieldWrap: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  }
});

class Login extends React.Component {

  state = {
    field:{
      username:(localStorage.hasOwnProperty("username") ? localStorage.getItem("username") : ""),
      password:""
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props.user.action.login !== nextProps.user.action.login){return true}
    return false
  }

  _onLogin = () => {
    if(browserName === "Chrome" && browserVersion < 90){
      MagbizEvent.alert("Your browser is too old, please update your chrome to latest version")
    }
    if(browserName !== "Chrome" && browserName !== "Brave" && MagbizGeneral.includes([settings.name], ["dsa", "dsa-sandbox"])){
      MagbizEvent.alert("You need chrome browser to access this system")
    }else{
      this.props.actionPerformInfo("INFO_CLEAR_ALL")
      this.props.actionPerformUser("USER_OPERATION", {action:"LOGIN", body:this.state.field} )
    }
  }

  _onFieldChange = (event) => {
    if(this.state.field.hasOwnProperty(event.target.name)){
      this.setState({field : {...this.state.field, [event.target.name]:event.target.value} })
    }
  }
/*<Grid item xs >
  <img src={window.location.origin+"/"+settings.logo} alt="Logo" style={{width:120,height:"auto"}} />
</Grid>*/
  render() {

    const { classes } = this.props;
    const tlang = this.props.intl.formatMessage;
    const defaultUsername = this.state.field.username
    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.container}>
          <Grid item xs={12} md={6} lg={4} >
            <Grid container direction="row" justify="center" >
              <Paper className={classes.paper} elevation={12}>
                <Grid container direction="column" justify="center" alignItems="center" style={{marginTop:20}}>
                  {settings.logo &&
                  <Grid item xs >
                    <img src={window.location.origin+"/"+settings.logo} alt="Logo" style={{width:120,height:"auto"}} />
                  </Grid>}
                  <Grid item xs style={{marginTop:10}}>
                    <Typography variant="h6"  style={{textAlign:'center', textTransform: 'uppercase'}}>
                      {settings.title}
                    </Typography>
                  </Grid>
                  <Grid item xs >
                    <Typography variant="caption" style={{textAlign:'center', color:"#bdbdbd"}}>
                      {settings.description}
                    </Typography>
                  </Grid>
                  <fieldset disabled={this.props.user.action.login} className={classes.textFieldWrap}>
                    <KTTextField required name="username" label={tlang({id:"username"})} placeholder="Username" defaultValue={defaultUsername}
                      icon="account-circle" onChange={(e) => this._onFieldChange(e)} />
                    <KTTextField required type="password" name="password" label={tlang({id:"password"})} placeholder="Password"
                      icon="key" onChange={(e) => this._onFieldChange(e)} />
                    <KTButton onClick={this._onLogin} loading={this.props.user.action.login} style={{marginTop:16}} >
                      {tlang({id:"button.login"})}
                    </KTButton>
                  </fieldset >
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

    );
  }
}
Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(({app, user}) => {
	return {app, user};
}, {
  actionPerformUser,
  actionPerformInfo
})(injectIntl(withStyles(styles, { withTheme: true })(Login)))
