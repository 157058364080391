import React from 'react';
import {useSelector} from 'react-redux';
import {CircularProgress} from '@material-ui/core';

const styles = {
  container: {
    position:'absolute',
    left:0,
    right:0,
    width:'100vw',
    height:'100vh',
    backgroundColor: 'rgba(0,0,0,0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex:9999
  },
  centerSpan: {
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center'
  },
  circle: {
    color:'#fff'
  },
  text: {
    color:'#fff',
    marginTop:5
  },
};


export default function KTLoadingStandalone(props){

  const appscreen = useSelector(state => state.app.screen)

  let containerStyle = {...styles.container}
  if(appscreen && appscreen.appBarSize && appscreen.appBarSize.height){
    if(props.dashboard){
      containerStyle.height = "100%"
    }else{
      containerStyle.height = 'calc(100% - '+appscreen.appBarSize.height+'px)'
    }
  }

  return (
    <div style={containerStyle}>
      <div style={styles.centerSpan}>
        {!props.nocircle &&
        <CircularProgress size={24} style={styles.circle}/>}
        <div style={styles.text}>{(props.message ? props.message : "Please wait...")}</div>
      </div>
    </div>
  )

}
