import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from "redux-saga";
import { all } from 'redux-saga/effects';

import {appReducer} from './App';
import {userReducer} from './User';
import {reportReducer} from './Report';
import {infoReducer} from './Info';

import userSagas from './User';
import reportSagas from './Report';
import infoSagas from './Info';

const reducers = combineReducers({
  app: appReducer,
  user: userReducer,
  report: reportReducer,
  info: infoReducer
});

function* rootSaga(getState) {
    yield all([
        userSagas(),
        reportSagas(),
        infoSagas()
    ]);
}

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

export function configureStore(initialState) {
    const store = createStore(
        reducers,
        initialState,
        compose(applyMiddleware(...middlewares))
    );
    sagaMiddleware.run(rootSaga);
    return store;
}
