import React, {useEffect, useState} from 'react';
import { Chart } from 'react-google-charts';

const KTWGoogleChart = (props) => {

  const [charts, setCharts] = useState(null)

  useEffect(() => {
    if(props.data && props.data.charts){
      const charts = props.data.charts.map((chart) => {
        if(chart.isTimeseries){
          const chartdata = chart.chartdata.map(item => {
            const [dateStr, ...rest] = item
            let prest = rest
            if(chart.haveAnnotation){
              prest = rest.map((itm) => {
                if(itm === "null") return null
                return itm
              })
            }
            const date = new Date(dateStr)
            return [date, ...prest]
          })
          return {...chart, chartdata:chartdata}
        }else{
          return chart
        }
      })
      setCharts(charts)
    }
  }, [props.data])

  const createMarkup = (text) => {
    return {__html: text};
  }

  return (
    <div style={{width:"100%", paddingTop:30, paddingBottom:30}}>
      {props.data && <div style={{width:"100%", textAlign:"center", fontSize:16, fontWeight:"bold"}} dangerouslySetInnerHTML={createMarkup(props.data.title)} />}
      {charts && charts.map((chart, chartIndex) => {
        return (
          <Chart
            key={chartIndex}
            chartType="LineChart"
            width="100%"
            height={chart.height}
            data={chart.chartdata}
            options={chart.option}
          />
        )
      })}
    </div>
  );
};

export default KTWGoogleChart;
