//import {MagbizDateTime} from '../utils/Magbiz'

const initialState = {
  lang:(localStorage.hasOwnProperty("lang") ? localStorage.getItem("lang") : "th"),
  screen:{
    width:0,
    height:0,
    appBarSize:{width:0, height:0},
    isFullscreen:false,
    showMenu:false,
  },
  interface:{
    printRef:null,
    onBeforePrint:null,
    onAfterPrint:null,
    search_text:""
  },
  map:{
    isEnabled:false,
    isShow:false,
    mode:"",
    working:null,
    data:null
  },
  geos:{},
  ajaxOptions:{}
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'APP_SETUP_SCREEN':
      return {...state, screen:action.payload};
    case 'APP_CHANGE_LANG':
      if(action.payload){
        localStorage.setItem("lang", action.payload)
      }
      return {...state, lang:action.payload};
    case 'APP_SETUP_INTERFACE':
      return {...state, interface:action.payload};
    case 'APP_GEO_CHANGE':
      return {...state, geos:action.payload};
    case 'APP_AJAX_OPTION_CHANGE':
      return {...state, ajaxOptions:action.payload};
    case 'APP_VAR':
      return {...state, [action.payload.fdata]:action.payload.info};
    case 'APP_SETTING_MAP':
      return {...state, map:action.payload};
    default:
      return {...state};
  }
};

// ############################## Global Action ##########################
//const actionInternal = (type, payload) => {
//  return { type: type, payload: payload }
//};

// ############################## Global Async Action ##########################
export const actionPerform = (type, payload) => {
  return { type: type , payload: payload}
};
