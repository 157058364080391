import React,{Fragment} from 'react';
import { injectIntl } from 'react-intl';
import { connect} from 'react-redux';
import { withRouter } from "react-router";
import classNames from 'classnames';
import MaterialIcon from 'react-material-iconic-font'
import { withStyles } from '@material-ui/core/styles';
//import {TableCell,TableSortLabel,, , CircularProgress} from '@material-ui/core';
import {AppBar,Typography, Toolbar, Tooltip, Grid, IconButton} from '@material-ui/core';
import {ArrowBack as ArrowBackIcon} from '@material-ui/icons';

import {actionPerform as actionPerformInfo} from '../../redux/Info'
import settings from '../../settings'
import {KTSelectField, KTButton, KTTextField} from '../../KT'
//import {MagbizUI} from '../../utils/Magbiz'

import DetailForm from './DetailForm';

const drawerWidth = 240;

const styles = theme => {
  return {
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
      backgroundColor:"rgb(211, 47, 47)"
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
    subText:{
      fontSize: '0.7rem',
      backgroundColor:"white"
    },
    menuItemPrimaryText:{
      color:theme.palette.secondary.contrastText,
      fontSize: '0.8rem'
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    }
  }
};

class QueryView extends React.Component {

  state = {
    detailId:null
  }

  componentDidMount(){
    let filter = {...this.props.module.filter}
    filter = {...filter,...this.props.module.filter_fixed}
    this.props.actionPerformInfo("INFO_APPLY_FILTER", {fdata:this.props.module.name, filter: filter })
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props.app.screen !== nextProps.app.screen){return true}
    if(this.props.app.lang !== nextProps.app.lang){return true}
    if(this.props.info[this.props.module.name] !== nextProps.info[this.props.module.name]){
      if(nextProps.info[this.props.module.name].length > 0){
        this._onRowClick({rowData:nextProps.info[nextProps.module.name][0]})
        return false
      }
    }
    if(this.props.info.action[this.props.module.name] !== nextProps.info.action[this.props.module.name]){return true}
    if(this.props.info.mode !== nextProps.info.mode){return true}
    if(this.props.info[this.props.module.detail.name] !== nextProps.info[this.props.module.detail.name]){return true}
    if(this.props.info.action[this.props.module.detail.name+"_operation"] !== nextProps.info.action[this.props.module.detail.name+"_operation"]){return true}
    if(this.props.module !== nextProps.module){return true}
    if(this.state !== nextState){return true}
    return false
  }

  _onFilterChange = (event) => {
    if(this.props.module.filter.hasOwnProperty(event.target.name)){
      let filter = this.props.info.filters[this.props.module.name]
      filter[event.target.name].value = event.target.value
      filter[event.target.name].text = (typeof event.target.label !== 'undefined' ? event.target.label : event.target.value)
    }
  }

  _onRowClick = (event) => {
    if(this.props.module.disableDetailRender){
      this.setState({detailId: event.rowData[this.props.module.dataId]})
    }else{
      this.props.history.push(this.props.match.path+"/detail/"+event.rowData[this.props.module.dataId])
    }
  }

  _onBackClick = (event) => {
    this.props.actionPerformInfo("INFO_CLEAR", {fdata:this.props.module.detail.name})
    this.props.actionPerformInfo("INFO_MODE", {mode:"view"})
    if(this.props.module.disableDetailRender){
      this.setState({detailId: null})
    }else{
      this.props.history.goBack()
    }
  }

  _onFilterChange = (event) => {
    if(this.props.module.filter.hasOwnProperty(event.target.name)){
      let filter = this.props.info.filters[this.props.module.name]
      filter[event.target.name].value = event.target.value
      filter[event.target.name].text = (typeof event.target.label !== 'undefined' ? event.target.label : event.target.value)
    }
  }

  loadData = () => {
    const filter = this.props.info.filters[this.props.module.name]
    const body = Object.values(filter).reduce((accumulator, currentValue) => {
      if(currentValue){
        accumulator[currentValue.name] = currentValue.value
      }
      return accumulator
    }, {})
    this.props.actionPerformInfo("INFO_OPERATION", {action:this.props.module.actionPerform, body:{...body, order_by:this.props.module.order_by}, fdata:this.props.module.name})
  }

  render() {
    //console.log("Render QueryView : "+this.props.module.name)
    const {classes} = this.props
    const filters = this.props.info.filters
    const tlang = this.props.intl.formatMessage

    let sidebar_name = this.props.match.path.replace("/"+settings.name+"/","").replace("/detail","").replace("/","_")
    if(sidebar_name.indexOf("/:") !== -1){
      sidebar_name = sidebar_name.substring(0,sidebar_name.indexOf("/:"))
    }
    const page_name = tlang({"id":"sidebar."+sidebar_name})
    const docWidth = (this.props.width ? this.props.width : this.props.app.screen.width)
    const showSideBar = (this.props.module.menu && docWidth >= 1280 ? true : false)
    const docContentWith = docWidth - (showSideBar ? drawerWidth : 0)
    const docContentHeight = (this.props.height ? this.props.height : window.innerHeight - this.props.app.screen.appBarSize.height*2)

    return (
      <Fragment>

        {(this.props.match.path.indexOf("/detail") !== -1 || this.state.detailId) &&
        <Fragment>
          <AppBar position="sticky" style={{backgroundColor:settings.theme.primary.dark, zIndex: 900}} >
            <Toolbar style={{  paddingLeft:10,paddingRight:10}}>

              <Tooltip title="Back" style={{marginRight:10}}>
                <IconButton color="inherit" onClick={this._onBackClick}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>

              <div style={{ flexDirection:'column', justifyContent:'center', marginRight:10}}>
                <Grid container direction="column" justify="center">
                  <Typography variant="subtitle1" color="inherit" >
                    {tlang({"id":"detail_of"})+" "+page_name}
                  </Typography>
                </Grid>
              </div>
              <div  style={{margin:10}}>
                <MaterialIcon type={"flip"} />
              </div>
              <div style={{flexGrow: 1}} />

              {this.props.module.action &&
              <Fragment >
                {this.props.module.action.map((item, index) => {
                  if(this.props.module.permission.allowCondition(item.name, this.props.info[this.props.module.detail.name], this.props.info.mode)){
                    return (
                      <div  key={index}  style={{display:'flex', marginLeft:5, marginRight:5}}>
                        <KTButton size="small" onClick={item.operation} style={{fontSize:"0.7rem", backgroundColor:(item.color ? item.color : settings.theme.primary.light)}} loading={this.props.info.action[this.props.module.detail.name+"_operation"]}  >
                          {item.text}
                        </KTButton>
                      </div>
                    )
                  }else{
                    return null
                  }
                })}

              </Fragment>}

            </Toolbar>
          </AppBar>
          <DetailForm
            module={this.props.module}
            width={docWidth}
            height={docContentHeight}
            reload={() => {this.loadData()}}
            back={this._onBackClick}
            selectId={(this.state.detailId ? this.state.detailId : window.location.pathname.replace(this.props.match.path+"/",""))}
          />
        </Fragment>}

        {(this.props.match.path.indexOf("/detail") === -1  && !this.state.detailId) &&
        <Fragment>
          <AppBar position="sticky" style={{backgroundColor:settings.theme.primary.dark,zIndex: 900}}>
            <Toolbar >
              <div style={{flexDirection:'column', justifyContent:'center', marginRight:10}}>
                <Grid container direction="column" justify="center">
                  <Typography variant="subtitle1" color="inherit" >
                    {page_name}
                  </Typography>
                </Grid>
              </div>

              <div style={{marginLeft:10, marginRight:10}}>
                <MaterialIcon type={"filter-list"} />
              </div>

              <div className={classes.sectionMobile} style={{flexGrow: 1}} />

            </Toolbar>
          </AppBar>

          <div className={classNames(classes.content, {
              [classes.contentShift]: showSideBar,
            })}>

            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              style={{ width: docContentWith, height: docContentHeight}} >

              <Grid item xs={6}>
                {this.props.module.filter && Object.values(this.props.module.filter).map((item, index) => {
                  if(item === null) return null
                  if(item.isHide) return null
                  switch(item.type) {
                     case "select": {
                       return (
                         <div key={index} style={{marginLeft:10, marginRight:10, width:item.width+'px'}}>
                           <KTSelectField name={item.name} label={tlang({"id":(item.caption ? item.caption : item.name )})} placeholder=" " align="center"
                             icon={item.icon} defaultValue={(filters[this.props.module.name] ? filters[this.props.module.name][item.name].value : item.value)}
                             suggestions={item.list} async={Boolean(item.async)}
                             onChange={(e) => this._onFilterChange(e)} small light />
                         </div>
                       )
                     }
                     case "text":{
                       return (
                       <div key={index} style={{marginLeft:10, marginRight:10, width:item.width+'px'}}>
                         <KTTextField type={item.type} name={item.name} label={tlang({"id":(item.caption ? item.caption : item.name )})} placeholder=" "
                           icon={item.icon} onChange={(e) => this._onFilterChange(e)} small light />
                        </div>
                       )
                     }
                     default: {
                       return null
                     }
                  }
                })}
                <div style={{marginLeft:10, marginRight:10, width:'200px'}}>
                  <KTButton onClick={this.loadData} loading={Boolean(this.props.info.action[this.props.module.name])} style={{marginTop:10}} >
                    {tlang({id:"button.query"})}
                  </KTButton>
                </div>

              </Grid>

            </Grid>


          </div>

        </Fragment>}

      </Fragment>
    );

  }

}

export default withRouter(connect(({app, info}) => {
	return {app, info};
}, {
  actionPerformInfo
})(injectIntl(withStyles(styles, { withTheme: true })(QueryView))));
