import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import api from '../api';

const initialState = {
  action: {
    login:false,
    logout:false
  },
  userdata : JSON.parse(localStorage.getItem("userdata"))
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGIN_PERFORMING':
      return {...state, action: {...state.action, login:true} };
    case 'USER_LOGIN_SUCCESS':
      if(action.payload){
        localStorage.setItem("username", action.payload.username)
        localStorage.setItem("userdata", JSON.stringify(action.payload))
      }
      return {...state, action: {...state.action, login:false}, userdata : action.payload};
    case 'USER_LOGIN_FAILURE':
      NotificationManager.error(action.payload);
      return {...state, action: {...state.action, login:false} };
    case 'USER_LOGOUT_PERFORMING':
      return {...state, action: {...state.action, logout:true} };
    case 'USER_LOGOUT_SUCCESS':
      //if(localStorage.hasOwnProperty("userdata")){
      //  localStorage.removeItem("userdata")
      //}
      localStorage.clear()
      return {...state, action: {...state.action, logout:false}, userdata : null};
    case 'USER_LOGOUT_FAILURE':
      //Still force logout even fail to logout from server
      //if(localStorage.hasOwnProperty("userdata")){
      //  localStorage.removeItem("userdata")
      //}
      localStorage.clear()
      return {...state, action: {...state.action, logout:false}, userdata : null};
    default:
      return {...state};
  }
};

// ############################## Internal Action ##########################
const actionInternal = (type, payload) => {
  return { type: type, payload: payload }
};

// ############################## Global Async Action ##########################
export const actionPerform = (type, payload) => {
  return { type: type , payload: payload}
};

// #######################################################################
function* asyncOperation({ payload }) {
  try {
    yield put(actionInternal("USER_"+payload.action+"_PERFORMING"));
    const result = yield call(() => {return api("user/"+payload.action.toLowerCase(),payload.body)});
    if (result.error) {
        yield put(actionInternal("USER_"+payload.action+"_FAILURE", result.error.message));
        if(payload.callback){
          payload.callback(false)
        }
    } else {
        yield put(actionInternal("USER_"+payload.action+"_SUCCESS",result.info));
        if(payload.callback){
          payload.callback(true)
        }
    }
  } catch (err) {
    console.log(err.message);
    yield put(actionInternal("USER_"+payload.action+"_FAILURE", "Unexpected Error"))
    if(payload.callback){
      payload.callback(false)
    }
  }
}

// ############################## Watch Saga ##########################
function* watchOperation() {
  yield takeEvery('USER_OPERATION', asyncOperation);
}

export default function* rootSaga() {
    yield all([
        fork(watchOperation)
    ]);
}
