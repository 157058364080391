import React,{Fragment} from 'react';
import { injectIntl } from 'react-intl';
import { connect} from 'react-redux';
import { withRouter } from "react-router";
import classNames from 'classnames';
import MaterialIcon from 'react-material-iconic-font'
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet'
//import {TableCell,TableSortLabel,, , CircularProgress} from '@material-ui/core';
import {Fab,AppBar,Typography, Toolbar, Tooltip, Grid, IconButton, List, ListSubheader, ListItem, ListItemIcon, ListItemText, Divider} from '@material-ui/core';
import {Add as AddIcon, ArrowBack as ArrowBackIcon} from '@material-ui/icons';
import moment from 'moment'

import {actionPerform as actionPerformInfo} from '../../redux/Info'
import {actionPerform as actionPerformApp} from '../../redux/App'
import settings from '../../settings'
import {KTSelectField, KTDateField, KTTimeField, KTButton, KTDownloadExcel, KTIcon} from '../../KT'
import {MagbizDateTime,MagbizUI,MagbizGeneral} from '../../utils/Magbiz'

import TableList from './TableList';
import TableInfiniteList from './TableInfiniteList';
import UISectionList from './UISectionList';
import DetailForm from './DetailForm';

const drawerWidth = 240;

const styles = theme => {
  return {
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
    subText:{
      fontSize: '0.7rem',
      backgroundColor:"white"
    },
    menuItemPrimaryText:{
      color:theme.palette.secondary.contrastText,
      fontSize: '0.8rem'
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    }
  }
};

class TableListView extends React.Component {

  state = {
    menu_filter: null,
    detailId:null,
    dataRows:null,
    interval_id:null,
    selecteds: {},
    selectedAll:false,
  }

  componentDidMount(){
    if(this.props.match.path.indexOf("/detail") === -1){
      const me = this
      this.setState({menu_filter:(this.props.info.menu_filters[this.props.module.name] ? this.props.info.menu_filters[this.props.module.name] : (this.props.module.menu ? MagbizUI.getSelectMenuItem(this.props.module.menu) : null))})
      setTimeout(function(){
        if(!me.props.info[me.props.module.name]){
          me.loadData(me.props.info.filters[me.props.module.name])
        }else{
          me.getDataRows(me.props.info[me.props.module.name], me.props.app.interface.search_text)
        }
      },200)
    }else{
      if(!this.props.info.doc_option[this.props.module.name]){
        const doc_option = {...this.props.module.doc_option}
        this.props.actionPerformInfo("INFO_APPLY_DOCOPTION", {fdata:this.props.module.name, doc_option: doc_option })
      }
    }

    if(this.props.module.refresh_interval){
      const interval_id = setInterval(() => {
        console.log("Reload",new Date())
        this.loadData(this.props.info.filters[this.props.module.name])
      }, this.props.module.refresh_interval)
      console.log("Interval ID",interval_id)
      this.setState({interval_id: interval_id})
    }

    if(this.props.module.mapData && !this.props.app.map.isEnabled){
      this.props.actionPerformApp("APP_SETTING_MAP", {...this.props.app.map, isEnabled:true })
    }

  }

  componentWillUnmount(){
    if(this.state.interval_id){
      console.log("Clear Interval ID", this.state.interval_id)
      clearInterval(this.state.interval_id)
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props.app.lang !== nextProps.app.lang){return true}
    if(this.props.app.interface.search_text !== nextProps.app.interface.search_text){
      this.getDataRows(nextProps.info[nextProps.module.name], nextProps.app.interface.search_text)
      return false
    }
    if(this.props.info[this.props.module.name] !== nextProps.info[this.props.module.name]){
      this.getDataRows(nextProps.info[nextProps.module.name], nextProps.app.interface.search_text)
      return false
    }
    if(this.props.reload !== nextProps.reload){
      if(nextProps.reload){
        this.loadData(this.props.info.filters[this.props.module.name])
      }
      return false
    }
    if(this.props.info.action[this.props.module.name] !== nextProps.info.action[this.props.module.name]){return true}
    if(this.props.info.mode !== nextProps.info.mode){return true}
    if(this.props.info[this.props.module.detail.name] !== nextProps.info[this.props.module.detail.name]){return true}
    if(this.props.info.action[this.props.module.detail.name+"_operation"] !== nextProps.info.action[this.props.module.detail.name+"_operation"]){return true}
    if(this.props.module !== nextProps.module){return true}
    if(this.state !== nextState){return true}
    if(this.props.app.screen !== nextProps.app.screen){return true}
    if(this.props.width !== nextProps.width){return true}
    if(this.props.app.map.isShow !== nextProps.app.map.isShow){return true}
    if(this.props.app.map.isChanged !== nextProps.app.map.isChanged){return true}
    return false
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.menu_filter !== prevState.menu_filter){
      this.getDataRows(this.props.info[this.props.module.name], this.props.app.interface.search_text)
    }
    this.checkShowMap(this.props.app.map, this.props.info.mode, this.props.info[this.props.module.detail.name]);
  }

  checkShowMap = (map, mode, detail) => {
    if(map.isEnabled){
      const doShow = (this.props.module.mapData && (this.props.match.path.indexOf("/detail") !== -1 || this.state.detailId) && (mode === "add" || Boolean(detail)))
      if(map.isChanged){
        this.props.actionPerformApp("APP_SETTING_MAP", {...map, mode:mode, isChanged:false, working:detail, data:this.props.module.mapData})
      }else if(!map.isShow && doShow){
        this.props.actionPerformApp("APP_SETTING_MAP", {...map, mode:mode, isShow:true, working:detail, data:this.props.module.mapData})
      }else if(map.isShow && !doShow){
        this.props.actionPerformApp("APP_SETTING_MAP", {...map, mode:mode, isShow:false, working:null, data:null})
      }else if(map.mode !== mode && map.isShow){
        this.props.actionPerformApp("APP_SETTING_MAP", {...map, mode:mode})
      }
    }
  }

  _onMenuFilterChange = (event, item) => {
    this.setState({menu_filter: item})
    this.props.actionPerformInfo("INFO_APPLY_MENUFILTER", {fdata:this.props.module.name, menu_filter: item })
  }

  _onFilterDateTimeChange = (dateObj) => {
    this._onFilterChange({target:dateObj})
  }

  _onFilterChange = (event) => {
    if(this.props.module.filter.hasOwnProperty(event.target.name)){
      let filter = this.props.info.filters[this.props.module.name]
      filter[event.target.name].value = event.target.value
      filter[event.target.name].text = (typeof event.target.label !== 'undefined' ? event.target.label : event.target.value)
      this.loadData(filter)
      if(this.props.module.onFilterChange){
        this.props.module.onFilterChange(filter)
      }
    }
  }

  _onDocOptionChange = (event) => {
    if(this.props.module.doc_option.hasOwnProperty(event.target.name)){
      let doc_option = this.props.info.doc_option[this.props.module.name]
      doc_option[event.target.name].value = event.target.value
      doc_option[event.target.name].text = (typeof event.target.label !== 'undefined' ? event.target.label : event.target.value)
      this.props.actionPerformInfo("INFO_APPLY_DOCOPTION", {fdata:this.props.module.name, doc_option: doc_option })
      if(this.props.module.onDocOptionChange){
        this.props.module.onDocOptionChange(doc_option)
      }
    }
  }

  _onAddButtonClick = (event) => {
    if(this.props.module.disableDetailRender){
      this.setState({detailId: "add"})
    }else{
      this.props.history.push(this.props.match.path+"/detail/add")
    }
  }

  _onRowClick = (event) => {
    if(this.props.module.onRowClick){
      this.props.module.onRowClick(event.rowData)
    }else if(this.props.module.disableDetailRender){
      this.setState({detailId: event.rowData.dataId})
    }else{
      this.props.history.push(this.props.match.path+"/detail/"+event.rowData.dataId)
    }
  }

  _onSelectRow = (rowData) => {
    if(this.props.module.onSelectRow){
      this.setState({selecteds:this.props.module.onSelectRow(rowData, this.state.selecteds)})
    }
  }

  _onSelectAll = (isSelected) => {
    const newIsSelected = !isSelected
    if(this.props.module.onSelectAll){
      this.setState({selectedAll:newIsSelected, selecteds:this.props.module.onSelectAll(newIsSelected, this.state.selecteds, this.state.dataRows)})
    }
  }

  _onBackClick = (event) => {
    this.props.actionPerformInfo("INFO_CLEAR", {fdata:this.props.module.detail.name})
    this.props.actionPerformInfo("INFO_MODE", {mode:"view"})
    if(this.props.module.disableDetailRender){
      this.setState({detailId: null})
    }else{
      //this.props.history.push("/"+settings.name+"/"+this.props.module.name)
      this.props.history.goBack()
    }
  }

  loadData = (filter) => {
    if(!filter){
      filter = {...this.props.module.filter}
      filter = {...filter,...this.props.module.filter_fixed}
      this.props.actionPerformInfo("INFO_APPLY_FILTER", {fdata:this.props.module.name, filter: filter })
    }
    const body = Object.values(filter).reduce((accumulator, currentValue) => {
      if(currentValue){
        accumulator[currentValue.name] = currentValue.value
      }
      return accumulator
    }, {})
    this.setState({selectedAll:false, selecteds: {}})
    this.props.actionPerformInfo("INFO_OPERATION", {action:this.props.module.actionPerform, body:{...body, order_by:this.props.module.order_by}, fdata:this.props.module.name})
  }

  getDataRows = (datas, search_text) => {
    let dataRows = []
    if(datas){
      dataRows = datas.filter(item => {
        return this.filterData(item)
      }).map((item, index) => {
        return this.parseData(item, index)
      }).filter(item => {
        return this.searchData(item, search_text)
      })
    }
    this.setState({dataRows:dataRows})
  }

  filterData = (item) => {
    if(this.state.menu_filter){
      if(this.state.menu_filter.key+"" === "0" || (this.state.menu_filter.key+"").toLowerCase() === "all"){
        //if((item[this.state.menu_filter.name]+"").toLowerCase() === "cancelled" ){
        //  return false
        //}
      }else if(this.state.menu_filter.name_age && (this.state.menu_filter.key+"").startsWith("AGE_")){
        if((this.state.menu_filter.key+"").startsWith("AGE_BETWEEN_")){
          const age_days = this.state.menu_filter.key.replace("AGE_BETWEEN_","").split("_")
          if(age_days.length === 2){
            const age = MagbizDateTime.dateDiff(moment().format("YYYY-MM-DD"), item[this.state.menu_filter.name_age])
            if(age < parseInt(age_days[0]) || age > parseInt(age_days[1])){
              return false
            }
          }
        }else if((this.state.menu_filter.key+"").startsWith("AGE_OVER_")){
          const age_days = parseInt(this.state.menu_filter.key.replace("AGE_OVER_",""))
          if(MagbizDateTime.dateDiff(moment().format("YYYY-MM-DD"), item[this.state.menu_filter.name_age]) <= age_days){
            return false
          }
        }
      }else if(item[this.state.menu_filter.name]+"" !== this.state.menu_filter.key+""){
        return false
      }
    }
    return true
  }

  searchData = (item, search_text) => {
    if(this.props.module.searchable && search_text !== ""){
      for(let i=0;i<this.props.module.searchable.length;++i){
        const sitem = item[this.props.module.searchable[i]]
        if(sitem){
          if(typeof sitem === "object"){
            const ssitem = sitem[this.props.module.searchable[i]]
            if(ssitem.toLowerCase().includes(search_text.toLowerCase())){
              return true
            }
          }else if(typeof sitem === "string"){
            if(sitem.toLowerCase().includes(search_text.toLowerCase())){
              return true
            }
          }
        }
      }
      return false
    }
    return true
  }

  parseData = (item, index) => {
    const objects = this.props.module.columns.reduce((objects, col) => {
      if (col.columnCalculation) {
        objects[col.dataKey] = col.columnCalculation(item)
      }else if (col.format) {
        objects[col.dataKey] = col.format(item[col.dataKey])
      }else if (col.list) {
        if(!col.isMulti){
          if(col.list[item[col.dataKey]]){
            objects[col.dataKey] = col.list[item[col.dataKey]].text
          }else if(item[col.dataKey] !== 0 && item[col.dataKey] !== "" && item[col.dataKey] !== null && typeof item[col.dataKey] !== "undefined" ){
            objects[col.dataKey]  = "[!"+item[col.dataKey]+"]"
          }else{
            objects[col.dataKey] = ""
          }
        }else{
          const arrs = (item[col.dataKey] ? item[col.dataKey].split("|") : [])
          let values = ""
          for(let i=0;i<arrs.length;++i){
            if(arrs[i] !== ""){
              if(col.list[arrs[i]]){
                values += ", "+col.list[arrs[i]].text
              }else{
                //values += ","+arrs[i]
              }
            }
          }
          objects[col.dataKey] = (values !== "" ? values.substring(1) : "")
        }
      }else if (col.cellContentRenderer) {
        let cellData = {}
        for(let i=0;i<col.cellContentKeys.length;++i){
          cellData[col.cellContentKeys[i]] = item[col.cellContentKeys[i]]
        }
        objects[col.dataKey] = cellData
      }else{
        objects[col.dataKey] = item[col.dataKey]
      }

      if (col.display && objects[col.dataKey]) {
        objects[col.dataKey] = col.display
      }

      if(col.sortType){
        if(col.sortType === "number"){
          objects[col.dataKey+"_"+col.sortType] = MagbizGeneral.numberValue(objects[col.dataKey])
        }
      }

      return objects
    }, {})
    if(this.props.module.enableSelect){
      let cellData = {"dataId":item[this.props.module.dataId]}
      const keys = this.props.module.enableSelect.keys
      if(keys){
        for(let i=0;i<keys.length;++i){
          cellData[keys[i]] = item[keys[i]]
        }
      }
      objects["selector"] = cellData
    }
    objects["dataId"] = item[this.props.module.dataId]
    return objects
  }

  parseDataExcel = (item, index) => {
    const objects = this.props.module.export_columns.reduce((objects, col) => {
      if (col.columnCalculation) {
        objects[col.dataKey] = col.columnCalculation(item)
      }else if (col.format) {
        objects[col.dataKey] = col.format(item[col.dataKey])
      }else if (col.list) {
        if(col.list[item[col.dataKey]]){
          objects[col.dataKey] = col.list[item[col.dataKey]].text
        }else if(item[col.dataKey] !== 0){
          objects[col.dataKey]  = item[col.dataKey]
        }
      }else{
        objects[col.dataKey] = item[col.dataKey]
      }
      return objects
    }, {})
    objects["dataId"] = item[this.props.module.dataId]
    return objects
  }

  excelData = (xcdata) => {
    if(this.props.module.excelData){
      return this.props.module.excelData(xcdata)
    }else{
      return xcdata
    }
  }

  render() {
    //console.log("Render TableListView : "+this.props.module.name)
    const {classes} = this.props
    const filters = this.props.info.filters
    const doc_option = this.props.info.doc_option
    const tlang = this.props.intl.formatMessage

    let sidebar_name = this.props.match.path.replace("/"+settings.name+"/","").replace("/detail","").replace("/","_")
    if(sidebar_name.indexOf("/:") !== -1){
      sidebar_name = sidebar_name.substring(0,sidebar_name.indexOf("/:"))
    }
    const page_name = tlang({"id":"sidebar."+sidebar_name})
    const docWidth = (this.props.width ? this.props.width : (this.props.app.map.isShow ? this.props.app.screen.width*60/100 : this.props.app.screen.width))
    const isDesktop = (docWidth >= 1280 ? true : false)
    const showSideBar = (this.props.module.menu && isDesktop ? true : false)
    const docContentWith = docWidth - (showSideBar ? drawerWidth : 0)
    const docContentHeight = (this.props.height ? this.props.height : window.innerHeight - this.props.app.screen.appBarSize.height*2)
    const docListHeight = docContentHeight - (this.props.module.summaryPanel && this.state.dataRows ? 30 : 0)

    let columns_display = this.props.module.columns.filter(item => {
      if(item.hideWidth && docWidth < item.hideWidth){
        return false
      }
      if(item.hideCondition && item.hideCondition()){
        return false
      }
      return true
    })
    if(this.props.module.enableSelect){
      columns_display = [...[{label: "", dataKey: 'selector', selected:this.state.selectedAll, width: 40, justifyContent:"center", cellContentRenderer:(({cellData,columnIndex=null}) => {
        if(this.props.module.enableSelect.condition){
          if(!this.props.module.enableSelect.condition(cellData)) return null
        }
        return (
          <div style={{cursor:"pointer"}} onClick={() => this._onSelectRow(cellData)}>
            {this.state.selecteds[cellData.dataId] ? <KTIcon vicon="check-box" vicontype="MaterialIcons" size={18} /> :
            <KTIcon vicon="check-box-outline-blank" vicontype="MaterialIcons" size={18} />}
          </div>
        )
      }), cellContentKeys:[...["dataId"], ...this.props.module.enableSelect.keys] }], ...columns_display]
    }

    return (
      <Fragment>

        {(this.props.match.path.indexOf("/detail") !== -1 || this.state.detailId) &&
        <Fragment>

          <Helmet>
            <meta charSet="utf-8" />
            <title>{tlang({"id":"detail_of"})+" "+page_name}</title>
          </Helmet>

          <AppBar position="sticky" style={{backgroundColor:settings.theme.primary.dark, zIndex: 900}} >
            <Toolbar style={{  paddingLeft:10,paddingRight:10}}>

              <Tooltip title="Back" style={{marginRight:10}}>
                <IconButton color="inherit" onClick={this._onBackClick}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>

              <div className={classes.sectionDesktop} style={{ flexDirection:'column', justifyContent:'center', marginRight:10}}>
                <Grid container direction="column" justify="center">
                  <Typography variant="subtitle1" color="inherit" >
                    {tlang({"id":"detail_of"})+" "+page_name}
                  </Typography>
                </Grid>
              </div>
              <div className={classes.sectionDesktop} style={{margin:10}}>
                <MaterialIcon type={"flip"} />
              </div>
              <div style={{flexGrow: 1}} />

              {this.props.module.doc_option && this.props.info.mode === "view" && Object.values(this.props.module.doc_option).map((item, index) => {
                if(item === null) return null
                if(item.isHide) return null
                switch(item.type) {
                   case "select": {
                     return (
                       <div key={index} className={(index <= 1 ? "" : classes.sectionDesktop )} style={{marginLeft:10, marginRight:10, width:item.width+'px'}}>
                         <KTSelectField name={item.name} label={tlang({"id":(item.caption ? item.caption : item.name )})} placeholder=" " align="center"
                           icon={item.icon} defaultValue={(doc_option[this.props.module.name] ? doc_option[this.props.module.name][item.name].value : item.value)}
                           suggestions={item.list} async={Boolean(item.async)}
                           onChange={(e) => this._onDocOptionChange(e)} small light />
                       </div>
                     )
                   }
                   default: {
                     return null
                   }
                }
              })}

              {this.props.module.action &&
              <Fragment >
                {this.props.module.action.map((item, index) => {
                  if(this.props.module.permission.allowCondition(item.name, this.props.info[this.props.module.detail.name], this.props.info.mode)){
                    return (
                      <div  key={index}  style={{display:'flex', marginLeft:5, marginRight:5}}>
                        <KTButton size="small" onClick={() => item.operation(this.props.info[this.props.module.detail.name])} style={{fontSize:"0.7rem", textTransform:item.textTransform, backgroundColor:(item.color ? item.color : settings.theme.primary.main)}} loading={this.props.info.action[this.props.module.detail.name+"_operation"]}  >
                          {item.icon ? <span><KTIcon vicon={item.icon}  vicontype={item.icontype} size={"0.7rem"} color="white" />&nbsp;</span> : null}
                          {item.text}
                        </KTButton>
                      </div>
                    )
                  }else{
                    return null
                  }
                })}
              </Fragment>}

            </Toolbar>
          </AppBar>
          <DetailForm
            module={this.props.module}
            width={docWidth}
            height={docContentHeight}
            reload={() => {this.loadData(this.props.info.filters[this.props.module.name])}}
            back={this._onBackClick}
            selectId={(this.state.detailId ? this.state.detailId : window.location.pathname.replace(this.props.match.path+"/",""))}
          />
        </Fragment>}

        {(this.props.match.path.indexOf("/detail") === -1  && !this.state.detailId) &&
        <Fragment>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{page_name}</title>
          </Helmet>

          <AppBar position="sticky" style={{backgroundColor:settings.theme.primary.dark,zIndex: 900}}>
            <Toolbar >

              {this.props.module.showBackButton &&
              <Tooltip title="Back" style={{marginRight:10}}>
                <IconButton color="inherit" onClick={this._onBackClick}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>}

              <div className={classes.sectionDesktop} style={{flexDirection:'column', justifyContent:'center', marginRight:10}}>
                <Grid container direction="column" justify="center">
                  <Typography variant="subtitle1" color="inherit" >
                    {page_name}
                  </Typography>
                </Grid>
              </div>
              {/*<div className={classes.sectionMobile} style={{flexDirection:'column', justifyContent:'center', marginRight:10, width:60}}>
                <Grid container direction="column" justify="center">
                  <Typography variant="subtitle1" color="inherit" style={{fontSize:12, lineHeight:1.2}}>
                    {page_name}
                  </Typography>
                </Grid>
              </div>*/}
              <div className={classes.sectionDesktop} style={{marginLeft:10, marginRight:10}}>
                <MaterialIcon type={"filter-list"} />
              </div>

              {this.props.module.filter && Object.values(this.props.module.filter).map((item, index) => {
                if(item === null) return null
                if(item.isHide) return null
                if(index > 1 && !isDesktop) return null
                switch(item.type) {
                   case "select": {
                     return (
                       <div key={index} style={{marginLeft:2, marginRight:2, width:item.width+'px'}}>
                         <KTSelectField name={item.name} label={tlang({"id":(item.caption ? item.caption : item.name )})} placeholder={(item.placeholder ? item.placeholder : " ")} align="center"
                           icon={item.icon} defaultValue={(filters[this.props.module.name] ? filters[this.props.module.name][item.name].value : item.value)}
                           suggestions={item.list} async={Boolean(item.async)} isMulti={item.isMulti}
                           onChange={(e) => this._onFilterChange(e)} small light />
                       </div>
                     )
                   }
                   case "time": {
                     return (
                       <div key={index} style={{marginLeft:2, marginRight:2, width:item.width+'px'}}>
                         <KTTimeField type="time" name={item.name} label={tlang({"id":(item.caption ? item.caption : item.name )})} placeholder={(item.placeholder ? item.placeholder : " ")} align="center"
                           defaultValue={(filters[this.props.module.name] ? filters[this.props.module.name][item.name].value : item.value)}
                           onChange={(e) => this._onFilterDateTimeChange(e)} small light outline notblank />
                       </div>
                     )
                   }
                   case "date" : {
                     return (
                       <div key={index} style={{marginLeft:2, marginRight:2, width:item.width+'px'}}>
                         <KTDateField type="date" name={item.name} label={tlang({"id":(item.caption ? item.caption : item.name )})} placeholder={(item.placeholder ? item.placeholder : " ")} align="center"
                           defaultValue={(filters[this.props.module.name] ? filters[this.props.module.name][item.name].value : item.value)}
                           onChange={(e) => this._onFilterDateTimeChange(e)} small light outline />
                       </div>
                     )
                   }
                   default: {
                     return null
                   }
                }
              })}

              <div className={classes.sectionDesktop} style={{flexGrow: 1}} />
              <div className={classes.sectionDesktop}>
                {this.state.selecteds && Object.keys(this.state.selecteds).length > 0 && this.props.module.select_actions && this.props.module.select_actions.map((item, index) => {
                  return (
                    <div key={index} style={{marginLeft:5, marginRight:5}}>
                      <KTButton size="small"  onClick={() => item.onPerform(this.state.dataRows, this.state.selecteds)}
                        style={{fontSize:"0.7rem", textTransform:"none", backgroundColor:settings.theme.primary.main}} >
                        {item.icon ? <span><KTIcon vicon={item.icon}  vicontype={item.icontype} size={"0.7rem"} color="white" />&nbsp;</span> : null}
                        {item.label}
                      </KTButton>
                    </div>
                  )
                })}
                {this.props.module.exports && this.props.module.exports.map((item, index) => {
                  return (
                    <div key={index} style={{marginLeft:5, marginRight:5}}>
                      <KTButton size="small" onClick={() => item.onExport(this.state.dataRows)}
                        style={{fontSize:"0.7rem", textTransform:"none", backgroundColor:settings.theme.primary.main}} >
                        {item.label}
                      </KTButton>
                    </div>
                  )
                })}
                {this.props.module.buttons && this.props.module.buttons.map((item, index) => {
                  if(item.hidden) return null
                  return (
                    <div key={index} style={{marginLeft:5, marginRight:5}}>
                      <KTButton size="small"  onClick={() => item.onPerform(this.props.info[this.props.module.name])}
                        style={{fontSize:"0.7rem", textTransform:"none", backgroundColor:(item.color ? item.color : settings.theme.primary.main)}} >
                        {item.icon ? <span><KTIcon vicon={item.icon}  vicontype={item.icontype} size={"0.7rem"} color="white" />&nbsp;</span> : null}
                        {item.label}
                      </KTButton>
                    </div>
                  )
                })}
                {this.props.info[this.props.module.name] && this.props.module.export_columns &&
                <div style={{marginLeft:5, marginRight:5}}>
                  <KTDownloadExcel filename={page_name+".xlsx"} columns={this.props.module.export_columns.filter(item => {
                    if(item.hideCondition && item.hideCondition()){
                      return false
                    }
                    return true
                  })}
                    data={this.excelData(this.props.info[this.props.module.name]).filter(item => {
                      return this.filterData(item, "")
                    }).map((item, index) => {
                      return this.parseDataExcel(item, index)
                    })}  summary={this.props.module.export_columns_summary}  />
                </div>}
                {this.props.module.import_button &&
                <div style={{marginLeft:5, marginRight:5}}>
                  <KTButton size="small" onClick={() => {this.props.history.push(this.props.match.path+"/import")}}
                    style={{fontSize:"0.7rem", textTransform:"none", backgroundColor:settings.theme.primary.main}} >
                    Import
                  </KTButton>
                </div>}
                {this.props.module.refresh_button &&
                <div style={{marginLeft:5, marginRight:5}}>
                  <KTButton size="small" onClick={() => {this.loadData(this.props.info.filters[this.props.module.name])}}
                    style={{fontSize:"0.7rem", textTransform:"none", backgroundColor:settings.theme.primary.main}}
                    loading={this.props.info.action[this.props.module.name+"_operation"]}  >
                    <span><KTIcon vicon={"reload"} vicontype={"MaterialCommunityIcons"} size={"0.7rem"} color="white" />&nbsp;</span>
                    Reload {(this.props.module.refresh_interval ? "(AUTO "+MagbizGeneral.toMoney(this.props.module.refresh_interval/(60*1000),0)+"min)" : "")}
                  </KTButton>
                </div>}
              </div>
            </Toolbar>
          </AppBar>
          {showSideBar &&
          <div style={{width:drawerWidth-1, height:docContentHeight,
                maxHeight: docContentHeight, overflow: 'auto',
                float:"left", backgroundColor:"white", outline:"1px solid #f2f2f2"
            }} >
            <List component="nav"
              subheader={<ListSubheader component="div" classes={{root: classes.subText}}>{this.props.module.menu.title}</ListSubheader>}>
              {this.props.module.menu.list.map((item, index) => {

                let item_count = 0
                if(this.props.info[this.props.module.name]){
                  item_count = this.props.info[this.props.module.name].reduce((count, obj) => {
                    if(obj[item.name]+"" === item.key+"" || item.key+"" === "All") count += 1
                    return count
                  }, 0)
                }

                if(this.props.module.menuHideEmpty && item_count === 0){return null}

                return (
                  <ListItem button key={index} onClick={(e) => this._onMenuFilterChange(e, item)} style={{backgroundColor:(this.state.menu_filter && this.state.menu_filter.key === item.key ? "#EBF4F6" : "transparent")}} >
                    {item.icon && <ListItemIcon style={{color:item.color, marginRight:0}} ><MaterialIcon type={item.icon}  large /></ListItemIcon>}
                    <ListItemText primary={item.title+(item_count > 0 ? " ("+item_count+")" : "")} classes={{primary:classes.menuItemPrimaryText}} style={{fontSize:10}}/>
                  </ListItem>
                )
              })}
            </List>
            <Divider />
          </div>}
          <div className={classNames(classes.content, {
              [classes.contentShift]: showSideBar,
            })}>

            {this.props.section &&
            <UISectionList
              width={docContentWith}
              height={docContentHeight} />}

              {!this.props.section && !this.props.module.infinite &&
              <TableList
                onFilterChange={this._onFilterChange}
                onRowClick={this._onRowClick}
                columns={columns_display}
                enableSort={this.props.module.enableSort}
                onSelectAll={this._onSelectAll}
                filter={this.props.module.filter}
                rowHeight={this.props.module.rowHeight}
                disableRowClick={this.props.module.disableRowClick}
                rows={this.state.dataRows}
                loading={this.props.info.action[this.props.module.name]}
                width={docContentWith}
                height={docListHeight} />}

              {!this.props.section && this.props.module.infinite &&
              <TableInfiniteList
                runnumber={false}
                column={columns_display}
                rows={this.state.dataRows}
                width={docContentWith}
                height={docListHeight}
                filter={this.props.module.filter}
                loading={this.props.info.action[this.props.module.name]}
                onFilterChange={this._onFilterChange}
                onRowClick={this._onRowClick} />}

            {this.props.module.summaryPanel && this.state.dataRows &&
            <Grid container style={{width:docContentWith, height:30, padding:4, fontSize:13, backgroundColor:"#B9BCBF"}} >
              {this.props.module.summaryPanel.map((sitem, index) => {
                const value = this.state.dataRows.reduce((sum, theitem) => {
                  let value = 0
                  if(sitem.count){
                    value = 1
                  }else if(sitem.value){
                    value = (theitem[sitem.value] ? MagbizGeneral.numberValue(theitem[sitem.value]) : 0)
                  }else if(sitem.values){
                    value = sitem.values.reduce((stim, v) => (stim + (theitem[v] ? MagbizGeneral.numberValue(theitem[v]) : 0)),0)
                  }
                  return sum + value
                },0)
                return (
                  <Grid item xs={sitem.xs} style={{textAlign:"center"}}>
                    <span style={{fontWeight:600}}>{sitem.label}</span>
                    <span style={{fontWeight:500}}>{" "+(this.state.dataRows ? MagbizGeneral.toMoney(value,(sitem.count ? 0 : 2))  : "-")+" "}</span>
                    <span style={{fontWeight:600}}>{sitem.postlabel}</span>
                  </Grid>
                )
              })}
            </Grid>}

            {Boolean(this.props.module.permission.add) && this.props.module.permission.allowCondition("add",{}, "view") &&
            <Fab color="primary" aria-label="Add" size="large" style={{position:"fixed", bottom:20, left: docWidth - 20 - 56}} onClick={this._onAddButtonClick} >
                <AddIcon />
            </Fab>}
          </div>

        </Fragment>}

      </Fragment>
    );

  }

}

export default withRouter(connect(({app, info}) => {
	return {app, info};
}, {
  actionPerformInfo,
  actionPerformApp
})(injectIntl(withStyles(styles, { withTheme: true })(TableListView))));
