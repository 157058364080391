import React from "react"
import { connect} from 'react-redux';
//import {actionPerform as actionPerformApp} from '../../redux/App'

//import {MagbizGeneral} from '../../utils/Magbiz'
import GMap from '../../utils/GMap'

class GoogleMapViewer extends React.Component {

  componentDidMount(){
    const me = this
    GMap.loadMap(this.props.mapContainerClass)
    GMap.setCallback("mapLoad", function(location){
      me.drawAll(me.props.data)
    })
    GMap.setCallback("mapClick", function(location){

    })
    GMap.setCallback("mapRightClick", function(location){

    })
  }

  componentWillUnmount() {
    GMap.clearMapLoading(this.props.mapContainerClass)
  }

  shouldComponentUpdate(nextProps, nextState){
    const map = GMap.getMap(this.props.mapContainerClass)
    if(this.props.width !== nextProps.width || this.props.height !== nextProps.height){
      GMap.resize(map)
      return false
    }
    if(this.props.data !== nextProps.data){
      if(!this.props.data){
        this.drawAll(nextProps.data)
      }else if(nextProps.data && this.props.data.fields !== nextProps.data.fields){
        this.drawFields(nextProps.data.fields, nextProps.data.fields_option)
      }
    }
    if(this.props.focus !== nextProps.focus){
      if(nextProps.focus){
        setTimeout(function(){
          GMap.mapSetBound(map, nextProps.focus)
        }, 100)
      }
    }
    return false
  }

  drawAll = (data) => {
    if(data){
      this.drawFields(data.fields, data.fields_option)
    }
  }

  drawFields = (fields, fields_option) => {
    if(fields){
      GMap.drawPolygons(GMap.getMap(this.props.mapContainerClass), fields, {group:"fields",...fields_option})
    }
  }

  render() {
    //console.log("Render GoogleMapViewer ", this.props.mapContainerClass)
    return (
      <div className={this.props.mapContainerClass} style={{ height: "100%", width: "100%" }}>
      </div>
    )
  }
}

export default connect(({app}) => {
	return {app};
}, {
  //actionPerformApp
})(GoogleMapViewer)
