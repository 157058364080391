import React from 'react';
import {Grid,Typography ,Paper} from '@material-ui/core';
import Measure from 'react-measure';

import {KTInputField,KTSelectField,KTDateField,KTTimeField,KTInputAutoCompleteField,KTRadioField} from '../../KT';
import {MagbizGeneral} from '../../utils/Magbiz';

const containerWidth = 600

class DocTableContent extends React.Component {

  state = {
    footerHeight:0
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props !== nextProps){
      return true
    }
    return false
  }

  _onFieldChange = (event) => {
    this.props.onFieldChange(event)
  }

  _onTableRowClick = (item, index) => {
    this.props.onTableRowClick(item, index)
  }

  _onDateTimeChange = (dateObj) => {
    this.props.onDateTimeChange(dateObj)
  }

  _onFieldSelect = (name, selectId) => {
    this.props.onFieldSelect(name, selectId)
  }

  _onResize = (contentRect) => {
    //console.log(contentRect.bounds.height,this.state.footerHeight)
  };

  render(){
    //console.log("Render DocTableContent")
    const {groupObj, table_fields, fields, table_summary, mode, width, height, paging, pageRange, isLastPage} = this.props
    const {columns, footColumns, tableName, tableRow, tableRowHeight, tableRowBottomLine, footRowHeight} = groupObj

    if(groupObj.hideCondition && groupObj.hideCondition(fields, mode)) return null

    let paperStyle = {marginLeft:10,marginRight:10,marginBottom:20,boxShadow:"none"}
    const containerStyle = {}


    if(groupObj.fieldStyle === "document"){
        paperStyle = {...paperStyle, marginLeft:3, marginRight:3, marginBottom:10, }
    }

    if(typeof groupObj.marginTop !== "undefined"){paperStyle.marginTop = groupObj.marginTop;}
    if(typeof groupObj.marginBottom !== "undefined"){paperStyle.marginBottom = groupObj.marginBottom;}

    let fieldRows = []

    let promotionRows = []
    let promotion_rows = (table_summary && table_summary.promotions ? table_summary.promotions.length : 0)
    let thisPageRow = 0

    if(pageRange){
      for(let i=0;i<table_fields[tableName].length;++i){
        if(i >= pageRange.start && i < pageRange.end){
          fieldRows.push({...table_fields[tableName][i], dataIndex:i})
          thisPageRow += 1
          //console.log("F",i)
        }
      }
      if(promotion_rows > 0){
        if(fieldRows.length < tableRow){
          let theindex = table_fields[tableName].length - 1
          for(let i=0;i<table_summary.promotions.length;++i){
            theindex = table_fields[tableName].length + i
            if(theindex >= pageRange.start && theindex < pageRange.end){
              promotionRows.push({...table_summary.promotions[i], dataIndex:theindex})
              thisPageRow += 1
              //console.log("P",theindex)
            }
          }
          if(thisPageRow < tableRow){
            for(let i=thisPageRow;i<tableRow;++i){
              theindex += 1
              promotionRows = [...promotionRows, {dataIndex:theindex}]
              //console.log("B",theindex)
            }
          }
        }
      }else{
        for(let i=fieldRows.length;i<tableRow;++i){
          fieldRows = [...fieldRows, {dataIndex:i}]
        }
      }
    }else{
      fieldRows = table_fields[tableName]
      let writeRow = tableRow
      if(paging){
        if(mode !== "view" && fieldRows.length >= tableRow){
          writeRow = fieldRows.length + 1
        }else if(mode === "view" && promotion_rows > 0){
          writeRow = tableRow - promotion_rows
        }
      }
      for(let i=fieldRows.length;i<writeRow;++i){
        //console.log("F",i)
        fieldRows = [...fieldRows, {dataIndex:i}]
      }
      if(promotion_rows > 0){
        for(let i=0;i<table_summary.promotions.length;++i){
          promotionRows.push({...table_summary.promotions[i], dataIndex:writeRow+i})
          //console.log("P",writeRow+i)
        }
      }
    }

    const createMarkup = (text) => {
      return {__html: text};
    }

    return (
      <Grid item xs={(!Boolean(groupObj.span) || width < containerWidth ? 12 : groupObj.span )}  >
        <Paper style={paperStyle} >
          <Typography variant="subtitle1" style={{backgroundColor:"#f2f2f2", borderBottom:"1px solid #fafafa", fontSize:"0.8rem", fontWeight:500}}>
            {groupObj.title && <div style={{paddingTop:5, paddingBottom:5, paddingLeft:10, paddingRight:10, fontWeight:"bold"}}>{groupObj.title}</div>}
          </Typography>
          <Grid container style={containerStyle}>

            <Measure bounds onResize={this._onResize} >
              {({ measureRef }) => <div ref={measureRef} className="datatableWrapper" style={{width:"100%", height:(height ? height : "auto")}}>

                <table className="datatable" >
                  <thead>
                    <tr>
                    {columns && columns.map((item, index) => {
                      if(item.type === "hidden" || item.hidden){
                        return (<th key={index} style={{display:"none"}} >{item.label}</th>)
                      }else{
                        return (<th key={index} style={{width:item.width+"%", padding:5, textAlign:(item.align === "right" ? "right" : "center")}} >{item.label}</th>)
                      }
                    })}
                    </tr>
                  </thead>
                  <tbody>
                    {fieldRows && fieldRows.length===0 && !isLastPage &&
                      <tr>
                        <td colSpan={columns.length} style={{fontSize:"0.8rem", textAlign:"center", color:"#bdbdbd"}}>No Data</td>
                      </tr>
                    }
                    {fieldRows && fieldRows.length>0 && fieldRows.map((fields, rowIndex) => {
                      const dataIndex = (fields && fields.dataIndex ? fields.dataIndex : rowIndex)
                      return (
                        <tr key={dataIndex} onClick={() => {if(groupObj.enableRowClick){ this._onTableRowClick(fields, dataIndex) }}}  >
                          {columns && columns.map((item, colIndex) => {
                            const field_name = tableName+"_"+item.name+"["+dataIndex+"]"

                            let value = item.value
                            if(fields && fields[field_name]){
                              value = fields[field_name].value
                            }

                            if(item.formatValue) value = item.formatValue(value)
                            if(item.formatCustom) value = item.formatCustom(fields, dataIndex)
                            if(typeof value === "undefined") value = ""

                            if(item.type === "hidden" || item.hidden){
                              return (
                                <td key={colIndex} style={{display:"none"}}>
                                  <input type="hidden" name={field_name}  value={value} />
                                </td>
                              )
                            }else if(item.hiddenIfNotFirstRow && rowIndex !== 0){
                              return (
                                <td key={colIndex}>
                                  <input type="hidden" name={field_name}  value={value} style={{display:"none"}}/>
                                </td>
                              )
                            }

                            let tdStyle = {textAlign:(item.align ? item.align : "left"), verticalAlign:"top"}
                            if(rowIndex !== fieldRows.length - 1){
                              tdStyle.borderBottom = "none"
                            }
                            if(tableRowHeight){
                              tdStyle.height = tableRowHeight
                            }
                            if(tableRowBottomLine){
                              tdStyle.borderBottom = tableRowBottomLine
                            }

                            let label = item.ltext
                            if(item.hideLabelLastRow && rowIndex === fieldRows.length - 1){
                              label = ""
                            }

                            return (
                              <td key={colIndex} style={tdStyle} >

                                {(item.type === "int" || item.type.startsWith("decimal") || item.type === "text" || item.type === "password")  &&
                                <KTInputField mytheme={this.props.mytheme} name={field_name} type={item.type}  defaultValue={value} placeholder={item.placeholder} multiline={item.multiline} color={item.colorCondition ? item.colorCondition(fields, mode, dataIndex) : ""}
                                  fieldOnTable disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))} align={item.align} pointer={groupObj.enableRowClick}
                                  onChange={(e) => this._onFieldChange(e)} />}

                                {item.type === "select" &&
                                <KTSelectField mytheme={this.props.mytheme} name={field_name} placeholder={item.placeholder}
                                  fieldOnTable icon={item.icon} defaultValue={value} blankNotAllowed={!Boolean(item.allowBlank)} async={Boolean(item.async)} isMulti={Boolean(item.isMulti)} color={item.colorCondition ? item.colorCondition(fields, mode, dataIndex) : ""}
                                  suggestions={item.list} disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))} align={item.align}
                                  onChange={(e) => this._onFieldChange(e)} small no_outline  />}

                                {item.type === "autocomplete" &&
                                <KTInputAutoCompleteField mytheme={this.props.mytheme} name={field_name} placeholder={item.placeholder}
                                  fieldOnTable defaultValue={value} suggestions={item.list}
                                  disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))}
                                  onChange={(e) => this._onFieldChange(e)} onSelect={(name, selectId) => this._onFieldSelect(name, selectId)} small no_outline />}

                                {item.type === "date" &&
                                <KTDateField mytheme={this.props.mytheme} type="date" name={field_name} placeholder={item.placeholder}
                                  fieldOnTable icon="calendar" small defaultValue={value} center={(item.align === "center" ? true : false)}
                                  disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))}
                                  onChange={(e) => this._onDateTimeChange(e)} />}

                                {item.type === "time" &&
                                <KTTimeField mytheme={this.props.mytheme} type="time" name={field_name} placeholder={item.placeholder}
                                  fieldOnTable icon="calendar" small defaultValue={value} center={(item.align === "center" ? true : false)}
                                  disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))}
                                  onChange={(e) => this._onDateTimeChange(e)} />}

                                {item.type === "radio" &&
                                <KTRadioField name={field_name} list={item.list}
                                  fieldOnTable small defaultValue={value} hideIfValue={item.hideIfValue}
                                  disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))}
                                  onChange={(e) => this._onFieldChange(e)} />}

                                {item.type === "html" &&
                                <div style={item.style} dangerouslySetInnerHTML={createMarkup(value)}/>}

                                {item.type === "htmllabel" &&
                                <div style={item.style} dangerouslySetInnerHTML={createMarkup(label)}/>}

                              </td>
                            )

                          })}
                        </tr>
                      )
                    })}
                    {promotionRows.length > 0 && promotionRows.map((rowItem, rowIndex) => {
                      const dataIndex = (rowItem && rowItem.dataIndex ? rowItem.dataIndex : rowIndex)
                      return (
                        <tr key={dataIndex} >
                          {columns && columns.map((item, colIndex) => {
                            const field_name = "promotions_"+item.name.replace("item_","")+"["+rowIndex+"]"
                            let value = item.value
                            if(rowItem[item.name.replace("item_","")]){
                              value = rowItem[item.name.replace("item_","")]
                            }
                            if(typeof value === "undefined") value = ""

                            if(item.type === "hidden" || item.hidden){
                              return (
                                <td key={colIndex} style={{display:"none"}}>
                                  <input type="hidden" name={field_name}  value={value} />
                                </td>
                              )
                            }

                            let tdStyle = {textAlign:(item.align ? item.align : "left"), verticalAlign:"top"}
                            if(rowIndex !== promotionRows.length - 1){
                              tdStyle.borderBottom = "none"
                            }
                            if(tableRowHeight){
                              tdStyle.height = tableRowHeight
                            }
                            if(tableRowBottomLine){
                              tdStyle.borderBottom = tableRowBottomLine
                            }

                            return (
                              <td key={colIndex} style={tdStyle} >
                                <div style={{...{fontSize:11, color:(rowItem && rowItem.hold === "H" ? "red" : "#34B2F8"), paddingLeft:5, paddingRight:5, lineHeight:2, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}, ...item.style}} dangerouslySetInnerHTML={createMarkup(value)}/>
                              </td>
                            )

                          })}
                        </tr>
                      )
                    })}
                  </tbody>

                  {groupObj.fields && (!pageRange || pageRange.isLast) &&
                    <tfoot>
                    {groupObj.fields && Object.values(groupObj.fields).map((item, index) => {
                      let xs = (item.xs ? item.xs : 6)
                      let labelMinHeight = (footRowHeight ? footRowHeight : 24)
                      let labelFontWeight = 600

                      let tdStyle = {fontSize:12, textAlign:(item.align ? item.align : "left"), verticalAlign:"middle", paddingTop:(index === 0 ? 5 : 0 )}
                      if(index !== Object.keys(groupObj.fields).length - 1){
                        tdStyle.borderBottom = "none"
                      }
                      if(footRowHeight){
                        tdStyle.height = footRowHeight
                      }else if(tableRowHeight){
                        tdStyle.height = tableRowHeight
                      }

                      let value = item.value
                      if(fields){
                        if(item.nameValue && fields[item.nameValue]){
                          value = fields[item.nameValue].value
                        }else if(fields[item.name]){
                          value = fields[item.name].value
                        }
                      }

                      if(item.type === "hidden" || item.hidden){
                        return (
                          <tr key={index} style={{display:"none"}}>
                            <td style={{border:"none"}} colSpan={item.blankspan} ></td>
                            <td style={tdStyle} colSpan={item.colspan}>
                              <input type="hidden" name={item.name}  value={value} />
                            </td>
                          </tr>
                        )
                      }

                      return (
                        <tr key={index} >
                          <td style={{border:"none"}} colSpan={item.blankspan} ></td>
                          <td style={tdStyle} colSpan={item.colspan}>
                            <Grid container direction="row">
                              <Grid item xs={12 - xs} style={{minHeight:labelMinHeight}}>
                                <Typography variant="caption" style={{marginTop:1, fontWeight:labelFontWeight, fontSize:12, marginLeft:5}}>
                                  &nbsp;{item.label}
                                </Typography>
                              </Grid>
                              <Grid item xs={xs - (item.plxs ? item.plxs : 0)} style={{minHeight:(typeof item.minHeight !== "undefined" ? item.minHeight : labelMinHeight ), paddingRight:5, fontWeight:500}}>
                                {(item.type === "int" || item.type.startsWith("decimal") || item.type === "text" || item.type === "password")  &&
                                <KTInputField mytheme={this.props.mytheme} name={item.name} type={item.type}  defaultValue={value} placeholder={item.placeholder} multiline={item.multiline}
                                  fieldOnTable disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))}
                                  onChange={this._onFieldChange} align="right" />}
                              </Grid>
                              {item.postlabel && item.plxs &&
                              <Grid item xs={item.plxs} style={{minHeight:(typeof item.minHeight !== "undefined" ? item.minHeight : labelMinHeight ), textAlign:(item.postlabelPR ? item.postlabelPR : "right"), paddingRight:(item.postlabelPR ? item.postlabelPR : 0 )}}>
                                <Typography variant="caption" style={{marginTop:1, fontWeight:labelFontWeight, fontSize:12, marginRight:5}}>
                                  {item.postlabel}
                                </Typography>
                              </Grid>}
                            </Grid>
                          </td>
                        </tr>
                      )
                    })}
                    </tfoot>
                  }
                </table>

              </div>}
            </Measure>



            {footColumns &&
            <table className="datatable" >
              <tfoot>
                <tr>
                {footColumns.map((item, index) => {
                  let footStyle = {width:item.width+"%", textAlign:item.align, fontSize:"0.7rem", fontWeight:600, padding:"0px 5px"}
                  let value = ""
                  if(item.role === "blank"){
                    footStyle.border = "none"
                  }else if(item.role === "caption"){
                    value = item.text
                  }else if(item.role === "sum"){
                    let decimal = 0
                    if(item.type.startsWith("decimal")){
                      decimal = parseInt(item.type.split(",")[1])
                    }
                    if(table_summary[item.name]){
                      if(isNaN(table_summary[item.name])){
                        value = ""
                      }else{
                        value = MagbizGeneral.toMoney(table_summary[item.name],decimal)
                      }
                    }else if(this.props.fields[item.name_mapping]){
                      let fvalue = parseFloat(this.props.fields[item.name_mapping].value)
                      if(isNaN(fvalue)){
                        value = ""
                      }else if(fvalue !== 0){
                        value = MagbizGeneral.toMoney(fvalue,decimal)
                      }
                    }
                  }
                  return (
                    <td key={index} style={footStyle} >
                      {value}
                    </td>
                  )
                })}
                </tr>
              </tfoot>
            </table>}

          </Grid>
        </Paper>
      </Grid>
    )
  }
}

export default DocTableContent
