import React from "react";
import Chart from 'react-google-charts';
import { injectIntl } from 'react-intl';


import {MagbizDateTime, MagbizGeneral} from  '../../utils/Magbiz';
//import {white} from '@material-ui/core/colors';

class KTWChart extends React.Component {

  render(){
    const tlang = this.props.intl.formatMessage;
    if(!this.props.data) return null

    const left = (this.props.left ? this.props.left : 20)
    const right = (this.props.right ? this.props.right : 20)
    const top = (this.props.top ? this.props.top : 40)
    const bottom = (this.props.bottom ? this.props.bottom : 20)

    if(this.props.chartType === "Pie"){
      return (
          <Chart
            width={'100%'}
            height={this.props.height}
            chartType="PieChart"
            loader={<div></div>}
            data={[...[['Key', 'Value']], ...Object.keys(this.props.data).map((k) => [tlang({id:this.props.module_name+"."+this.props.chartid+"_"+k}),this.props.data[k]])]}
            options={{
              title: tlang({id:this.props.module_name+"."+this.props.chartid}),
              //titleTextStyle: {color: 'white'},
              backgroundColor: "transparent",
              legend: {
                position:(this.props.legendPosition ? this.props.legendPosition : "default"), //textStyle: {color: 'white'}
                alignment: (this.props.legendAlign ? this.props.legendAlign : "automatic"), //start, center, end
                textStyle: {fontSize: (this.props.legendSize ? this.props.legendSize : 9)},
              },
              pieHole: (this.props.pieHole ? this.props.pieHole : 0),
        			pieSliceText:'value',
              chartArea: {left:left, right:right, top:top, bottom:bottom},
            }}
            rootProps={{ 'chart-id': this.props.chartid }}
          />
      );
    }else if(this.props.chartType === "SteppedArea"){ //Data Structure = map[string][]map[string]interface{}
      if(Object.keys(this.props.data).length  === 0) return null

      const fistItem = this.props.data[Object.keys(this.props.data)[0]]
      const hos = Object.values(fistItem).map((obj) => {
        return obj
      })
      const header = MagbizGeneral.sortByKey(hos, "name").reduce((arr, item) => {
        arr.push(item.name)
        return arr
      },["Time"])
      const ldata = Object.keys(this.props.data).map((t) => {
        const ros = Object.values(this.props.data[t]).map((obj) => {
          return obj
        })
        const rowdata = MagbizGeneral.sortByKey(ros, "name").reduce((arr, item) => {
          arr.push(item.level)
          return arr
        },[MagbizDateTime.dateObjFromString(t)])
        return rowdata
      })
      const data = [...[header],...ldata]

      const left = (this.props.left ? this.props.left : 20)
      const right = (this.props.right ? this.props.right : 20)
      const top = (this.props.top ? this.props.top : 40)
      const bottom = (this.props.bottom ? this.props.bottom : 20)

      return (
          <Chart
            width={'100%'}
            height={this.props.height}
            chartType="SteppedAreaChart"
            loader={<div></div>}
            data={data}
            options={{
              title: tlang({id:this.props.module_name+"."+this.props.chartid}),
              chartArea: {left:left, right:right, top:top, bottom:bottom},
              legend:{
                position: (this.props.legendPosition ? this.props.legendPosition : "default"),
                alignment: (this.props.legendAlign ? this.props.legendAlign : "automatic"), //start, center, end
                textStyle: {fontSize: (this.props.legendSize ? this.props.legendSize : 9)},
              },
              vAxis: { textStyle: {fontSize: 9, color:'grey'} },
              hAxis: {
                textStyle: {fontSize: 9, color:'grey'},
                gridlines: {
                  count: -1,
                  units: {
                    days: {format: ['MMM d']},
                  }
                },
              },
              //isStacked:true,
            }}
            rootProps={{ 'chart-id': this.props.chartid }}
          />
      );
    }else if(this.props.chartType === "Bar"){ //Data Structure = map[string]map[string]interface{}
      if(Object.keys(this.props.data).length === 0) return null

      const fistItem = this.props.data[Object.keys(this.props.data)[0]]
      const hk = Object.keys(fistItem).map((k) => {
        return k
      })
      const header = hk.sort().reduce((arr, k) => {
        if(k !== "name"){
          arr.push(tlang({id:this.props.module_name+"."+this.props.chartid+"_"+k}))
        }
        return arr
      },["Group"])

      const ldata = Object.keys(this.props.data).map((id) => {
        const item = this.props.data[id]
        const rk = Object.keys(item).map((k) => {
          return k
        })
        const rowdata = rk.sort().reduce((arr, k) => {
          if(k !== "name"){
            arr.push(item[k])
          }
          return arr
        },[item.name])
        return rowdata
      })
      const data = [...[header],...ldata]

      const left = (this.props.left ? this.props.left : 20)
      const right = (this.props.right ? this.props.right : 20)
      const top = (this.props.top ? this.props.top : 40)
      const bottom = (this.props.bottom ? this.props.bottom : 20)

      return (
          <Chart
            width={'100%'}
            height={this.props.height}
            chartType={(this.props.horizontal ? "BarChart" : "Bar")}
            loader={<div></div>}
            data={data}
            options={{
              title: tlang({id:this.props.module_name+"."+this.props.chartid}),
              chartArea: {left:left, right:right, top:top, bottom:bottom},
              legend:{
                position: (this.props.legendPosition ? this.props.legendPosition : "default"),
                alignment: (this.props.legendAlign ? this.props.legendAlign : "automatic"), //start, center, end
                textStyle: {fontSize: (this.props.legendSize ? this.props.legendSize : 9)},
              },
              //bars: (this.props.horizontal ? "horizontal" : "vertical" ),
              vAxis: { textStyle: {fontSize: 9, color:'grey'} },
              hAxis: {
                textStyle: {fontSize: 9, color:'grey'},
                minValue : 0,
                maxValue: (this.props.hMax ? this.props.hMax : "automatic")
              },
            }}
            rootProps={{ 'chart-id': this.props.chartid }}
          />
      );
    }else{
      return null
    }


  }

}

export default injectIntl(KTWChart);
