import React from "react";
import Modal from 'react-modal'
//import {useSelector} from 'react-redux';
//import {Grid, CircularProgress} from '@material-ui/core';

//import {TableInfLoaderList, GoogleMapViewerV2, DocDefaultContent, KTSelectField} from '../../KT'
//import {MagbizGeneral, MagbizUI} from '../../utils/Magbiz'
//import {useInfoOperation, useInfo, useApp, useLocalStorage} from '../../utils/MagbizHook'
//import {translate} from '../../lang'
//import settings from '../../settings'

Modal.setAppElement('#root')
//Modal.setAppElement(document.getElementById('root'))

//Base properties => isOpen, onClose
export default function KTWModal(props){

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  const onLoad = () => {
    if(props.onLoad){
      props.onLoad()
    }
  }

  const onRequestClose = () => {
    if(props.onRequestClose){
      props.onRequestClose()
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onAfterOpen={onLoad}
      onRequestClose={onRequestClose}
      style={customStyles}>
      {props.children &&
      React.Children.map(props.children, child => {
        return (
          React.cloneElement(child, {})
        )
      })}
    </Modal>
  )

}
