import React,{Fragment} from 'react';
import {InfiniteLoader, List} from 'react-virtualized';
import { withStyles } from '@material-ui/core/styles';

//import settings from '../../settings'

const styles = theme => {
  return {
    List: {
      width: "100%",
      border: "1px solid #DDD"
    },
    header: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: "#f2f2f2",
      borderBottom: "1px solid #888",
      height:30,
      fontSize: 10,
      fontWeight: "bold"
    },
    cellHeader:{
      display:"inline-block",
      padding:"0 5px"
    },
    rowInvalid: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color:"red",
      borderBottom: "1px solid #e0e0e0",
      fontSize: 10,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: "#fff",
      borderBottom: "1px solid #e0e0e0",
      fontSize: 10,
    },
    cellRow:{
      display:"inline-block",
      padding:"0 5px",
    },
    cellLoading: {
      display: "inline-block",
      height: "1em",
      backgroundColor: "#DDD",
      borderLeft:"3px solid white",
      borderRight:"3px solid white"
    }
  }
};

const STATUS_LOADING = 1;
const STATUS_LOADED = 2;

class TableInfiniteList extends React.PureComponent {

  state = {
    loadedRowCount: 0,
    loadedRowsMap: {},
    loadingRowCount: 0,
  }

  _timeoutIdMap = {}
  _column = []

  componentWillUnmount() {
    Object.keys(this._timeoutIdMap).forEach(timeoutId => {
      clearTimeout(timeoutId);
    });
  }

  render() {
    //const {loadedRowCount, loadingRowCount} = this.state;

    if(!this.props.rows) return null

    if(this.props.column){
      this._column = this.props.column
    }else if(this.props.rows.length > 0){
      this._column = Object.keys(this.props.rows[0]).reduce((object, value) => {
        object.push({name:value, label:value, width:Math.floor(100/Object.keys(this.props.rows[0]).length)})
        return object
      }, [])
    }

    return (
      <Fragment>
        <div className={this.props.classes.header} >
          {this._column && this._column.length > 0 && this._column.map((col, colIndex) => {
            return (
              <div key={colIndex} className={this.props.classes.cellHeader} style={{width: col.width+"%"}}>
                {col.label}
              </div>
            )
          })}
        </div>
        <div>
          <InfiniteLoader
            isRowLoaded={this._isRowLoaded}
            loadMoreRows={this._loadMoreRows}
            rowCount={this.props.rows.length}>
            {({onRowsRendered, registerChild}) => (
              <List
                ref={registerChild}
                className={this.props.classes.List}
                height={this.props.height - 30}
                onRowsRendered={onRowsRendered}
                rowCount={this.props.rows.length}
                rowHeight={30}
                rowRenderer={this._rowRenderer}
                width={this.props.width}
              />
            )}
          </InfiniteLoader>
        </div>
        </Fragment>
    );
  }

  _rowRenderer = ({index, key, style}) => {
    const {loadedRowsMap} = this.state;
    const row = this.props.rows[index];
    let content;

    if (loadedRowsMap[index] === STATUS_LOADED) {
      content = this._column.map((col, colIndex) => {
        return (
          <div key={colIndex} className={this.props.classes.cellRow} style={{width: col.width+"%"}}>
            {(col.name === "runnumber" ? (index+1) : row[col.name])}
          </div>
        )
      })
    } else {
      content = this._column.map((col, colIndex) => {
        return (
          <div key={colIndex} className={this.props.classes.cellLoading} style={{width: col.width+"%"}}/>
        )
      })
    }

    return (
      <div className={(row["INVALID"] === "Y" ? this.props.classes.rowInvalid : this.props.classes.row)} key={key} style={style}>
        {content}
      </div>
    );
  }

  _clearData = () => {
    this.setState({
      loadedRowCount: 0,
      loadedRowsMap: {},
      loadingRowCount: 0,
    });
  }

  _isRowLoaded = ({index}) => {
    const {loadedRowsMap} = this.state;
    return !!loadedRowsMap[index]; // STATUS_LOADING or STATUS_LOADED
  }

  _loadMoreRows = ({startIndex, stopIndex}) => {
    const {loadedRowsMap, loadingRowCount} = this.state;
    const increment = stopIndex - startIndex + 1;

    for (var i = startIndex; i <= stopIndex; i++) {
      loadedRowsMap[i] = STATUS_LOADING;
    }

    this.setState({
      loadingRowCount: loadingRowCount + increment,
    });

    const timeoutId = setTimeout(() => {
      const {loadedRowCount, loadingRowCount} = this.state;

      delete this._timeoutIdMap[timeoutId];

      for (var i = startIndex; i <= stopIndex; i++) {
        loadedRowsMap[i] = STATUS_LOADED;
      }

      this.setState({
        loadingRowCount: loadingRowCount - increment,
        loadedRowCount: loadedRowCount + increment,
      });

      promiseResolver();
    }, 1000 ); //+ Math.round(Math.random() * 2000)

    this._timeoutIdMap[timeoutId] = true;

    let promiseResolver;

    return new Promise(resolve => {
      promiseResolver = resolve;
    });
  }

}

export default (withStyles(styles, { withTheme: true })(TableInfiniteList))
