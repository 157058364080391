import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from 'classnames';
import Autocomplete from "react-autocomplete";

import {MagbizGeneral} from '../../utils/Magbiz'
import settings from '../../settings'

const styles = theme => ({
  nakedInput: {
    fontSize: "0.7rem",
    fontFamily:"inherit",
    color: "rgba(0,0,0,0.87)",
    lineHeight: "20px",
    minHeight: 20,
    padding: "0px 5px",
    width: "100%",
    backgroundColor:"#fafafa",
    border:"none",
    outline: "none",
    borderBottom: "1px solid #f2f2f2",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 2,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 2px rgba(0,123,255,.25)"
    },
    "&:disabled": {
      color: "black",
      backgroundColor:"transparent",
    },
  },
  nakedInputFieldOnTable: {
    borderBottom: "1px solid #fff",
  }
});

//let delCount = 0

class KTInputAutoCompleteField extends React.Component {

  state = {
    value: this.props.defaultValue
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props !== nextProps){
      if(nextProps.defaultValue !== this.state.value){
        this.setState({value: nextProps.defaultValue})
        return false
      }
      return true
    }
    if(this.state !== nextState){return true}
    return false
  }

  _onChange = (event) => {
    /*if(event.target.value.length < this.state.value.length){
      if(delCount >= 2){
        delCount = 0
        event.target.value = ""
        this.setState({value: event.target.value})
        if(this.props.onChange){
          this.props.onChange(event)
        }
        return
      }else{
        delCount += 1
      }
    }else{
      delCount = 0
    }*/
    if(this.props.onChange){
      this.props.onChange(event)
    }
    this.setState({value: event.target.value})
  }

  _onBlur = (event) => {
    if(this.props.onChange){
      this.props.onChange(event)
    }
  }

  _onSelect = (value, item) => {
    //delCount = 0
    this.setState({value: value})
    if(this.props.onSelect){
      this.props.onSelect(this.props.name, item.value)
    }
  }

  render(){

    let className = classNames(this.props.classes.nakedInput)
    let inputPropsStyle = {};
    if (this.props.underlineColor) {
      inputPropsStyle.borderBottom = "1px solid " + this.props.underlineColor;
    }
    if (this.props.fontSize) {
      inputPropsStyle.fontSize = this.props.fontSize;
    }
    if (this.props.lineHeight) {
      inputPropsStyle.lineHeight = this.props.lineHeight;
    }
    if (this.props.padding) {
      inputPropsStyle.padding = this.props.padding;
    }

    if(this.props.mytheme){
      if(!this.props.disabled){
        inputPropsStyle.backgroundColor = settings.theme.mytheme.input;
      }
    }else{
      if(this.props.required && MagbizGeneral.isEmpty(this.state.value)){
        inputPropsStyle.backgroundColor = "rgb(255, 230, 230)";
      }
    }

    if (this.props.center) {
      inputPropsStyle.textAlign = "center"
    }else if(this.props.align){
      inputPropsStyle.textAlign = this.props.align
    }
    if(this.props.fieldOnTable){
      className = classNames(this.props.classes.nakedInput, this.props.classes.nakedInputFieldOnTable)
    }

    return (
        <Autocomplete
          wrapperStyle={{width: this.props.width ? this.props.width : "100%", verticalAlign:"top"}}
          menuStyle={{
            borderRadius: '3px',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
            background: 'rgba(255, 255, 255, 1)',
            overflow: 'auto',
            position:"fixed",
            maxHeight: '50%',
            zIndex:900
          }}
          inputProps={{
            name:this.props.name,
            placeholder:this.props.placeholder,
            disabled:this.props.disabled,
            style:{...inputPropsStyle},
            className:className,
            //onBlur:(event => this._onBlur(event))
          }}
          getItemValue={(item) => item.label}
          items={(this.props.suggestions ? this.props.suggestions.filter(item => item.label.includes(this.state.value)).slice(0, 15) : [])}
          renderItem={(item, isHighlighted) =>
            <div key={item.value} style={{ background: isHighlighted ? 'lightgray' : 'white', fontSize: "0.7rem", padding: "5px 5px" }}>
              {item.label}
            </div>
          }
          value={this.state.value}
          onChange={event => this._onChange(event) }
          onSelect={(value,item) => this._onSelect(value, item)}
        />

    );
  }

}

export default withStyles(styles)(KTInputAutoCompleteField);
