import React from 'react';

export default function KTGrid(props){
  const style = getItemStyle(props)
  if(!props.children) return null
  return (
    <div style={{...{display:"flex", alignContent:"flex-start"}, ...style, ...props.style}} >
      {props.children &&
      React.Children.map(props.children, child => {
        if(child){
           if(typeof child === "object"){
            return React.cloneElement(child, {})
           }else{
            return child
           }
        }else{
          return null
        }
      })}
    </div>
  )
}

function getItemStyle(props){
  let style = {}
  style.flexDirection = (props.vertical ? "column" : "row")
  if(props.flex){
    style.flex = 1
  }
  if(props.wrap){
    style.flexWrap = "wrap"
  }

  if(typeof props.top !== "undefined") style.marginTop = props.top
  if(typeof props.bottom !== "undefined") style.marginBottom = props.bottom
  if(typeof props.left !== "undefined") style.marginLeft = props.left
  if(typeof props.right !== "undefined") style.marginRight = props.right
  if(typeof props.ptop !== "undefined") style.paddingTop = props.ptop
  if(typeof props.pbottom !== "undefined") style.paddingBottom = props.pbottom
  if(typeof props.pleft !== "undefined") style.paddingLeft = props.pleft
  if(typeof props.pright !== "undefined") style.paddingRight = props.pright
  if(typeof props.padding !== "undefined") style.padding = props.padding
  if(typeof props.margin !== "undefined") style.margin = props.margin

  let xs = (props.xs ? props.xs : 12)
  let hs = (props.hs ? props.hs : 12)

  const xsNum = {
    xs1:"8.33%",
    xs2:"16.66%",
    xs3:"25%",
    xs4:"33.33%",
    xs5:"41.66%",
    xs6:"50%",
    xs7:"58.33%",
    xs8:"66.66%",
    xs9:"75%",
    xs10:"83.33%",
    xs11:"91.66%",
    xs12:"100%"
  }

  if(xsNum["xs"+xs]){
    style.width = xsNum["xs"+xs]
  }
  if(xsNum["xs"+hs]){
    style.height = xsNum["xs"+hs]
  }
  if(props.height) style.height = props.height
  if(props.bold) style.fontWeight = "bold"
  if(props.lineHeightPx) style.lineHeight = props.lineHeightPx+"px"

  if(props.vertical){
    style.justifyContent = 'flex-start'
    style.alignItems = 'flex-start'

    if(props.hcenter){
        style.alignItems = 'center'
    }else if(props.hend){
        style.alignItems = 'flex-end'
    }

    if(props.vcenter){
        style.justifyContent = 'center'
    }else if(props.vend){
        style.justifyContent = 'flex-end'
    }
  }else{
    style.justifyContent = 'flex-start'
    style.alignItems = 'center'

    if(props.hcenter){
        style.justifyContent = 'center'
    }else if(props.hend){
        style.justifyContent = 'flex-end'
    }

    if(props.vstart){
        style.alignItems = 'flex-start'
    }else if(props.vend){
        style.alignItems = 'flex-end'
    }
  }

  if(props.middle){
    style.justifyContent = 'center'
    style.alignItems = 'center'
  }

  return style
}
