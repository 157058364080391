module.exports = {
	"sidebar.section.main_menu": "เมนูหลัก",
	"sidebar.farm_monitoring": "Farm Monitoring",
	"sidebar.fleet_monitoring": "Fleet Monitoring",
	"sidebar.dashboard": "Dashboard",
	"sidebar.dashboard_fleetsugarfarm": "Sugar Farm Dashboard",
	"sidebar.dashboard_field": "Field Dashboard",
	"sidebar.dashboard_farmservice" : "Farm Service Dashboard",
	"sidebar.dashboard_farmservicegroup" : "Fleet Monitoring Dashboard",
	"sidebar.dashboard_monitoring": "Monitoring Dashboard",
	"sidebar.dashboard_notification": "Notification Dashboard",
	"sidebar.dashboard_field2": "ภาพรวมระบบ",
	"sidebar.map": "แผนที่แปลงขึ้นทะเบียน",
	"sidebar.fields" : "แปลงขึ้นทะเบียน",
	"sidebar.fields_status" : "แปลงขึ้นทะเบียนตามสถานะ",
	"sidebar.fields_plants" : "แปลงขึ้นทะเบียนตามประเภทอ้อย",
	"sidebar.fields_age" : "แปลงขึ้นทะเบียนตามอายุอ้อย",
	"sidebar.fields_address" : "แปลงขึ้นทะเบียนตามที่ตั้ง",	//address, distance
	"sidebar.fields_soil" : "แปลงขึ้นทะเบียนตามประเภทดิน",
	"sidebar.fields_staff" : "แปลงขึ้นทะเบียนตามเจ้าหน้าที่",
	"sidebar.fields_farmer" : "แปลงขึ้นทะเบียนตามชาวไร่",
	"sidebar.fields_harvestperiod": "แปลงขึ้นทะเบียนตามกำหนดตัด",
	"sidebar.fieldharvest" : "แปลงรถตัด",
	"sidebar.harvestfields_status": "ทะเบียนแปลงรถตัด",
	"sidebar.fieldharvest_request" : "คำขอขึ้นทะเบียนแปลงรถตัด",
	"sidebar.fieldharvest_eval" : "ตรวจสอบ/ประเมินแปลงรถตัด",
	"sidebar.collaterals": "หลักทรัพย์ค้ำประกัน",
	"sidebar.farms" : "กิจกรรมในแปลง",
	"sidebar.farm_prepares" : "การเตรียมดิน",
	"sidebar.farm_plants" : "การปลูก",
	"sidebar.farm_evaluations" : "การประเมินผลผลิต",
	"sidebar.farm_growth" : "การเจริญเติบโต",
	"sidebar.farm_verify" : "การตรวจแปลง",
	"sidebar.farm_brix" : "การวัดบริกซ์",
	"sidebar.plans" : "แผนงาน",
	"sidebar.plans_harvest" : "ใบงานนำตัด",
	"sidebar.plans_transport" : "ใบปล่อยรถบรรทุกรับอ้อย",
	"sidebar.contact_truckerrequest" : "ผู้ขับรถบรรทุกขอรับงาน",
	"sidebar.works" : "ข้อมูลรถจาก GPS",
	"sidebar.works_plow" : "งานรถไถ",
	"sidebar.works_harvest" : "งานรถตัดอ้อย",
	"sidebar.works_harvestentry" : "บันทึกงานรถตัดอ้อย",
	"sidebar.works_transportation" : "งานขนส่งอ้อยถึงโรงงาน",
	"sidebar.tickets" : "Service Ticket",
	"sidebar.contracts": "สัญญาส่งอ้อย",
	"sidebar.farmercontracts" : "สัญญาส่งอ้อย",
	"sidebar.farmercontracts_regist" : "คำขอทำสัญญาส่งอ้อย",
	"sidebar.farmers" : "ทะเบียนชาวไร่",
	"sidebar.farmers_regist" : "คำขอขึ้นทะเบียนชาวไร่",
	"sidebar.staffs" : "เจ้าหน้าที่ส่งเสริม",
	"sidebar.contact" : "ทะเบียนผู้ขับ, Vendor",
	"sidebar.contact_vendor": "ทะเบียนผู้ให้บริการ (Vendor)",
	"sidebar.contact_trucker": "ทะเบียนผู้ขับรถบรรทุก",
	"sidebar.contact_harvester": "ทะเบียนผู้ขับรถตัด",
	"sidebar.contact_cablecardriver": "ทะเบียนผู้ขับรถไถลาก-กระเช้า",
	"sidebar.documents" : "เอกสารและใบบันทึก",
	"sidebar.documents_billgasoline" : "บิลน้ำมัน",
	"sidebar.documents_deliverysugarcane" : "ใบบันทึกส่งอ้อยในลานชั่ง",
	"sidebar.documents_deliverysugarcane_import" : "เข้านำใบบันทึกส่งอ้อยในลานชั่ง",
	"sidebar.letter_to_dlt": "จดหมายแจ้งกรมขนส่ง",
	"sidebar.settings": "การตั้งค่า",
	"sidebar.settings_org" : "ตั้งค่าระบบขององค์กร",
	"sidebar.settings_project_vehicle_group" : "กลุ่มรถร่วมโครงการ",
	"sidebar.settings_vehicle": "รถลงทะเบียน (ทั้งหมด)",
	"sidebar.settings_fleet": "รถลงทะเบียน (ติดตั้ง GPS)",
	"sidebar.settings_fleet_s1": "ความเร็วรถ, ชม. เครื่องยนต์ (GPS)",
	"sidebar.settings_fleet_group": "กลุ่มรถตาม User (GPS)",
	"sidebar.settings_fleet_category": "กลุ่มประเภทรถ (GPS)",
	"sidebar.settings_fleet_division": "กลุ่มหน่วยรถ (GPS)",
	"sidebar.settings_fleet_zone": "กลุ่มโซนพื้นที่รถ (GPS)",
	"sidebar.settings_fleet_channel": "กลุ่ม Channel ขนส่ง (GPS)",
	"sidebar.settings_notification": "การแจ้งเตือน",
	"sidebar.settings_notifytoken": "กลุ่มแจ้งเตือน (Line, App, etc..)",
	"sidebar.settings_user": "ผู้ใช้งานระบบ (Smartfarm)",
	"sidebar.settings_user_survtrex": "ผู้ใช้งานระบบ (Survtrex)",
	"sidebar.settings_bank": "ธนาคาร",
	"sidebar.report": "รายงาน",
	"sidebar.report.field" : "แปลงปลูก",
	"sidebar.report.harvester" : "งานรถตัด",
	"sidebar.report.working": "งานบรรทุกขนส่งอ้อย",
	"sidebar.report.working_harvest": "การทำงานของรถตัด (GPS)",
	"sidebar.report.working_harvest_by_field": "แปลงตัดอ้อย (GPS)",
	"sidebar.report.working_transportation" : "รถขนอ้อยจากแปลงถึงโรงงาน (GPS)",
	"sidebar.report.working_checkpoint": "รถผ่านด่านและจุดต้องห้าม (GPS)",
	"sidebar.report.working_harvest_summary": "สรุปการทำงานของรถตัด (GPS)",
	"sidebar.report.working_harvest_field_summary": "สรุปผลผลิตที่ได้ต่อแปลงขึ้นทะเบียน",
	"sidebar.report.working_summarytransportationzone" : "สรุปเที่ยววิ่งรถตามโซน (GPS)",
	"sidebar.report.working_summarytransportationdaily" : "สรุปเที่ยววิ่งรถรายวัน (GPS)",
	"sidebar.report.working_transportationqueueweight": "การรับอ้อย (แจ้งคิว+ลานชั่ง)",
	"sidebar.report.plan" : "แผนงาน",
	"sidebar.report.plan_harvesttransport" : "การรับอ้อยตามแผนงาน",
	"sidebar.report.plan_summaryharvest" : "สรุปงานรถตัดตามแผนงาน",
	"sidebar.report.trip": "การวิ่งรถและสถานที่",
	"sidebar.report.trip_fieldlocation":"รถตัดย้ายแปลง (GPS)",
	"sidebar.report.farmer" : "ชาวไร่",
	"sidebar.report.farmer_field" : "ทะเบียนชาวไร่",
	"sidebar.report.farmer_contractton" : "สัญญาส่งอ้อย",

	"status" : "สถานะ",
	"status.All": "ทั้งหมด",
	"status.Draft": "รอตรวจสอบ",
	"status.Checking": "กำลังตรวจสอบ",
	"status.Verified": "ยืนยันข้อมูล",
	"status.Cancelled": "ยกเลิก",
	"status.Inactive": "In-Active",
	"status.Active": "Active",
	"button.checking" : "ตรวจสอบ",
	"button.verified" : "ยืนยันข้อมูล",

	"dashboard.number_of_field" : "แปลงขึ้นทะเบียน",
	"dashboard.number_of_field.Draft" : "แปลงรอตรวจสอบ",
	"dashboard.number_of_field.Verified" : "แปลงขึ้นทะเบียนใหม่",
	"dashboard.number_of_field.Plant" : "แปลงที่ปลูกแล้ว",
	"dashboard.number_of_field.Evaluation" : "แปลงรอตัด/กำลังตัด",
	"dashboard.number_of_field.Harvesting30" : "แปลงที่ตัดแล้ว > 30%",
	"dashboard.number_of_field.Harvesting80" : "แปลงที่ตัดแล้ว >= 80%",
	"dashboard.number_of_field.Harvest" : "แปลงที่ตัดเสร็จ",
	"dashboard.number_of_area" : "พื้นที่ปลูก (จดแจ้ง)",
	"dashboard.number_of_area.Draft" : "พื้นที่รอตรวจสอบ",
	"dashboard.number_of_area.Verified" : "พื้นที่จดแจ้งแล้ว",
	"dashboard.number_of_area.Plant" : "พื้นที่เริ่มปลูกแล้ว",
	"dashboard.number_of_area.Evaluation" : "พื้นที่รอตัด/กำลังตัด",
	"dashboard.number_of_area.Harvest" : "พื้นที่ตัดเสร็จ",
	"dashboard.number_of_farmer" : "จำนวนชาวไร่",
	"dashboard.number_of_staff" : "จำนวนเจ้าหน้าที่",
	"dashboard.number_of_ton.All" : "เข้าหีบทั้งหมด (ลานชั่ง)",
	"dashboard.number_of_transportation.All" : "เที่ยวรถทั้งหมด (GPS)",
	"dashboard.number_of_ton.Today" : "เข้าหีบวันนี้ (ลานชั่ง)",
	"dashboard.number_of_transportation.Today" : "เที่ยวรถวันนี้ (GPS)",
	"dashboard.number_of_weight.All" : "เที่ยวรถทั้งหมด (ลานชั่ง)",
	"dashboard.number_of_weight.Today" : "เที่ยวรถวันนี้ (ลานชั่ง)",
	"dashboard.number_of_eton.All" : "เข้าหีบทั้งหมด (ประมาณการ)",
	"dashboard.number_of_eton.Today" : "เข้าหีบวันนี้ (ประมาณการ)",
	"dashboard.number_of_plan_ton.All" : "เข้าหีบทั้งหมด (แผน)",
	"dashboard.number_of_plan_ton.Today" : "เข้าหีบวันนี้ (แผน)",
	"dashboard.number_of_plan.All" : "เที่ยวรถทั้งหมด (แผน)",
	"dashboard.number_of_plan.Today" : "เที่ยวรถวันนี้ (แผน)",
	"dashboard.harvestHour" : "ชั่วโมงการตัดรวม",
	"dashboard.harvestAreaPerDay" : "พื้นที่ตัดเฉลี่ย / วัน",
	"dashboard.harvestAreaPerHour" : "พื้นที่ตัดเฉลี่ย / ชม",
	"dashboard.percent_from_all" : "% จากทั้งหมด",
	"dashboard.field_ready_to_harvest" : "แปลงอายุเกินตัด",
	"dashboard.field_to_havrvest" : "แปลงพร้อมตัด",
	"dashboard.field_havrvested" : "แปลงที่ตัดเสร็จแล้ว",
	"dashboard.trucker_waiting" : "รถบรรทุกของาน",
	"dashboard.plow_job_waiting" : "งานไถใหม่",
	"dashboard.fuel_consumption_all" : "การใช้น้ำมันทั้งหมด",
	"dashboard.fuel_consumption_avg" : "การใช้น้ำมันเฉลี่ย",
	"dashboard.plan_harvest_waiting" : "แผนรอรถตัดรับงาน",
	"dashboard.plans_transport_waiting" : "แผนรอรถบรรทุกรับงาน",
	"dashboard.target_weight": "ตันประเมินเป้าหมาย",

	"dashboard.fleet_status" : "สถานะปัจจุบัน",
	"dashboard.fleet_status.working" : "กำลังวิ่งงาน",
	"dashboard.fleet_status.harvesting" : "กำลังตัด",
	"dashboard.fleet_status.moving" : "ย้ายแปลง",
	"dashboard.fleet_status.idle" : "จอดติดเครื่อง",
	"dashboard.fleet_status.off" : "ดับเครื่อง",
	"dashboard.fleet_status.offline" : "สัญญาณหาย",
	"dashboard.fleet_status.not_update" : "ไม่อัพเดท",

	"dashboard.fleet_location" : "พื้นที่",
	"dashboard.hour_engine_accu" : "ชม.การทำงาน",
	"dashboard.hour_trip_accu" : "ชม.การเดินทาง",
	"dashboard.distance_out_field" : "ระยะทางย้ายแปลง",
	"dashboard.hour_harvest_accu" : "ชม.การตัดโคน",
	"dashboard.harvest_accu" : "ปริมาณการตัดสะสม (ตัน)",
	"dashboard.plow_accu" : "พื้นที่ไถสะสม (ไร่)",
	"dashboard.fuel_consumption_accu1" : "ปริมาณการใช้น้ำมันเชื้อเพลิงรถตัดอ้อย (ลิตร)",
	"dashboard.fuel_consumption_accu2" : "ปริมาณการใช้น้ำมันเชื้อเพลิงรถไถ (ลิตร)",
	"dashboard.fuel_consumption_accu3" : "ปริมาณการใช้น้ำมันเชื้อเพลิงรถอื่นๆ (ลิตร)",
	"dashboard.fleet_group" : "กลุ่มรถ",
	"dashboard.fleet_category" : "ประเภทรถ",
	"dashboard.oiler_draining" : "กำลังจ่ายน้ำมัน",
	"dashboard.oiler_normal" : "ไม่ได้ปฏิบัติงาน",
	"dashboard.service_progress" : "กำลังซ่อมบำรุง",
	"dashboard.service_normal" : "ไม่ได้ปฏิบัติงาน",
	"dashboard.service_progress_hour" : "ซ่อมมาแล้ว",
	"dashboard.fuel_harvester_total" : "การใช้เชื้อเพลิงรถตัดรวม",
	"dashboard.harvest_total" : "จำนวนตันที่ตัดได้รวม",
	"dashboard.harvest_consumption" : "สัดส่วนเชื้อเพลิงรถตัด",
	"dashboard.fuel_tractor_total" : "การใช้เชื้อเพลิงรถไถรวม",
	"dashboard.plow_total" : "พื้นที่ไถรวม",
	"dashboard.plow_consumption" : "สัดส่วนเชื้อเพลิงรถไถ",

	"fleet.title" : "การตั้งค่าตัวรถ",
	"fleet.title_info" : "ข้อมูลรถ",
	"fleet.title_group" : "การตั้งค่ากลุ่มรถ",
	"fleet.title_driver" : "ข้อมูลผู้ขับขี่",
	"fleet.title_fuel" : "การตั้งค่าน้ำมัน",
	"fleet.title_policy" : "การตั้งค่าความเร็ว, ชม เครื่องยนต์ และอื่นๆ",
	"fleet.fleet_group" : "กลุ่มรถ (User)",
	"fleet.fleet_category" : "ประเภทรถ",
	"fleet.fleet_division" : "หน่วยรถ",
	"fleet.regist_no" : "เบอร์รถ",
	"fleet.extend": "ทะเบียนพ่วง",
	"fleet.vehicle_role": "การติดตั้ง",
	"fleet.chassis_no": "เลขตัวถัง",
	"fleet.vehicle_brand": "ยี่ห้อรถ",
	"fleet.zone": "โซนพื้นที่รถ",
	"fleet.channel": "Channel ขนส่ง",
	"fleet.driver": "ผู้ขับขี่หลัก",
	"fleet.driver_phone": "เบอร์โทรผู้ขับ",
	"fleet.fuel_capable": "ขนาดถัง",
	"fleet.vehicle_model": "ค่าน้ำมัน (รุ่นรถ)",
	"fleet.fuel_cost": "ต้นทุนค่าเชื้อเพลิง",
	"fleet.fuel_rate": "อัตราสิ้นเปลืองเชื้อเพลิง",
	"fleet.fuel_hour_rate": "อัตราสิ้นเปลืองเชื้อเพลิง (ขณะจอดแช่)",
	"fleet.limited_speed": "ความเร็วเกินกำหนด",
	"fleet.limited_speed_time": "เป็นระยะเวลา",
	"fleet.limited_stop": "จอดติดเครื่องเกินกำหนด",
	"fleet.limited_work": "วิ่งต่อเนื่องเกินกำหนด",
	"fleet.rest_time": "โดยไม่หยุดพัก",
	"fleet.limited_work_daily": "ระยะเวลาวิ่งรถในรอบ 24 ชม.เกิน",
	"fleet.limited_rpm": "รอบเครื่องเกินกำหนด",
	"fleet.limited_rpm_time": "เป็นระยะเวลา",

	"report.query_operation" : "เรียกดูข้อมูล",
	"report.print_date" : "วันที่พิมพ์",
	"report.start_date" : "จากวันที่",
	"report.end_date" : "ถึงวันที่",
	"report.start_time" : "ตั้งแต่เวลา",
	"report.end_time" : "ถึงเวลา",
	"report.vehicle" : "เบอร์/ทะเบียนรถ",
	"report.checkpoint1" : "จุด Check Point 1",
	"report.checkpoint2" : "จุด Check Point 2",

	"coordinate.lat": "พิกัดละติจูด",
	"coordinate.lng": "พิกัดลองจิจูด",

	"contact.title_additional":"(เพิ่มเติม)",
	"contact.profile_info" : "ข้อมูลทั่วไป",
	"contact.farmer_info": "ข้อมูลชาวไร่",
	"contact.profile_attach": "เอกสารแนบ",
	"contact.profile_status": "สถานะรับงาน",
	"contact.profile_status_time": "เวลาที่อัพเดทสถานะ",
	"contact.attach_profile": "ภายถ่ายล่าสุด",
	"contact.attach_card": "บัตรประชาชน",
	"contact.attach_home": "ทะเบียนบ้าน",
	"contact.attach_bank": "หน้าสมุดบัญชี",
	"contact.attach_request": "เอกสารคำร้อง",
	"contact.last_updated" : "อัพเดทล่าสุด",
	"contact.address" : "ข้อมูลที่อยู่หลัก (ตามเอกสาร)",
	"contact.address_no" : "เลขที่อยู่/อาคาร/หมู่บ้าน/ถนน/ซอย",
	"contact.region" : "เขตตำบล",
	"contact.zipcode" : "รหัสไปรษณีย์",
	"contact.vehicle": "ข้อมูลรถที่ขับขี่",
	"contact.vehicle_no": "เบอร์รถ",
	"contact.trailer_no": "รถพ่วง",
	"contact.tractor_no": "รถไถลาก",
	"contact.cablecar_no": "รถกระเช้า",
	"contact.vehicle_gps": "รถที่ติดตั้ง GPS",
	"contact.phone" : "เบอร์โทร",
	"contact.sex":"เพศ",
	"contact.birthdate":"วันเกิด",
	"contact.marriage":"สถานะคู่ครอง",
	"contact.fax" : "แฟกซ์",
	"contact.email" : "อีเมลล์",
	"contact.line" : "Line",
	"contact.website" : "เว็บไซด์",
	"contact.company_name" : "ชื่อบริษัท",
	"contact.nickname" : "ชื่อเล่น",
	"contact.education":"การศึกษา",
	"contact.abbrname" : "ชื่อย่อ",
	"contact.credit" : "เครดิต",
	"contact.branch" : "สาขา",
	"contact.branch_hint" : "(สำนักงานใหญ่)",
	"contact.company_ref" : "Vendor",
	"contact.remark" : "หมายเหตุ",
	"contact.comment": "รายละเอียดการพิจารณา",
	"contact.taxno" : "เลขผู้เสียภาษี",
	"contact.cardno" : "เลขบัตรประชาชน",
	"contact.card_expire": "หมดอายุ",
	"contact.bank_info" : "ข้อมูลบัญชีธนาคารหลัก",
	"contact.bank" : "ธนาคาร",
	"contact.bank_account" : "เลขที่บัญชี",
	"contact.bank_branch" : "สาขา",
	"contact.bank_acc_type" : "ประเภทบัญชี",
	"contact.account_name" : "ชื่อบัญชี",
	"contact.account" : "ข้อมูลทางบัญชี",
	"contact.account_no": "รหัสบัญชี",
	"contact.contact_type" : "ประเภทผู้ติดต่อ",
	"contact.doc_type" : "ประเภทการติดต่อ",
	"contact.doc_date": "วันที่ลงทะเบียน",
	"contact.follower": "พนง.ติดตาม",
	"contact.status.D" : "เอกสารใหม่",
	"contact.status.S" : "ส่งข้อมูล",
	"contact.status.V" : "ยืนยันข้อมูล",
	"contact.status.R" : "ไม่อนุมัติ",
	"contact.status.A1" : "อนุมัติ",
	"contact.status.A" : "อนุมัติเปิดใช้งาน",
	"contact.status.N" : "ปิดสถานะการใช้งาน",
	"contact.status.C" : "ยกเลิก",
	"contact.fleet_channel": "Channel รถขนส่ง",
	"contact.fleet_zone": "โซนรถตัด",
	"contact.all_jobs": "งานทั้งหมด",
	"contact.on_process_jobs": "งานค้าง",
	"contact.last_closed_job_time": "ปิดงานล่าสุด",
	"contact.last_job_time": "จ่ายงานล่าสุด",

	"trucker.title" : "ทะเบียนผู้ขับรถบรรทุก",
	"trucker.name" : "ชื่อ-สกุล",
	"trucker.doc_no" : "รหัสผู้ขับ",

	"farmer.title_registering" : "คำขอขึ้นทะเบียนชาวไร่",
	"farmer.title_registered" : "ทะเบียนชาวไร่",
	"farmer.title_profile" : "ข้อมูลชาวไร่",
	"farmer.title_profile_image": "ภายถ่ายชาวไร่",
	"farmer.title_bank" : "ข้อมูลทางบัญชี",
	"farmer.title_address" : "ข้อมูลที่อยู่ชาวไร่",
	"farmer.title_more" : "รายละเอียดเพิ่มเติม",
	"farmer.title_staff" : "ข้อมูลพนักงานส่งเสริม",
	"farmer.title_photo" : "ภาพถ่าย",
	"farmer.title_attach" : "เอกสารแนบ",
	"farmer.quota": "รหัสชาวไร่",
	"farmer.code": "รหัสชาวไร่",
	"farmer.account_no": "รหัสอ้างอิง",
	"farmer.doc_date": "วันที่ลงทะเบียน",
	"farmer.name": "ชื่อ-สกุล (ไทย)",
	"farmer.engname": "ชื่อ-สกุล (Eng)",
	"farmer.nickname" : "ชื่อเล่น",
	"farmer.sex":"เพศ",
	"farmer.phone" : "เบอร์โทร",
	"farmer.card_no": "เลขที่บัตรประชาชน",
	"farmer.card_expire": "หมดอายุ",
	"farmer.farmer_card": "บัตรชาวไร่",
	"farmer.birthdate":"วันเกิด",
	"farmer.marriage":"สถานะคู่ครอง",
	"farmer.education":"การศึกษา",
	"farmer.grade":"เกรดชาวไร่",
	"farmer.type":"ประเภทชาวไร่",
	"farmer.division_area": "เขต/สาย",
	"farmer.zone": "โซน",
	"farmer.address_no" : "ที่อยู่ชาวไร่",
	"farmer.region" : "เขตตำบล",
	"farmer.zipcode" : "รหัสไปรษณีย์",
	"farmer.group_head" : "รหัสหัวหน้ากลุ่ม",
	"farmer.credit" : "เครดิต",
	"farmer.remark" : "หมายเหตุ",
	"farmer.comment": "รายละเอียดการพิจารณา",
	"farmer.bank_info" : "ข้อมูลบัญชีธนาคารหลัก",
	"farmer.bank" : "ธนาคาร",
	"farmer.bank_account" : "เลขที่บัญชี",
	"farmer.bank_branch" : "สาขา",
	"farmer.bank_acc_type" : "ประเภทบัญชี",
	"farmer.account_name" : "ชื่อบัญชี",
	"farmer.owner": "พนง.ส่งเสริม",
	"farmer.follower": "พนง.ติดตาม",
	"farmer.attach_card": "บัตรประชาชน",
	"farmer.attach_home": "ทะเบียนบ้าน",
	"farmer.attach_bank": "หน้าสมุดบัญชี",
	"farmer.attach_request": "เอกสารคำร้อง",
	"farmer.status.D" : "เอกสารใหม่",
	"farmer.status.S" : "ส่งข้อมูล",
	"farmer.status.V" : "ยืนยันข้อมูล",
	"farmer.status.R" : "ไม่อนุมัติ",
	"farmer.status.A1" : "อนุมัติ",
	"farmer.status.Active" : "อนุมัติเปิดบัญชี",
	"farmer.status.N" : "ปิดสถานะบัญชี",
	"farmer.status.C" : "ยกเลิก",

	"farmercontract.title_registering" : "คำขอทำสัญญาส่งอ้อย",
	"farmercontract.title_registered" : "สัญญาส่งอ้อย",
	"farmercontract.title_profile" : "ข้อมูลชาวไร่",
	"farmercontract.title_ton" : "ปริมาณตันอ้อย",
	"farmercontract.title_contract" : "ประสงค์ขอสินเชื่อประเภท",
	"farmercontract.title_payment" : "การขอรับเงิน",
	"farmercontract.title_address" : "ที่อยู่แปลง",
	"farmercontract.title_collateral": "ข้อมูลหลักทรัพทย์ค้ำประกัน",
	"farmercontract.title_plant" : "ข้อมูลการปลูกอ้อย",
	"farmercontract.title_prepare" : "ข้อมูลความพร้อมในการปลูกอ้อย",
	"farmercontract.title_to_factory" : "ข้อมูลการขนส่งอ้อยเข้าหีบ",
	"farmercontract.title_delivery" : "ข้อมูลการส่งอ้อย",
	"farmercontract.title_selling": "ข้อมูลการขายอ้อย",
	"farmercontract.title_other": "ข้อมูลอื่นๆ",
	"farmercontract.title_photo" : "ภาพถ่าย",
	"farmercontract.title_attach" : "เอกสารแนบ",
	"farmercontract.doc_no": "เลขที่คำขอ",
	"farmercontract.ref_doc_no": "เลขที่สัญญา",
	"farmercontract.doc_date": "วันที่คำขอ",
	"farmercontract.yearly": "ปีการผลิต",
	"farmercontract.farmer_name": "ชื่อ-สกุล",
	"farmercontract.farmer_phone" : "เบอร์โทร",
	"farmercontract.contract_type" : "ประเภทสัญญา",
	"farmercontract.volume" : "สัญญาตัน",
	"farmercontract.credit_amount" : "วงเงินสินเชื่อ",
	"farmercontract.num_all" : "จำนวนแปลงขึ้นทะเบียน",
	"farmercontract.num_all2" : "แปลงอ้อย",
	"farmercontract.area_all" : "พื้นที่ขึ้นทะเบียน",
	"farmercontract.credit" : "ประเภทส่งเสริม",
	"farmercontract.plant_new": "อ้อยใหม่/ตนเอง",
	"farmercontract.plant_tor": "อ้อยตอ/ตนเอง",
	"farmercontract.buy_new": "ซื้ออ้อยใหม่",
	"farmercontract.buy_tor": "ซื้ออ้อยตอ",
	"farmercontract.cane_yard": "อ้อยลาน",
	"farmercontract.vol_contract": "ปริมาณสัญญา",
	"farmercontract.collateral_type": "หลักทรัพย์",
	"farmercontract.collateral_val": "มูลค่า",
	"farmercontract.collateral_desc": "รายละเอียดหลักทรัพย์",
	"farmercontract.doc_area": "พื้นที่",
	"farmercontract.village": "หมู่บ้าน",
	"farmercontract.region": "เขตตำบล",
	"farmercontract.region_hint": "ตำบล/อำเภอ/จังหวัด",
	"farmercontract.payment_option": "ขอรับเงินอื่นๆ (ระบุ)",
	"farmercontract.land_owner": "ที่ดินตนเอง/ครอบครัว",
	"farmercontract.land_rent": "ที่ดินเช่า/บุคคลอื่น",
	"farmercontract.land_other": "ที่ดินอื่นๆ",
	"farmercontract.tractor_big": "รถไถขนาดใหญ่",
	"farmercontract.tractor_med": "รถไถขนาดกลาง",
	"farmercontract.plant_machine": "เครื่องปลูกอ้อย",
	"farmercontract.grabber": "เครื่องคีบอ้อย",
	"farmercontract.truck6w": "รถ 6 ล้อ",
	"farmercontract.trucktrailer": "รถบรรทุกเทเลอร์",
	"farmercontract.trucktractort": "รถพ่วงรถไถ",
	"farmercontract.truck10w": "รถ 10 ล้อเดี่ยว",
	"farmercontract.truck10wt": "รถ 10 ล้อ/พ่วง",
	"farmercontract.harvester": "รถตัดอ้อย",
	"farmercontract.sell1": "การส่ง/ขายอ้อย 1",
	"farmercontract.sell2": "การส่ง/ขายอ้อย 2",
	"farmercontract.sell_farmer": "ชาวไร่รายใหญ่",
	"farmercontract.sell_farmer_hint": "กรุณาระบุ กรณีส่งให้ชาวไร่รายใหญ่",
	"farmercontract.sell_fac_name": "เคยส่งอ้อยให้แก่โรงงาน",
	"farmercontract.sell_fac_year": "ปีการผลิต",
	"farmercontract.sell_fac_volume": "ปริมาณ",
	"farmercontract.sell_fac_contract": "ปัจจุบันมีสัญญาส่งอ้อยกับโรงงาน",
	"farmercontract.sell_fac_cvolume": "ปริมาณ",
	"farmercontract.member": "เลขที่สมาชิก",
	"farmercontract.regist": "เลขประจำตัวชาวไร่",
	"farmercontract.other": "อื่นๆ (เพิ่มเติม)",
	"farmercontract.comment": "รายละเอียดการพิจารณา",
	"farmercontract.staff" : "เจ้าหน้าที่",
	"farmercontract.remark" : "หมายเหตุ",
	"farmercontract.attach_request": "เอกสารคำร้อง",
	"farmercontract.status.Checked" : "ยืนยันข้อมูล",
	"farmercontract.status.Signed" : "อนุมัติสัญญา",
	"farmercontract.status.Cancelled" : "ยกเลิก",
	"farmercontract.status.Rejected" : "ไม่อนุมัติสัญญา",

	"staff.profile": "ข้อมูลเจ้าหน้าที่",
	"staff.code": "รหัสพนักงาน",
	"staff.name": "ชื่อ-สกุลเจ้าหน้าที่",
	"staff.division": "พื้นที่รับผิดชอบ",
	"staff.department": "แผนก",
	"staff.phone": "เบอร์โทร",
	"staff.email": "อีเมลล์",
	"staff.profile_image": "รูปถ่าย",

	"driver.profile": "ข้อมูลผู้ขับขี่",
	"driver.code": "รหัสผู้ขับขี่",
	"driver.name": "ชื่อ-สกุล",
	"driver.phone": "เบอร์โทร",
	"driver.card_id": "เลขใบขับขี่",
	"driver.citizen_id": "Partner Code",
	"driver.profile_image": "รูปถ่าย",
	"driver.vehicle": "เบอร์รถ",
	"driver.status": "พร้อมรับงาน",
	"driver.device_status": "สถานะ GPS",
	"driver.processing_jobs": "งานค้าง",
	"driver.fleet_category" : "ประเภทรถ",
	"driver.address" : "ตำแหน่ง GPS ปัจจุบัน",
	"driver.chassis_no": "เลขตัวถังรถ",
	"driver.location_name": "ตำแหน่งรถ",
	"driver._request" : "ขอรับงาน (O)",
	"driver.service_request" : "ขอรับงาน (S)",
	"driver.harvester_request" : "เรียกรถบรรทุก",
	"driver.transport_request" : "ขอรับงาน",
	"driver.is_main": "ผู้ขับหลัก",

	"field.yearly": "ปีการผลิต",
	"field.division": "หน่วยส่งเสริม",
	"field.division_area": "เขต/สาย",
	"field.zone": "โซน",
	"field.hzone": "โซนรถตัด",
	"field.regist_type": "พื้นที่",
	"field.regist_type.old": "เก่า",
	"field.regist_type.new": "ใหม่",
	"field.ref_field_no": "เลขที่แปลงเดิม",
	"field.c_field_no": "เลขที่อ้างอิง",
	"field.contract_no": "เลขที่สัญญา",
	"field.staff": "ผู้รับผิดชอบ",
	"field.farmer": "ชาวไร่",
	"field.farmer_code": "โควต้า",
	"field.farmer_name": "ชื่อชาวไร่",
	"field.farmer_phone": "เบอร์โทร",
	"field.farmer_address": "ที่อยู่ชาวไร่",
	"field.farmer_grade":"เกรดชาวไร่",
	"field.farmer_type":"ประเภทชาวไร่",
	"field.harvester": "รถตัด",
	"field.produce_weight" : "ตันประเมิน",
	"field.plan": "แผนงาน",
	"field.field_type": "ประเภทแปลง",
	"field.doc_date" : "วันที่สำรวจ",
	"field.regist_date" : "วันที่ขึ้นทะเบียน",
	"field.field_no": "เลขที่แปลง",
	"field.area": "พื้นที่",
	"field.coordinate": "พิกัด",
	"field.region" : "ที่อยู่แปลง",
	"field.address": "ที่ตั้งแปลง GPS",
	"field.field_address": "ที่ตั้งแปลง",
	"field.field_address_register": "ที่อยู่แปลง",
	"field.doc_area" : "พื้นที่จดแจ้ง",
	"field.gps_area" : "พื้นที่ GPS",
	"field.produce_grade": "เกรดพื้นที่",
	"field.field_category": "ชนิดแปลง",
	"field.supporting": "ขอส่งเสริม",

	"field.status.Plant" : "เริ่มปลูก",
	"field.status.Evaluation" : "พร้อมตัด",
	"field.status.Harvest" : "ตัดเสร็จแล้ว",
	"field.status.Cancelled" : "ยกเลิก",
	"field.status.Checked" : "ยืนยันข้อมูล",
	"field.status.Verified" : "อนุมัติแปลง",
	"field.status.Draft" : "ยกเลิกอนุมัติ",

	"field.ownership": "กรรมสิทธิ์",
	"field.owner_type": "เอกสารสิทธิ์",
	"field.owner_doc_no": "เลขที่เอกสาร",
	"field.owner_expire_date": "วันที่สัญญา",

	"field.topography": "ภูมิประเทศ",
	"field.soil_type": "ประเภทดิน",
	"field.water_source": "แหล่งน้ำต้นทุน",
	"field.distance_to_factory": "ระยะทางถึงโรงงาน",
	"field.distance": "ระยะทาง",

	"field.plant_type": "ประเภทอ้อย",
	"field.plant_type2": "ประเภทการปลูก/บำรุง",
	"field.support_type": "ประเภทส่งเสริม",
	"field.plant_date": "วันที่ปลูก/บำรุง",
	"field.plant_age": "อายุอ้อย",
	"field.plant_manage": "ลักษณะการปลูก",
	"field.harvest_type": "ประเภทการตัด",
	"field.intersect": "แปลงทับซ้อน",
	"field.harvest_period" : "ช่วงกำหนดตัด",
	"field.harvest_date": "วันกำหนดตัด",
	"field.gap" : "ระยะร่อง",
	"field.len_groove" : "ความยาวร่อง",
	"field.seed" : "พันธุ์อ้อย",
	"field.barrier" : "อุปสรรค",
	"field.remark" : "หมายเหตุ",
	"field.updated_time": "วันที่แก้ไขล่าสุด",
	"field.progress": "ตัดแล้ว(%)",

	"fieldharvest.title": "คำขอขึ้นทะเบียนแปลงรถตัด",
	"fieldharvest.new": "คำขอขึ้นทะเบียนแปลงรถตัดใหม่",
	"fieldharvest.doc_no": "เลขที่อ้างอิง",
	"fieldharvest.start_date": "จากวันที่",
	"fieldharvest.end_date": "ถึงวันที่",
	"fieldharvest.field_no": "เลขที่แปลง",
	"fieldharvest.yearly": "ปีการหีบอ้อย",
	"fieldharvest.doc_date": "วันที่คำขอ",
	"fieldharvest.contract_no": "เลขที่สัญญา",
	"fieldharvest.farmer": "ชาวไร่",
	"fieldharvest.farmer_code": "รหัสชาวไร่",
	"fieldharvest.farmer_phone" : "เบอร์โทรชาวไร่",
	"fieldharvest.farmer_address" : "ที่อยู่ชาวไร่",
	"fieldharvest.staff": "เจ้าหน้าที่",
	"fieldharvest.division": "เขต/สาย",
	"fieldharvest.zone": "โซน",
	"fieldharvest.lat": "ละติจูด",
	"fieldharvest.lng": "ลองจิจูด",
	"fieldharvest.ref_field_no":"เลขที่แปลงเดิม",
	"fieldharvest.c_field_no":"เลขที่อ้างอิง",
	"fieldharvest.village":"หมู่บ้าน",
	"fieldharvest.region":"ที่อยู่แปลง",
	"fieldharvest.location":"ที่ตั้งแปลง GPS",
	"fieldharvest.coordinate":"พิกัด",
	"fieldharvest.num_all": "แปลงทั้งหมด",
	"fieldharvest.area_all": "พื้นที่รวม",
	"fieldharvest.item_field_no":"เลขที่แปลง",
	"fieldharvest.item_field_address":"ที่ตั้งแปลง GPS",
	"fieldharvest.item_gps_area":"พื้นที่",
	"fieldharvest.item_doc_area":"พื้นที่",
	"fieldharvest.item_address_no":"หมู่บ้าน",
	"fieldharvest.item_region":"ตำบล/อำเภอ/จังหวัด",
	"fieldharvest.status.Cancelled" : "ยกเลิก",
	"fieldharvest.status.Verified" : "อนุมัติ",

	"farmharvest.doc_date":"วันที่สำรวจ",
	"farmharvest.times":"ครั้งที่",
	"farmharvest.doc_no": "เลขที่อ้างอิง",
	"farmharvest.start_date": "จากวันที่",
	"farmharvest.end_date": "ถึงวันที่",
	"farmharvest.field_no": "เลขที่แปลง",
	"farmharvest.yearly": "ปีการหีบอ้อย",
	"farmharvest.contract_no": "เลขที่สัญญา",
	"farmharvest.farmer": "ชาวไร่",
	"farmharvest.farmer_code": "รหัสชาวไร่",
	"farmharvest.farmer_phone" : "เบอร์โทรชาวไร่",
	"farmharvest.farmer_address" : "ที่อยู่ชาวไร่",
	"farmharvest.staff": "เจ้าหน้าที่",
	"farmharvest.division": "เขต/สาย",
	"farmharvest.zone": "โซน",
	"farmharvest.ref_field_no":"เลขที่แปลงเดิม",
	"farmharvest.c_field_no":"เลขที่อ้างอิง",
	"farmharvest.village":"หมู่บ้าน",
	"farmharvest.region":"ที่อยู่แปลง",
	"farmharvest.location":"ที่ตั้งแปลง GPS",
	"farmharvest.coordinate":"พิกัด",
	"farmharvest.status.Cancelled" : "ยกเลิก",
	"farmharvest.status.Verified" : "อนุมัติ",

	"farm.doc_date":"วันที่สำรวจ",
	"farm.times":"ครั้งที่",
	"farm.owner":"เจ้าหน้าที่",
	"farm.plant_date":"วันที่ปลูก",
	"farm.farmer_name":"ชาวไร่",
	"farm.status.Sent":"ส่งข้อมูล",
	"farm.status.Checked":"ยืนยันข้อมูล",
	"farm.status.Verified":"อนุมัติกิจกรรมแปลง",
	"farm.status.Draft":"ส่งแก้ไข",
	"farm.status.Cancelled":"ยกเลิก",

	"farm.prepare.title" : "การเตรียมดิน",
	"farm.prepare.date":"วันที่เตรียมดิน",
	"farm.prepare.ptype":"การเตรียมดิน",
	"farm.prepare.soil_type":"ลักษณะเนื้อดิน",
	"farm.prepare.tool":"อุปกรณ์ที่ใช้",
	"farm.prepare.manage":"การจัดเตรียมดิน",
	"farm.prepare.contractor_name":"ชื่อ-สกุลผู้รับเหมา",
	"farm.prepare.contractor_phone":"เบอร์โทร",
	"farm.prepare.soil_quality":"ความละเอียดดิน",
	"farm.prepare.depth":"ความลึก",
	"farm.prepare.len_groove" : "ความยาวร่อง",
	"farm.prepare.space_groove" : "ระยะร่อง",
	"farm.prepare.space_tailing" : "ระยะหัวแปลง/ท้ายแปลง",
	"farm.prepare.num_trees" : "จำนวนต้นไม้",
	"farm.prepare.num_stumps" : "จำนวนตอไม้",
	"farm.prepare.area":"พื้นที่เตรียมดินเสร็จ",
	"farm.prepare.np_area":"พื้นที่ยังไม่เตรียมดิน",
	"farm.prepare.np_cause":"สาเหตุที่ไม่เตรียมดิน",
	"farm.prepare.next_date":"วันที่คาดว่าจะเตรียมดินต่อ",
	"farm.prepare.remark":"รายละเอียดอื่นๆ",

	"farm.plant.title" : "การปลูก",
	"farm.plant.date" : "วันที่ปลูก",
	"farm.plant.area" : "พื้นที่ปลูกเสร็จ",
	"farm.plant.np_area" : "พื้นที่ยังไม่ปลูก",
	"farm.plant.np_cause" : "สาเหตุการไม่ปลูก",
	"farm.plant.next_date" : "วันที่คาดว่าจะปลูกต่อ",
	"farm.plant.manage" : "การจัดการปลูก",
	"farm.plant.contractor_name":"ชื่อ-สกุลผู้รับเหมา",
	"farm.plant.how" : "วิธีการปลูก",
	"farm.plant.groove" : "ลักษณะร่องปลูก",
	"farm.plant.depth":"ชักร่องลึก",
	"farm.plant.space_groove" : "ระยะห่างร่อง",
	"farm.plant.seed_name" : "ชื่อพันธุ์อ้อย",
	"farm.plant.filling" : "การกลบหนา",
	"farm.plant.fertilizer" : "ปุ๋ยรองพื้น",
	"farm.plant.npk" : "สูตรปุ๋ย",
	"farm.plant.npk_vol" : "ปริมาณปุ๋ยที่ใส่",
	"farm.plant.injection" : "การฉีดยาคุม",
	"farm.plant.send_water" : "การให้น้ำ",
	"farm.plant.remark" : "รายละเอียดอื่นๆ",

	"farm.evaluation.title" : "การประเมินผลผลิต",
	"farm.evaluation.round" : "ประเมินครั้งที่",
	"farm.evaluation.produce" : "ตันประเมิน",
	"farm.evaluation.produce_weight" : "ผลผลิตที่ได้",
	"farm.evaluation.send_weight" : "เข้าหีบ",
	"farm.evaluation.keep_weight" : "ไว้พันธุ์",
	"farm.evaluation.plot_distance" : "ระยะห่างร่อง",
	"farm.evaluation.weight_trunk" : "น้ำหนัก/ลำ",
	"farm.evaluation.trunk_num" : "จำนวนลำ/เมตร",
	"farm.evaluation.age" : "อายุอ้อย ณ วันประเมิน",

	"farm.brix.title" : "การวัดบริกซ์",
	"farm.brix.date" : "วันที่สำรวจ",
	"farm.brix.avg_brix" : "ค่าเฉลี่ย % Brix",
	"farm.brix.route" : "เส้นทาง",

	"farm.harvest_eval.title" : "ประเมินแปลงรถตัด",
	"farm.harvest_eval.doc_date" : "วันที่ประเมิน",
	"farm.harvest_eval.usage" : "การใช้งานรถตัด",
	"farm.harvest_eval.trailer" : "การเข้าออกรถพ่วง",
	"farm.harvest_eval.head_tail_road" : "ถนนหัวท้ายแปลง",
	"farm.harvest_eval.plant_groove" : "ลักษณะร่องปลูก",
	"farm.harvest_eval.plant_groove_len" : "ระยะร่องปลูก",
	"farm.harvest_eval.longest" : "ระยะแถวยาวที่สุด",
	"farm.harvest_eval.shortest" : "ระยะแถวสั้นที่สุด",
	"farm.harvest_eval.soil_type" : "ลักษณะดินในแปลง",
	"farm.harvest_eval.barrier" : "อุปสรรคในแปลง",
	"farm.harvest_eval.percent" : "เปอร์เซ็นต์อ้อย",
	"farm.harvest_eval.ton" : "ประเมินอ้อยในแปลง",

	"farm.growth.title" : "ใบงานตรวจการเจริญเติบโต",

	"farm.verify.title" : "ใบงานตรวจแปลง",

	"transportation.no" : "รหัสเที่ยวรถ",
	"transportation.vehicle" : "เบอร์รถบรรทุก",
	"transportation.start_date_time" : "ออกจากแปลง",
	"transportation.end_date_time" : "ถึงโรงงาน",
	"transportation.start_date" : "วันที่ออกจากแปลง",
	"transportation.start_time" : "เวลา",
	"transportation.end_date" : "วันที่ถึงโรงงาน",
	"transportation.end_time" : "เวลา",
	"transportation.distance" : "ระยะทาง",
	"transportation.duration" : "ระยะเวลา",
	"transportation.speed_avg" : "ความเร็วเฉลี่ย",
	"transportation.speed_max" : "ความเร็วสูงสุด",
	"transportation.field" : "แปลงขึ้นทะเบียน",
	"transportation.ton" : "จำนวนตันชั่ง",
	"transportation.ccs" : "CCS",
	"transportation.ton_from_type" : "ตันประมาณการจากประเภทรถ",

	"harvest.no" : "รหัสงาน",
	"harvest.vehicle" : "เบอร์รถตัด",
	"harvest.start_date_time" : "เริ่มตัด",
	"harvest.end_date_time" : "หยุดตัด",
	"harvest.start_date" : "วันที่เริ่มงาน",
	"harvest.start_time" : "เวลา",
	"harvest.end_date" : "วันที่สิ้นสุด",
	"harvest.end_time" : "เวลา",
	"harvest.distance" : "ระยะทางรวม",
	"harvest.duration" : "ระยะเวลารวม",
	"harvest.harvest_duration" : "ชม.การตัด",
	"harvest.speed_avg" : "ความเร็วเฉลี่ย",
	"harvest.speed_max" : "ความเร็วสูงสุด",
	"harvest.field" : "แปลงขึ้นทะเบียน",
	"harvest.ton" : "จำนวนตันที่ตัดได้",
	"harvest.progress": "%พื้นที่ตัดแล้ว",

	"plow.no" : "รหัสงาน",
	"plow.vehicle" : "เบอร์รถไถ",
	"plow.start_date_time" : "เริ่มไถ",
	"plow.end_date_time" : "หยุดไถ",
	"plow.start_date" : "วันที่เริ่มงาน",
	"plow.start_time" : "เวลา",
	"plow.end_date" : "วันที่สิ้นสุด",
	"plow.end_time" : "เวลา",
	"plow.distance" : "ระยะทางรวม",
	"plow.duration" : "ระยะเวลารวม",
	"plow.speed_avg" : "ความเร็วเฉลี่ย",
	"plow.speed_max" : "ความเร็วสูงสุด",
	"plow.field" : "แปลงขึ้นทะเบียน",
	"plow.plow_area" : "พื้นที่ไถ",
	"plow.plow_type" : "ประเภทการไถ",
	"plow.coords" : "พิกัดแปลง",

	"collateral.title": "หลักทรัพย์ค้ำประกัน",
	"collateral.title_farmer": "ข้อมูลผู้ขอค้ำประกัน",
	"collateral.title_collateral": "ข้อมูลหลักทรัพย์",
	"collateral.title_desc": "รายละเอียดหลักทรัพย์",
	"collateral.title_photo": "ภาพถ่าย",
	"collateral.title_attach": "เอกสารแนบ",
	"collateral.yearly": "ปีการหีบอ้อย",
	"collateral.farmer_name": "ชื่อ-สกุล",
	"collateral.farmer_phone": "เบอร์โทร",
	"collateral.doc_date": "วันที่ลงทะเบียน",
	"collateral.doc_no": "รหัสหลักทรัพย์",
	"collateral.collateral_type": "ประเภทหลักทรัพย์",
	"collateral.name": "ชื่อหลักทรัพย์",
	"collateral.owner_name": "ชื่อ-สกุลผู้ถือครอง",
	"collateral.card_no": "เลขที่บัตรประชาชน",
	"collateral.description": "รายละเอียด",
	"collateral.address_no": "ที่อยู่",
	"collateral.vil": "หมู่บ้าน",
	"collateral.region": "เขตตำบล",
	"collateral.brand": "ยี่ห้อ",
	"collateral.model": "รุ่น",
	"collateral.buy_price": "ราคาซื้อ",
	"collateral.regist": "จดทะเบียน",
	"collateral.regist_no": "เลขทะเบียน",
	"collateral.year": "ปีผลิต",
	"collateral.vtype": "ประเภท",
	"collateral.serial_no": "Serial No",
	"collateral.engine": "เลขเครื่อง",
	"collateral.chassis": "เลขตัวถัง",
	"collateral.color": "สี",
	"collateral.title_deed_no": "เลขที่โฉนด",
	"collateral.map_sheet": "ระวาง",
	"collateral.parcel_no": "เลขที่ดิน",
	"collateral.ref": "ข้อมูลเพิ่มเติม",
	"collateral.area": "เนื้อที่",
	"collateral.asset_price": "ราคาประเมิน",
	"collateral.net_amount": "วงเงินหลักทรัพย์",
	"collateral.volume": "วงเงินใช้ได้",
	"collateral.status.Signed": "อนุมัติ",
	"collateral.status.Rejected": "ไม่อนุมัติ",
	"collateral.status.Cancelled": "ยกเลิก",
	"collateral.status.Lent": "ยืมออก",
	"collateral.status.InStock": "นำเข้าที่ตั้ง",
	"collateral.status.WriteOff": "สูญหาย",

	"plan.doc_no" : "เลขที่",
	"plan.queue": "เลขคิว",
	"plan.field_no": "เลขแปลง",
	"plan.field_address": "ที่อยู่แปลง",
	"plan.field_distance": "ระยะทาง",
	"plan.field_coord": "พิกัดแปลง",
	"plan.date_range": "ช่วงวันที่",
	"plan.doc_date": "วันที่เปิดงาน",
	"plan.yearly": "ปีการผลิต",
	"plan.fee_info": "ค่าใช้จ่าย",
	"plan.harvester_rate": "ค่ารถตัดอ้อย",
	"plan.freight_rate": "ค่าบรรทุก",
	"plan.fuel_rate": "ค่าเติมน้ำมัน",
	"plan.harvester_price": "ค่ารถตัดอ้อย",
	"plan.truck_price": "ค่าบรรทุก",
	"plan.fuel": "เติมน้ำมัน",
	"plan.weight_in": "น.น เข้า",
	"plan.weight_out": "น.น ออก",
	"plan.weight_net": "น.น สุทธิ",
	"plan.produce_weight": "น้ำหนักอ้อย",
	"plan.vehicle_harvest2" : "รถตัด",
	"plan.vehicle_harvest" : "เบอร์รถตัด",
	"plan.vehicle_transport" : "ทะเบียนรถ",
	"plan.vehicle_tractorcablecard": "รถไถลาก-กระเช้า",
	"plan.vehicle_trailer" : "ทะเบียนพ่วง",
	"plan.trailer" : "พ่วง",
	"plan.tractor_no" : "รถไถลาก",
	"plan.cablecar" : "รถกระเช้า",
	"plan.use_with" : "ใช้กับ",
	"plan.farmer" : "ชื่อชาวไร่",
	"plan.farmer_phone" : "เบอร์โทรชาวไร่",
	"plan.farmer_phone2" : "เบอร์โทร",
	"plan.station" : "สถานที่รับอ้อย",
	"plan.station_hint" : "รับอ้อยที่แปลง",
	"plan.driver" : "ชื่อผู้ขับ",
	"plan.driver_owner" : "ผู้ขับ-เจ้าของรถ",
	"plan.truck_driver_phone" : "เบอร์โทร",
	"plan.truck_owner" : "เจ้าของรถ",
	"plan.start_date_time_harvest" : "กำหนดเริ่ม",
	"plan.start_date_time_transport" : "กำหนดเข้า",
	"plan.start_date_time_transport2" : "กำหนดเข้ารับอ้อย",
	"plan.actual_start_date_time_harvest" : "เริ่มงานจริง",
	"plan.actual_start_date_time_transport" : "เข้ารับจริง",
	"plan.end_date_time_harvest" : "กำหนดเสร็จ",
	"plan.actual_end_date_time_harvest" : "เสร็จงานจริง",
	"plan.progress": "% ตัดเสร็จ",
	"plan.status.Opened":"รับงาน",
	"plan.status.Draft":"งานใหม่",
	"plan.status.Closed":"จบงาน",
	"plan.status.Verified":"ตรวจสอบแล้ว",
	"plan.status.Failed":"ปฏิบัติงานไม่ได้",
	"plan.status.Cancelled":"ยกเลิก",
	"plan.status.Got":"เข้ารับอ้อย (รถตัด)",
	"plan.status.GotH":"เข้ารับอ้อย (กระเช้า)",

	"harvestplan.queue": "แปลงลำดับที่",
	"harvestplan.place" : "แปลงอ้อย",

	"transportplan.place" : "รับอ้อยมาจาก",

	"map.fields" : "แปลงขึ้นทะเบียน",
	"farmmonitoring.vehicle_role": "ชนิดรถ",
	"farmmonitoring.vehicles" : "รถ Online พร้อมปฏิบัติงาน",
	"farmmonitoring.fleet_category" : "ประเภทรถ",
	"farmmonitoring.channel" : "Channel รถขนส่ง",
	"farmmonitoring.zone" : "โซนรถตัด",
	"farmmonitoring.harvester": "แปลงรถตัด",
	"farmmonitoring.state": "การตรวจสอบ",
	"farmmonitoring.notify_range": "เวลาเกิดเหตุ",
	"farmmonitoring.notify_period": "ระยะเวลาเกิดเหตุ",
	"farmmonitoring.notify_location": "สถานที่เกิดเหตุ",
	"farmmonitoring.district": "ตำบล/แขวง",
	"farmmonitoring.city": "อำเภอ/เขต",
	"farmmonitoring.province": "จังหวัด",
	"farmmonitoring.lat": "ละติดจูด",
	"farmmonitoring.lng": "ลองจิจูด",
	"farmmonitoring.gps_area": "พื้นที่",

	"billgasoline.title" : "บิลน้ำมัน",
	"billgasoline.doc_no" : "เลขที่เอกสาร",
	"billgasoline.doc_date" : "วันที่",
	"billgasoline.doc_time" : "เวลา",
	"billgasoline.volume" : "จำนวนลิตร",
	"billgasoline.vehicle" : "เบอร์รถ",
	"billgasoline.fleet_category": "ประเภทรถ",

	"deliverysugarcane.title" : "ใบบันทึกส่งอ้อยในลานชั่ง",
	"deliverysugarcane.doc_no" : "เลขที่เอกสาร",
	"deliverysugarcane.doc_date" : "วันที่",
	"deliverysugarcane.doc_time" : "เวลา",
	"deliverysugarcane.volume" : "จำนวนตัน",
	"deliverysugarcane.vehicle" : "ทะเบียนรถ",

	"fleet_group.title" : "กลุ่มรถ (User)",
	"fleet_group.name" : "ชื่อกลุ่มรถ",
	"fleet_category.title" : "ประเภทรถ",
	"fleet_category.name" : "ชื่อประเภทรถ",
	"fleet_zone.code" : "รหัสโซนพื้นที่",
	"fleet_zone.text" : "ชื่อโซนพื้นที่รถ",
	"fleet_channel.code" : "รหัส Channel",
	"fleet_channel.text" : "ชื่อ Channel",
	"fleet_division.code" : "รหัสหน่วย",
	"fleet_division.text" : "ชื่อหน่วย",
	"bank.code" : "รหัสธนาคาร",
	"bank.text" : "ชื่อธนาคาร",

	"user.title" : "ผู้ใช้งานระบบ",
	"user.title_profile" : "ข้อมูลผู้ใช้งานระบบ",
	"user.title_authorized" : "สิทธิ์การใช้งาน",
	"user.username" : "Username",
	"user.password" : "รหัสผ่าน",
	"user.role" : "บทบาทในระบบ",
	"user.name" : "ชื่อ-สกุล",
	"user.email" : "อีเมลล์",
	"user.phone" : "เบอร์โทร",
	"user.info_group" : "สิทธิ์การใช้งานระบบ",
	"user.user_authorized" : "ระดับสิทธิ์ในระบบ Fleet",
	"user.group_ids" : "กลุ่ม (ดูรถเฉพาะกลุ่ม)",
	"user.division_ids" : "หน่วย (ดูรถเฉพาะหน่วย)",
	"user.role_hint" :  "00 : เข้าถึงทุกสิทธิ์ทุกเมนูรวมทั้งการตั้งค่ารถและUser<br/>10 : เข้าถึงทุกเมนู<br/>19 : จัดการข้อมูลแปลงพื้นที่,วางแผน,สั่งการ,ตรวจสอบ,ดูรายงาน,ติดตามรถ<br/>20 : จัดการข้อมูลแปลงพื้นที่<br/>30 : วางแผน,สั่งการ,ตรวจสอบ,ดูรายงาน,ติดตามรถ<br/>40 : ตรวจสอบ,ดูรายงาน,ติดตามรถ<br/>50 : ติดตามรถ",
	"user.user_authorized_hint" :  "Standard : แผนที่ Longdo<br/>Gold : แผนที่ดาวเทียม Google, POI<br/>Platinum : แผนที่ดาวเทียม Google, POI, แผนงาน, เส้นทาง, พื้นที่ Polygon",

	"ns.title" : "ลงทะเบียนการแจ้งเตือน",
	"ns.title_info" : "ตั้งค่าแจ้งเตือน",
	"ns.title_condition" : "เงื่อนไขเพิ่มเติม",
	"ns.notification_type":"การแจ้งเตือน",
	"ns.subscriber":"ช่องทางแจ้งเตือน",
	"ns.color":"ระดับ (สี)",
	"ns.notify_filter":"แจ้งเตือนเฉพาะ",
	"ns.notify_filter_hint":"แจ้งเตือนทุกรายการ",
	"ns.notify_key":"รหัสอ้างอิง",
	"ns.notify_key_hint":"รหัสกรองข้อมูลเฉพาะ",
	"ns.notify_to":"แจ้งเตือนไปยัง",
	"ns.notify_name":"ชื่อกลุ่ม (กรณี Line Notify)",
	"ns.notify_token":"Token ของผู้รับการแจ้งเตือน",
	"ns.msg":"ข้อความแจ้งเตือน",
	"ns.msg_hint":"ใช้ข้อความแจ้งเตือนของระบบ",
	"ns.msg_scheme":"ข้อความแจ้งเตือนของระบบ",

	"orgsetting.title":"ตั้งค่าระบบขององค์กร",
	"orgsetting.weight_avg_truck10": "น้ำหนักเฉลี่ยบรรทุกรถสิบล้อ (ตัน/เที่ยว)",
	"orgsetting.weight_avg_trailer": "น้ำหนักเฉลี่ยบรรทุกรถพ่วง (ตัน/เที่ยว)",
	"orgsetting.harvest_finish": "เปลี่ยนสถานะแปลงตัดเสร็จอัตโนมัติ ถ้า % ตัด GPS >= ",

	"ntk.title" : "กลุ่มแจ้งเตือน",
	"ntk.token_type":"ประเภท Token",
	"ntk.name":"ชื่อกลุ่ม/บุคคล",
	"ntk.token":"Token ของผู้รับการแจ้งเตือน",
	"ntk.status.A":"Active",
	"ntk.status.N":"Inactive",

	"custombox.notifications" : "การแจ้งเตือน",
	"custombox.historys" : "ประวัติย้อนหลัง",
	"custombox.map" : "แผนที่",
	"custombox.polygon_agriculture" : "แปลงปลูก",

	"information" : "ข้อมูล",
	"import": "นำเข้าข้อมูล",
	"import.file": "ไฟล์นำเข้า",

	"v_state.RUN" : "วิ่ง",
  "v_state.ON_RUN" : "กำลังวิ่ง",
  "v_state.ON_RUN_EXCEED" : "วิ่งเร็วเกิน",
  "v_state.ON_STOP" : "จอดติดเครื่อง",
  "v_state.OFF" : "จอดดับเครื่อง",
  "v_state.Offline" : "สัญญาณหาย",
	"v_state.NO_UPDATE" : "ไม่อัพเดท",
  "v_state.Unknown" : "ไม่ทราบสถานะ",

	"status.A": "Active",
	"status.N": "In-active",
	"status.Y": "Active",

	"warning.gps_offline_over":"การเชื่อมต่อขาดหายเกิน 7 วัน กรุณานำรถเข้าตรวจเช็ค",
	"notify.please_relogin_to_apply_change" : "เปลี่ยนแปลงข้อมูลการตั้งค่าเรียบร้อย กรุณาLogin ใหม่อีกครั้ง เพื่อใช้งานการตั้งค่าใหม่",

	"vehicle":"คัน",
	"machine":"เครื่อง",
	"text.field_address" : "ที่ตั้งแปลง",
	"text.district" : "ตำบล",
	"text.city" : "อำเภอ",
	"text.province" : "จังหวัด",
	"text.total_distance" : "ระยะทางรวม",
	"text.total_hour" : "ระยะเวลารวม",
	"text.total_work_hour" : "ชั่วโมงทำงานรวม",
	"text.speed_avg" : "ความเร็วเฉลี่ย",
	"text.speed_max" : "ความเร็วสูงสุด",
	"text.time_range_travel" : "ช่วงเวลาที่เดินทาง",
	"text.time_range_in_out_field" : "ช่วงเวลาที่เข้าออก-ออกแปลง",
	"text.please_specify_vehicle_datetime_range" : "กรุณาเลือกรถและช่วงเวลาที่ต้องการเรียกดูข้อมูล",
	"text.farmer_name" : "ชื่อเกษตรกร",
	"text.factory" : "โรงงาน",
	"text.ton" : "ตัน",
	"text.rai" : "ไร่",
	"text.km" : "กม",
	"text.hr" : "ชม",
	"text.meter" : "ม.",
	"text.kk" : "กก",
	"text.trunk" : "ลำ",
	"text.day" : "วัน",
	"text.field" : "แปลง",
	"text.round" : "เที่ยว",
	"text.person" : "ราย",
	"text.v" : "คัน",
	"text.liter" : "ลิตร",
	"text.baht" : "บาท",
	"text.work" : "งาน",
	"text.r" : "รอบ",
	"vehicle_name" : "ชื่อรถ",
	"no_vehicle_data": "ไม่พบรายการข้อมูลรถตามเงื่อนไขที่ระบุ",
	"on_route":"อยู่บนเส้นทาง",
	"unidentify_driver": "ผู้ขับไม่รูดบัตร"
}
