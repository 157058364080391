import {MagbizGeneral} from './Magbiz'

const utmObj = require('utm-latlng');
const utm=new utmObj(); //Default Ellipsoid is 'WGS 84'

export class Geo {

  static getPathFromPolygon(wkt) {
    let regex = /\(([^()]+)\)/g;
    let Rings = [];
    let results = regex.exec(wkt)
    while(results) {
        Rings.push(results[1])
        results = regex.exec(wkt)
    }
    let ptsArray=[];
    let polyLen=Rings.length;
    for(let i=0;i<polyLen;i++){
      let pointsData=Rings[i].split(",");
      let len=pointsData.length;
      for (let i=0;i<len;i++)
      {
        let xy=pointsData[i].trim().split(" ")
        let pt= {lat:parseFloat(xy[1]), lng:parseFloat(xy[0])}
        ptsArray.push(pt)
      }
    }
    if(ptsArray.length >= 3 && ptsArray[0].lat === ptsArray[ptsArray.length-1].lat && ptsArray[0].lng === ptsArray[ptsArray.length-1].lng){
      return ptsArray.slice(0,-1);
    }else{
      return ptsArray;
    }  
  }

  static getWktPolygonFromPath(points){
    let text = ""
    for(let i=0;i<points.length;i++){
      text += points[i].lng + " " +points[i].lat+ ","
    }
    if(text !== "" && points.length > 0){
      if(points[points.length-1].lng !== points[0].lng || points[points.length-1].lat !== points[0].lat){
        text += points[0].lng + " " +points[0].lat
      }else{
        text = text.substring(0, text.length-1)
      }
		}
    text = "POLYGON(("+text+"))"
    return text
  }

  static getCentroidPolygonPath(paths) {
     const pts = [...paths]
     let first = pts[0], last = pts[pts.length-1];
     if (first.lng !== last.lng || first.lat !== last.lat) pts.push(first);
     let twicearea=0,
     x=0, y=0,
     nPts = pts.length,
     p1, p2, f;
     for ( let i=0, j=nPts-1 ; i<nPts ; j=i++ ) {
        p1 = pts[i]; p2 = pts[j];
        f = p1.lng*p2.lat - p2.lng*p1.lat;
        twicearea += f;
        x += ( p1.lng + p2.lng ) * f;
        y += ( p1.lat + p2.lat ) * f;
     }
     f = twicearea * 3;
     return { lng:x/f, lat:y/f };
  }

  static formatXYLocation(lat, lng){
    if(lat === 0 || lng === 0) return ""
    const obj = utm.convertLatLngToUtm(lat, lng, 7)
    return "X="+MagbizGeneral.toMoney(obj.Easting,0)+" Y="+MagbizGeneral.toMoney(obj.Northing,0)+" ("+obj.ZoneNumber+obj.ZoneLetter+")"
  }

}
