import React from 'react';
import {CircularProgress} from '@material-ui/core';
import {useSelector} from 'react-redux';

const KTLoading = () => {

  const user_action = useSelector(state => state.user.action)

  if(user_action.logout){
    return (
      <div style={styles.container}>
        <div style={styles.centerSpan}>
          <CircularProgress size={24} style={styles.circle}/>
          <div style={styles.text}>Processing, Please wait...</div>
        </div>
      </div>
    )
  }else{
    return null
  }

}

export default KTLoading

const styles = {
  container: {
    position:'absolute',
    left:0,
    right:0,
    width:'100vw',
    height:'100vh',
    backgroundColor:'rgba(0,0,0,0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex:9999
  },
  centerSpan: {
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center'
  },
  circle: {
    color:'#fff'
  },
  text: {
    color:'#fff',
    marginTop:5
  },
};
