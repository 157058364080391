import React, {Fragment} from 'react';

import Icon, {
	AntDesign,
	Entypo,
	//EvilIcons,
	//Feather,
	FontAwesome,
	FontAwesome5,
	Foundation,
	Ionicons,
	MaterialIcons,
	MaterialCommunityIcons,
	//SimpleLineIcons,
	Octicons,
	//Zocial,
} from 'react-web-vector-icons';

export default function KTIcon(props) {
  return (
    <Fragment>
      {props.vicon && props.vicontype==="Ionicons" && <Ionicons name={props.vicon} size={props.size} color={props.color} />}
      {props.vicon && props.vicontype==="FontAwesome" && <FontAwesome name={props.vicon} size={props.size} color={props.color} />}
			{props.vicon && props.vicontype==="Foundation" && <Foundation name={props.vicon} size={props.size} color={props.color} />}
			{props.vicon && props.vicontype==="MaterialCommunityIcons" && <MaterialCommunityIcons name={props.vicon} size={props.size} color={props.color} />}
      {props.vicon && props.vicontype==="MaterialIcons" && <MaterialIcons name={props.vicon} size={props.size} color={props.color} />}
      {props.vicon && props.vicontype==="AntDesign" && <AntDesign name={props.vicon} size={props.size} color={props.color} />}
			{props.vicon && props.vicontype==="Octicons" && <Octicons name={props.vicon} size={props.size} color={props.color} />}
			{props.vicon && props.vicontype==="Entypo" && <Entypo name={props.vicon} size={props.size} color={props.color} />}
			{props.vicon && props.vicontype==="FontAwesome5" && <FontAwesome5 name={props.vicon} size={props.size} color={props.color} />}
      {props.icon && <Icon name={props.icon} font={props.icontype} size={props.size} color={props.color} />}
    </Fragment>
  )
}
