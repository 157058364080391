module.exports = {
	"lang" : "th",

	"button.login":"เข้าสู่ระบบ",
	"button.query":"เรียกดูข้อมูล",
	"button.upload":"อัพโหลด",
	"button.checking": "ตรวจสอบ",
	"button.verified": "ยืนยันข้อมูล",
	"button.import": "นำเข้าข้อมูล",
	"button.cancel": "ยกเลิก",
	"button.restore": "ดึงเอกสารกลับคืน",
	"status": "สถานะ",
	"status.All": "ทั้งหมด",
	"status.Active" : "Active",
	"status.Inactive" : "In-active",
	"status.Draft": "รอตรวจสอบ",
	"status.Checking": "กำลังตรวจสอบ",
	"status.Verified": "ยืนยันข้อมูล",
	"status.Signed": "ยืนยันข้อมูล",
	"status.Cancelled": "ยกเลิก",
	"report.data_date": "ข้อมูล ณ วันที่",

	"search_please_type" : "ค้นหา...",
	"search_not_found" : "ไม่พบข้อมูล...",
	"confirmation" : "ยืนยันการทำรายการ",
	"yes_confirm" : "ใช่, ยืนยัน",
	"no" : "ไม่",
	"question_confirm_save" : "ต้องการบันทึกข้อมูล ?",
	"question_confirm_cancel" : "ต้องการยกเลิกรายการนี้หรือไม่ ?",
	"question_confirm_delete" : "ต้องการลบรายการนี้หรือไม่ ?",
	"question_confirm_operation" : "การดำเนินการ",
	"question_confirm_operation_status": "เปลี่ยนสถานะเป็น",
	"error.incomplete_form" : "กรุณาระบุข้อมูลที่จำเป็นให้ครบถ้วน",

	"save": "บันทึก",
	"add": "เพิ่ม",
	"update": "อัพเดท",
	"cancel": "ยกเลิก",
	"print": "พิมพ์",

	"monthly" : "เดือน / ปี",
	"date":"วันที่",
	"time":"เวลา",
	"datetime":"วัน-เวลา",
	"from_date":"ตั้งแต่วันที่",
	"to_date":"ถึงวันที่",
	"from_time":"ตั้งแต่เวลา",
	"to_time":"ถึงเวลา",
	"year": "ปี",
	"day": "วัน",
	"hour": "ชม",
	"hr": "ชม",
	"min": "นาที",
	"cm" : "ซม",
	"second": "วินาที",
	"hour_abbr" : "ชม",
	"minute_abbr" : "น",
	"second_abbr" : "วิ",

	"district" : "แขวง/ตำบล",
	"city" : "เขต/อำเภอ",
	"province" : "จังหวัด",
	"portal" : "รหัสไปรษณีย์",

	"detail_of" : "ข้อมูล",
	"all":"ทั้งหมด",
	"type":"ประเภท",
	"username":"ชื่อผู้ใช้",
	"password":"รหัสผ่าน",

	"text.upto_minute" : "นาทีขึ้นไป",
	"text.minute" : "นาที",

	"coordinate":"พิกัด",
	"baht": "บาท",
	"before": "ก่อน",
	"now": "ณ ตอนนี้",
	"present": "ปัจจุบัน",
	"today": "วันนี้",
	"yesterday": "เมื่อวานนี้",
	"day_ago": "วันก่อน",
	"last_weekly": "1 สัปดาห์ล่าสุด",
	"last_monthly": "1 เดือนล่าสุด",
	"created_date": "วันที่สร้าง",
	"last_updated": "อัพเดทล่าสุด",
	"last_accessed": "เข้าใช้ล่าสุด",
	"description" : "รายละเอียด",
	"please_specify":"กรุณาระบุ",
	"not_specific":"ไม่ระบุ",
	"no_data": "ไม่พบรายการข้อมูล",
	"loading": "กำลังโหลดข้อมูล กรุณารอสักครู่...",
	"search": "ค้นหา",
	"remark" : "หมายเหตุ",
	"number" : "จำนวน",
	"entry" : "รายการ",
	"phone" : "เบอร์โทร",
	"sort_by": "เรียงลำดับ"
}
