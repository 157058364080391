import React, {Fragment, useEffect, useState, forwardRef} from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import KTIcon from './KTIcon';

import {MagbizGeneral} from '../../utils/Magbiz'
import {translate} from '../../lang';
import settings from '../../settings';

const KTSelect = forwardRef((props, ref) => {
  const { name, defaultValue, disabled, suggestions, isMulti, async, blankNotAllowed, placeholder,
  label, required, type,
  align = "left", no_outline, small, large,
  onChange } = props

  const [object, setObject] = useState(null)

  useEffect(() => {
    setObject(getObjectValue(defaultValue))
  }, [defaultValue, suggestions])

  const boxSize = (small ? 24 : large ? 48 : 36)
  const fontSize = (small ? 10 : large ? 18 : 13)
  const radius = (small ? 2 : large ? 8 : 4)

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: radius,
      border: no_outline ? '1px solid transparent' : state.isFocused ? '1px solid '+settings.theme.primary.main : '1px solid #ccc',
      ':hover': {
        border: no_outline ? '1px solid transparent' : '1px solid '+settings.theme.primary.main,
      },
      backgroundColor: settings.theme.mytheme && settings.theme.mytheme.input ? settings.theme.mytheme.input  : "transparent",
      boxShadow: "none",
      minHeight: 0,
      height: boxSize,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
      fontSize: fontSize,
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      justifyContent: align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center"
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: disabled || no_outline ? "none" : "flex"
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      marginTop: 0,
      marginBottom: 0,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#ccc',
      fontSize: fontSize,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? settings.theme.primary.main : 'white',
      color: state.isSelected ? 'white' : 'black',
      textAlign: align,
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: settings.theme.primary.dark,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
      fontSize: fontSize - 3,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#ccc',
      ':hover': {
        backgroundColor: settings.theme.primary.light,
        color: '#ccc',
      },
    }),
  }

  const getObjectValue = (ov) => {
    if(isMulti){
      if(!ov) return null
      const dvalues = ov.split("|")
      return suggestions.filter(item => MagbizGeneral.includes(dvalues, [item.value+""]))
    }else{
      const objVal = suggestions.find(item => item.value+"" === ov+"")
      if(objVal && objVal.text){
        return {...objVal, label:objVal.text}
      }else{
        return (typeof objVal === "undefined" ? null : objVal)
      }
    }
  }

  const onHandleChange = (objVal) => {
    let obj = objVal
    if(Array.isArray(objVal)){
      obj = {label:"", value:""}
      obj = objVal.reduce((olist, item) => {
        if(olist.value !== "") {
          olist.label += "|"
          olist.value += "|"
        }
        olist.label += item.label
        olist.value += item.value
        return olist
      }, obj)
    }
    if(obj){
      let event = {
        target:{
          name: name,
          value: obj.value,
          label: obj.label
        }
      }
      if(!blankNotAllowed || typeof obj.value !== "undefined"){
        setObject(objVal)
        onChange(event)
      }
    }else{
      let event = {
        target:{
          name: name,
          value: null,
          label: null
        }
      }
      setObject(null)
      onChange(event)
    }
  }

  const noOptionsMessage = ({ inputValue }) => {
    return inputValue ? translate("search_not_found") : async ? translate("search_please_type") : 'No options available';
  }

  const ClearIndicator = ({ inputValue }) => {
    if(inputValue){
      return (
        <div style={{paddingLeft:radius, paddingRight:radius}}>
          <KTIcon vicon="close" vicontype="AntDesign" size={fontSize-3} color="#ccc" />
        </div>
      )
    }else{
      return null
    }
  }

  const DropdownIndicator = ({ inputValue }) => {
    return (
      <div style={{paddingLeft:radius, paddingRight:radius}}>
        <KTIcon vicon="caretdown" vicontype="AntDesign" size={fontSize-3} color="#ccc" />
      </div>
    )
  }

  return (
    <Fragment>
      {!async &&
      <Select
        ref={ref}
        name={name}
        value={object}
        placeholder={placeholder}
        blankNotAllowed={blankNotAllowed}
        isClearable={!blankNotAllowed}
        isMulti={isMulti}
        styles={customStyles}
        options={suggestions}
        textFieldProps={{ label: label, required: required, type: type, }}
        noOptionsMessage={noOptionsMessage}
        components={{
          DropdownIndicator: DropdownIndicator,
          ClearIndicator : ClearIndicator,
        }}
        onChange={onHandleChange}
      />}
      {async &&
      <AsyncSelect
        ref={ref}
        name={name}
        value={object}
        placeholder={placeholder}
        blankNotAllowed={blankNotAllowed}
        isClearable={!blankNotAllowed}
        isMulti={isMulti}
        styles={customStyles}
        loadOptions={(inputValue, callback) => {
          if(suggestions){
            callback(suggestions.filter((item) => {
              const inputValues = inputValue.split(/\s+/)
              let result = false
              for(let k = 0;k < inputValues.length; ++k){
                if(inputValues[k] !== ""){
                  if(item.label.toLowerCase().includes(inputValues[k].toLowerCase())) {
                    result = true
                  }else{
                    return false
                  }
                }
              }
              return result
            }).slice(0, 50))
          }else{
            callback([])
          }
        }}
        textFieldProps={{ label: label, required: required, type: type, }}
        noOptionsMessage={noOptionsMessage}
        components={{
          DropdownIndicator: DropdownIndicator,
          ClearIndicator : ClearIndicator,
        }}
        onChange={onHandleChange}
      />}
    </Fragment>
  )

})

export default KTSelect
