module.exports = {
	"lang" : "th",

	"concrete_projects.title" : "โครงการ",
	"concrete_projects.customer_site" : "ไซด์งาน",
	"concrete_projects.produce" : "ยอดผลิตรวม",
	"concrete_projects.order" : "ยอดสั่งรวม",
	"concrete_projects.no" : "No",
	"concrete_projects.do_no" : "เลขที่ DO",
	"concrete_projects.plant" : "แพล้นท์",
	"concrete_projects.booking": "คิว",
	"concrete_projects.vehicle_no": "เลขรถ",
	"concrete_projects.amount":"จำนวน",
	"concrete_projects.booking_no":"Booking No",
	"concrete_projects.customer_name":"บริษัท",
	"concrete_projects.project_name" : "โครงการ",
	"concrete_projects.sale_name" : "Sale",
	"concrete_projects.sale_phone" : "เบอร์โทร",
	"concrete_projects.contact_site" : "ช่าง",
	"concrete_projects.mix_code" : "รหัสสินค้า",
	"concrete_projects.strength" : "Strength",
	"concrete_projects.slump" : "Slump",
	"concrete_projects.estimate_time": "วันที่-เวลา",
	"concrete_projects.produce_time": "เวลาผลิต",
	"concrete_projects.comment": "Description",
	"concrete_projects.plant_no": "แพล้นท์",
	"concrete_projects.viewonmap": "ดูบนแผนที่",

	"error.info_not_ready" : "ข้อมูลไม่พร้อมใช้งาน อาจเนื่องจากยังไม่ได้บันทึกข้อมูลพิกัดสถานที่ในระบบ"
}
