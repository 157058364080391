import React from 'react';
import { useBarcode } from '@createnextapp/react-barcode';

export default function KTBarcode(props) {

  if(!props.value) return null

  const { inputRef } = useBarcode({
    value: props.value,
    options: {
      width:1.6,                    //Width of single bar default is 2
      //format: "CODE128",        //Code128 by default
      height:(props.height ? props.height : 100),
      fontSize:(props.fontSize ? props.fontSize : 20),
      background: (props.bgColor ? props.bgColor : '#ffffff' ),
      margin:0,
      displayValue:false
    }
  });

  return <div style={{...{textAlign:"center"},...props.style}}>
    <img alt={props.value} ref={inputRef} />
    {props.title && !props.hidetitle && <div style={{fontSize:10}}>{props.value} ({props.title})</div>}
    {!props.title && !props.hidetitle && <div style={{fontSize:10}}>{props.value}</div>}
  </div>
};
