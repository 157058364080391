module.exports = {
	"sidebar.section.main_menu": "เมนูหลัก",
	"sidebar.dashboard": "Dashboard",
	"sidebar.report": "รายงาน",
	"sidebar.services": "Job Services",

	"service_type" : "Type of Service",
	"vendor" : "Vendor",
	"approve" : "อนุมัติ",
	"reject" : "ไม่อนุมัติ",
	"revise" : "Revise",
	"propose" : "เสนอราคา",

	"service.title_for_center" : "สำหรับ เจ้าหน้าที่ศูนย์บริการ",
	"service.title_sla" : "Service SLA",
	"service.detail": "Service Request Form",
	"service.service_type" : "Type of Service",
	"service.price" : "ราคาที่เสนอ",
	"service.cm_code" : "รหัสประเภทงาน",
	"service.br_code" : "BR No.",
	"service.rc_code" : "RC Code",
	"service.branch" : "สำนักงาน / สาขา",
	"service.rc_code_description" : "ศูนย์ความรับผิดชอบ",
	"service.category" : "Category (อาการเสีย)",
	"service.sub_category" : "Sub Category",
	"service.request_no" : "คำร้องเลขที่",
	"service.coordinator" : "ผู้ประสานงาน",
	"service.vendor" : "Vendor",
	"service.photo_service_job" : "Service Job",
	"service.photo_assessment" : "ใบประเมิน",
	"service.photo_before" : "ภาพก่อน",
	"service.photo_after" : "ภาพหลัง",
	"service.photo_qt" : "ใบเสนอราคา",
	"service.photo_request1" : "ภาพประกอบ 1",
	"service.photo_request2" : "ภาพประกอบ 2",
	"service.service_date" : "วันที่ขอเข้า",
	"service.service_technician" : "ชื่อช่าง",
	"service.service_technician_phone" : "เบอร์โทรช่าง",
	"service.equipment" : "ชื่ออุปกรณ์",
	"service.equipment_installation" : "จุดติดตั้งของอุปกรณ์",
	"service.equipment_symptom" : "อาการเสีย/ชำรุด หรือสิ่งที่ต้องการให้ดำเนินการ",
	"service.remark"  : "หมายเหตุ (สำหรับเจ้าหน้าที่ส่วนกลาง)",
	"service.remark_vendor"  : "หมายเหตุ (สำหรับเจ้าหน้าที่ศูนย์บริการ)",
	"service.status": "สถานะ",
	"service.status.All": "ทั้งหมด",
	"service.status.Draft": "เปิดงาน",
	"service.status.Proposed": "รออนุมัติใบเสนอราคา",
	"service.status.Approved": "รออนุมัติการจัดซื้อ",
	"service.status.ApprovedPO": "อนุมัติการจัดซื้อแล้ว",
	"service.status.Requested": "ขอเข้าพื้นที่",
	"service.status.Progress": "แจ้งความคืบหน้า",
	"service.status.Completed": "ปิดงาน",
	"service.status.Rejected": "ไม่อนุมัติใบเสนอราคา",
	"service.status.RejectedPO": "ไม่อนุมัติการจัดซื้อ",
	"service.status.Cancelled": "ยกเลิก",
	"service.created_time" : "ส่งคำร้อง",
	"service.arrival_time" : "แจ้งเข้าพื้นที่",
	"service.approved_time" : "อนุมัติ",
	"service.closed_time" : "ปิดงาน",
	"service.opened_date" : "วันที่เปิดงาน"
}
