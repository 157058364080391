import React from "react"
import { connect} from 'react-redux';
import {actionPerform as actionPerformApp} from '../../redux/App'

import {MagbizGeneral} from '../../utils/Magbiz'
import GMap from '../../utils/GMap'
import {Geo} from '../../utils/BGO'
import {store} from '../../App'

class MapContent extends React.Component {

  componentDidMount(){
    const me = this
    GMap.loadMap(this.props.mapContainerClass)
    GMap.setCallback("mapLoad", function(location){
      if(me.props.module.geos["field_working"]){
        GMap.drawPolygons(GMap.getMap(me.props.mapContainerClass), me.props.info[me.props.module.name], {group:"fields",hideLabel:true,...me.props.module.geoOptions})
      }else if(me.props.module.geos["field"]){
        GMap.drawPolygons(GMap.getMap(me.props.mapContainerClass), me.props.info[me.props.module.name], {group:"fields",hideLabel:true,...me.props.module.geoOptions})
      }else if(me.props.module.geos["poi_working"]){
        GMap.drawMarkers(GMap.getMap(me.props.mapContainerClass), me.props.info[me.props.module.name], {group:"pois",hideLabel:true, marker:me.props.module.geos["poi_working"].marker })
      }
    })
    GMap.setCallback("mapClick", function(location){
      if(store.getState().info.mode === "add"){
        if(me.props.module.geos["poi_working"]){
          GMap.addMarker(GMap.getMap(me.props.mapContainerClass), {id:0, lat:location.lat, lng:location.lng, draggable:true}, {group:"pois",hideLabel:true, marker:me.props.module.geos["poi_working"].marker})
          me.mappingPOIToField(location)
        }
      }
    })
    GMap.setCallback("mapRightClick", function(location){
      if(store.getState().info.mode === "add"){
        if(me.props.module.geos["field_working"]){
          GMap.clearData("field_working")
        }else if(me.props.module.geos["poi_working"]){
          GMap.clearData("poi_working")
        }
      }
    })
    GMap.setCallback("polygonCoordinatesChanged", function(polygon, obj){
      if(me.props.module.geos["field_working"]){
        me.mappingPolygonToField(polygon)
      }
    })
    GMap.setCallback("polygonClick", function(polygon, obj){
      //alert(obj.field_no)
    })
    GMap.setCallback("polygonDragEnd", function(polygon, obj){
      if(me.props.module.geos["field_working"]){
        me.mappingPolygonToField(polygon)
      }
    })
    GMap.setCallback("markerDragEnd", function(marker, obj, location){
      if(me.props.module.geos["poi_working"]){
        me.mappingPOIToField(location)
      }
    })
  }

  componentWillUnmount() {
    GMap.clearMapLoading(this.props.mapContainerClass)
    this.props.actionPerformApp("APP_GEO_CHANGE", {})
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props.module.geos["field_working"]){
      this.checkFieldWorking(nextProps)
    }else if(this.props.module.geos["field"]){
      this.checkField(nextProps)
    }else if(this.props.module.geos["poi_working"]){
      this.checkPOIWorking(nextProps)
    }
    return false
  }

  checkFieldWorking = (nextProps) => {
    const map = GMap.getMap(this.props.mapContainerClass)
    const me = this
    const detail_name = this.props.module.detail.name
    if(this.props.info[this.props.module.name] !== nextProps.info[this.props.module.name]){
      GMap.drawPolygons(map, nextProps.info[nextProps.module.name], {group:"fields", hideLabel:true,...me.props.module.geoOptions})
    }
    if(this.props.info.mode !== nextProps.info.mode){
      if(nextProps.info.mode === "edit" && !Boolean(nextProps.info[detail_name][me.props.module.geos["field_working"].name])){
        this.setDrawingMode("add")
      }else{
        this.setDrawingMode(nextProps.info.mode)
      }
      if(nextProps.info[detail_name] && nextProps.info.mode === "edit"){
        GMap.removePolygon(nextProps.info[detail_name], {group:"fields"})
        GMap.addPolygon(map, {...nextProps.info[detail_name], editable: true}, {group:"fields", hideLabel:true})
      }else if(this.props.info[detail_name] && this.props.info.mode === "edit"){
        GMap.removePolygon(this.props.info[detail_name], {group:"fields"})
        GMap.addPolygon(map, this.props.info[detail_name] , {group:"fields", hideLabel:true})
      }
    }
    if(this.props.info[detail_name] !== nextProps.info[detail_name]){
      if(nextProps.info[detail_name]){
        me.props.actionPerformApp("APP_GEO_CHANGE", {...me.props.app.geos,
          [me.props.module.geos["field_working"].name]: me.getMappingValue(nextProps.info[detail_name], "field_working") ,
          [me.props.module.geos["field_working_area"].name]: me.getMappingValue(nextProps.info[detail_name], "field_working_area"),
          [me.props.module.geos["field_working_lat"].name]: me.getMappingValue(nextProps.info[detail_name], "field_working_lat"),
          [me.props.module.geos["field_working_lng"].name]: me.getMappingValue(nextProps.info[detail_name], "field_working_lng"),
          [me.props.module.geos["field_working_address_no"].name]: me.getMappingValue(nextProps.info[detail_name], "field_working_address_no"),
          [me.props.module.geos["field_working_district"].name]: me.getMappingValue(nextProps.info[detail_name], "field_working_district"),
          [me.props.module.geos["field_working_city"].name]: me.getMappingValue(nextProps.info[detail_name], "field_working_city"),
          [me.props.module.geos["field_working_province"].name]: me.getMappingValue(nextProps.info[detail_name], "field_working_province"),
          //[me.props.module.geos["field_working_portal"].name]: me.getMappingValue(nextProps.info[detail_name], "field_working_portal")
        })
        setTimeout(function(){
          GMap.mapSetBound(map, Geo.getPathFromPolygon(nextProps.info[detail_name][me.props.module.geos["field_working"].name]))
        }, 500)
      }
    }
  }

  checkField = (nextProps) => {
    const map = GMap.getMap(this.props.mapContainerClass)
    const me = this
    const detail_name = this.props.module.detail.name
    if(this.props.info[this.props.module.name] !== nextProps.info[this.props.module.name]){
      GMap.drawPolygons(map, nextProps.info[nextProps.module.name], {group:"fields",hideLabel:true,...me.props.module.geoOptions})
    }
    if(this.props.info[detail_name] !== nextProps.info[detail_name]){
      if(nextProps.info[detail_name]){
        setTimeout(function(){
          GMap.mapSetBound(map, Geo.getPathFromPolygon(nextProps.info[detail_name][me.props.module.geos["field"].name]))
        }, 500)
      }
    }
  }

  checkPOIWorking = (nextProps) => {
    const map = GMap.getMap(this.props.mapContainerClass)
    const me = this
    const detail_name = this.props.module.detail.name
    const markerOption = this.props.module.geos["poi_working"].marker
    if(this.props.info[this.props.module.name] !== nextProps.info[this.props.module.name]){
      GMap.drawMarkers(map, nextProps.info[nextProps.module.name], {group:"pois", hideLabel:true, marker:markerOption})
    }
    if(this.props.info.mode !== nextProps.info.mode){
      map.setOptions({draggableCursor:(nextProps.info.mode === "add" ? "crosshair" : "default" )})
      if(nextProps.info[detail_name] && nextProps.info.mode === "edit"){
        GMap.removeMarker(nextProps.info[detail_name], {group:"pois"})
        GMap.addMarker(map, {...nextProps.info[detail_name], draggable: true }, {group:"pois", marker:markerOption})
      }else if(this.props.info[detail_name] && this.props.info.mode === "edit"){
        GMap.removeMarker(this.props.info[detail_name], {group:"pois"})
        GMap.addMarker(map, this.props.info[detail_name], {group:"pois", marker:markerOption})
      }
    }
    if(this.props.info[detail_name] !== nextProps.info[detail_name]){
      if(nextProps.info[detail_name]){
        me.props.actionPerformApp("APP_GEO_CHANGE", {...me.props.app.geos,
          [me.props.module.geos["poi_working_lat"].name]: me.getMappingValue(nextProps.info[detail_name], "poi_working_lat"),
          [me.props.module.geos["poi_working_lng"].name]: me.getMappingValue(nextProps.info[detail_name], "poi_working_lng"),
          [me.props.module.geos["poi_working_address_no"].name]: me.getMappingValue(nextProps.info[detail_name], "poi_working_address_no"),
          [me.props.module.geos["poi_working_district"].name]: me.getMappingValue(nextProps.info[detail_name], "poi_working_district"),
          [me.props.module.geos["poi_working_city"].name]: me.getMappingValue(nextProps.info[detail_name], "poi_working_city"),
          [me.props.module.geos["poi_working_province"].name]: me.getMappingValue(nextProps.info[detail_name], "poi_working_province"),
          [me.props.module.geos["poi_working_portal"].name]: me.getMappingValue(nextProps.info[detail_name], "poi_working_portal")
        })
        setTimeout(function(){
          GMap.mapSetCenter(map, nextProps.info[detail_name][me.props.module.geos["poi_working_lat"].name], nextProps.info[detail_name][me.props.module.geos["poi_working_lng"].name])
        }, 500)
      }
    }
  }

  setDrawingMode = (mode) => {
    const me = this
    GMap.setDrawingMode(GMap.getMap(this.props.mapContainerClass), mode, function(polygon){
       me.mappingPolygonToField(polygon)
    })
  }

  getMappingValue = (detail, key) => {
    if(detail){
      return detail[this.props.module.geos[key].name]
    }else{
      return ""
    }
  }

  mappingPolygonToField = (polygon) => {
    const me = this
    const pts = GMap.getPolygonPoints(polygon)
    const centroid = Geo.getCentroidPolygonPath(pts)
    me.props.actionPerformApp("APP_GEO_CHANGE", {...me.props.app.geos,
      [me.props.module.geos["field_working"].name]:Geo.getWktPolygonFromPath(pts),
      [me.props.module.geos["field_working_area"].name]:MagbizGeneral.round(GMap.calculatePolygonArea(polygon)/1600,3),
      [me.props.module.geos["field_working_lat"].name]:MagbizGeneral.round(centroid.lat,7),
      [me.props.module.geos["field_working_lng"].name]:MagbizGeneral.round(centroid.lng,7),
    })
    GMap.reverseGeoCoding(centroid.lat, centroid.lng, function(addr){
      if(addr){
        me.props.actionPerformApp("APP_GEO_CHANGE", {...me.props.app.geos,
          [me.props.module.geos["field_working_address_no"].name]:addr.address_no,
          [me.props.module.geos["field_working_district"].name]:addr.district,
          [me.props.module.geos["field_working_city"].name]:addr.city,
          [me.props.module.geos["field_working_province"].name]:addr.province,
          //[me.props.module.geos["field_working_portal"].name]:addr.portal
        })
      }
    })
  }

  mappingPOIToField = (location) => {
    const me = this
    me.props.actionPerformApp("APP_GEO_CHANGE", {...me.props.app.geos,
      [me.props.module.geos["poi_working_lat"].name]:MagbizGeneral.round(location.lat,7),
      [me.props.module.geos["poi_working_lng"].name]:MagbizGeneral.round(location.lng,7)
    })
    GMap.reverseGeoCoding(location.lat, location.lng, function(addr){
      if(addr){
        me.props.actionPerformApp("APP_GEO_CHANGE", {...me.props.app.geos,
          [me.props.module.geos["poi_working_address_no"].name]: addr.address_no,
          [me.props.module.geos["poi_working_district"].name]: addr.district,
          [me.props.module.geos["poi_working_city"].name]: addr.city,
          [me.props.module.geos["poi_working_province"].name]: addr.province ,
          [me.props.module.geos["poi_working_portal"].name]:addr.portal
        })
      }
    })
  }

  render() {
    //console.log("Render GoogleMap ", this.props.mapContainerClass)
    return (
      <div className={this.props.mapContainerClass} style={{ height: "100%", width: "100%" }}>
      </div>
    )
  }
}

export default connect(({app, info}) => {
	return {app, info};
}, {
  actionPerformApp
})(MapContent)
