import React,{useRef, useState} from "react";
import {useSelector} from 'react-redux';
import {Grid, CircularProgress} from '@material-ui/core';

import {TableInfLoaderList, GoogleMapViewerV2, DocDefaultContent, KTSelectField} from '../../KT'
import {MagbizGeneral, MagbizUI} from '../../utils/Magbiz'
import {useInfoOperation, useInfo, useApp, useLocalStorage} from '../../utils/MagbizHook'
import {translate} from '../../lang'
import settings from '../../settings'

//customBox properties
//size = ขนาดของ Box แนวตั้งแบ่งเป็น 3 ส่วน แนวนอนแบ่งเป็น 4 ส่วน ของหน้าจอ
//size.v = (ความสูงหน้าจอ Content)/3
//size.h = (ความกว้างหน้าจอ Content)/4
//fullbox = มี Header หรือไม่ ถ้า fullbox คือไม่มี header
//parentContentWidth = ขนาดความกว้างหน้าจอ Parent ของ Box ถ้าไม่ระบุจะใช้ความกว้างของหน้าจอเต็ม (ความกว้าง Window)
//parentContentHeight = ขนาดความกว้างหน้าจอ Parent ของ Box ถ้าไม่ระบุจะใช้ความสูงของหน้าจอเต็ม (ความสูง Window - appBarSize*2)

export default function KTWCustomBox(props){

  const performInfoOperation = useInfoOperation()
  const performInfo = useInfo()
  const performApp = useApp()
  const storage = useLocalStorage()
  const boxRef = useRef()

  const activity = props.activity
  const appinterface = useSelector(state => state.app.interface)
  const customBox = appinterface[props.activity+"-cbx"][props.id]
  const [loading, setLoading] = useState(false)

  const onFilterChange = (event, index) => {
    setLoading(true)
    customBox.filter[index].value = event.target.value
    performApp("APP_SETUP_INTERFACE", appinterface)
    storage.set(activity+"-"+props.id+"-"+customBox.filter[index].name, event.target.value)
    if(customBox.performFilterChange){
      setLoading(false)
      customBox.performFilterChange(event.target.value)
    }else{
      MagbizGeneral.delay(() => {
        setLoading(false)
      }, 200)
    }
  }

  const onClose = (event) => {
    performInfo("INFO_CLEAR", {fdata:activity+"_"+customBox.form})
    if(customBox.performClear){
      customBox.performClear()
    }
  }

  const onClickSubmit = (event) => {
    setLoading(true)
    performInfoOperation({action:customBox.actionSave,  body:data, fdata:activity+"_"+customBox.form, callback: success => {
      setLoading(false)
      if(success){
        const parentBox = appinterface[props.activity+"-cbx"][customBox.parentId]
        if(parentBox){parentBox.ref.forceUpdateGrid()}
        onClose()
        if(customBox.performAfterSave){
          customBox.performAfterSave(data, props.params)
        }
      }
    }})
  }

  const onRowClick = (id, item, index) => {
    const {offsetLeft, offsetTop} = boxRef.current
    if(customBox.performItemSelected){
      for (const [k,v] of Object.entries(customBox.performItemSelected(item))) {
        if(k === "cbx"){
          for (const [cbxid,obj] of Object.entries(v)) {
            for (const [k1,v1] of Object.entries(obj)) {
              appinterface[props.activity+"-cbx"][cbxid][k1] = v1
            }
          }
        }else{
          customBox[k] = v
        }
      }
      performApp("APP_SETUP_INTERFACE", appinterface)
    }
    if(props.detailBoxId){
      const detailBox = appinterface[props.activity+"-cbx"][props.detailBoxId]
      detailBox.boxStyle = {position:"absolute", top:offsetTop-2, left:offsetLeft-1, zIndex:9999, border:"1px solid "+settings.theme.primary.dark, boxShadow:"4px 3px 9px -4px rgba(0,0,0,0.75)"}
      detailBox.parentId = props.id
      if(detailBox.performLoad){
        for (const [k,v] of Object.entries(detailBox.performLoad(item))) {
          if(k === "cbx"){
            for (const [cbxid,obj] of Object.entries(v)) {
              for (const [k1,v1] of Object.entries(obj)) {
                appinterface[props.activity+"-cbx"][cbxid][k1] = v1
              }
            }
          }else{
            detailBox[k] = v
          }
        }
      }
      performApp("APP_SETUP_INTERFACE", appinterface)
    }
  }

  const onValueChange = (name, value) => {
    data[name] = value
    performInfo("INFO_UPDATE", {fdata:activity+"_"+customBox.form, info:data})
  }

  const dynamicRowHeight = ({index}) => {
    if(props.data[index].lines){
      return (props.data[index].lines * 15) + 16
    }else{
      return (customBox.rowHeight ? customBox.rowHeight : 45)
    }
  }

  const searchData = (item, search_text) => {
    if(customBox.searchable && search_text !== ""){
      for(let i=0;i<customBox.searchable.length;++i){
        const sitem = item[customBox.searchable[i]]
        if(typeof sitem === "object"){
          const ssitem = sitem[customBox.searchable[i]]
          if(ssitem.toLowerCase().includes(search_text.toLowerCase())){
            return true
          }
        }else if(typeof sitem === "string"){
          if(sitem.toLowerCase().includes(search_text.toLowerCase())){
            return true
          }
        }
      }
      return false
    }
    return true
  }
  
  const headerStyle = {
    height:(customBox.fullbox ? 0 : 32),
    backgroundColor: settings.theme.primary.dark,
    alignItems:"center",
    paddingRight:5,
    paddingLeft:5
  }
  const headerText = (customBox.headerText ? customBox.headerText : translate("custombox."+customBox.form))
  const column = [{name : "detail", label:"", width:100, cellContentRenderer:(obj) => (customBox.renderItem ? customBox.renderItem(obj, {filter:customBox.filter}) : null) }]
  const section = (customBox.section ? [ ...customBox.section, ...[{ fieldStyle: "document", compact:true, marginBottom:0, marginTop:0, marginLR:10, noPadding:true,
      fields : {
        save : { name:"save", type:"button", xs:12, text:translate("save"), value:"", height:28, style:{fontSize:12, lineHeight:1.2, backgroundColor:"#229954"}, onClick:onClickSubmit },
      }
    }]
  ] : null)

  let data = props.data
  if(customBox.filter && data){
    data = data.filter((item) => {
      let filtered = true
      for (const filter of customBox.filter) {
        if(item[filter.name]+"" !== filter.value+"" && filter.value+"" !== "All") return false
      }
      return filtered
    })
    if(customBox.searchable){
      data = data.filter(item => {
        return searchData(item, appinterface.search_text)
      })
    }
  }

  let conditionChosen = true
  if(customBox.filter){
    conditionChosen = false
    for (const filter of customBox.filter) {
      if(filter.value !== "") conditionChosen = true
    }
  }

  return (
    <Grid item xs={12} md={3*customBox.size.h}  style={customBox.boxStyle} >
      <div ref={boxRef} >
        {!customBox.fullbox &&
        <Grid container direction="row" justify="space-between" style={headerStyle}>
          <Grid item xs={(customBox.fullHeader ? 12 : (customBox.filter ? 3 : 10))} >
            <div style={{fontSize:11, fontWeight:"bold", color:"white", lineHeight:1.1}}>{headerText}</div>
          </Grid>
          {customBox.filter && customBox.filter.map((item, index) => {
            return (
              <Grid key={index} item xs={item.xs} >
                <KTSelectField name={props.id+"-"+item.name+"-filter"} placeholder={"Please choose"}
                  defaultValue={item.value} blankNotAllowed={true} suggestions={item.list}
                  onChange={(e) => onFilterChange(e, index)} small no_outline light  />
              </Grid>
            )
          })}
          {data && data.length > 0 && !customBox.fullHeader &&
          <Grid item xs={1} style={{textAlign:"right"}}>
            <div style={{fontSize:10, color:"white"}}>{(data ? "["+MagbizGeneral.toMoney(data.length,0)+"]" : "")}</div>
          </Grid>}
          {customBox.closeButton && !customBox.fullHeader &&
          <Grid item xs={1} style={{textAlign:"right"}} onClick={onClose}>
            <div style={{fontSize:12, fontWeight:"bold", color:"white", paddingRight:5, cursor:"pointer", title:"Close"}}>{"X"}</div>
          </Grid>}
        </Grid>}
        <CustomBox id={props.id} mode={"edit"} data={data} section={section} column={column} customBox={customBox}
          headerHeight={headerStyle.height} rowHeight={(customBox.rowHeight ? customBox.rowHeight : 45)}
          conditionChosen={conditionChosen} loading={loading} dynamicRowHeight={(customBox.rowHeight ? null : dynamicRowHeight)}
          onRowClick={(item, index) => onRowClick(props.id, item, index)}
          onValueChange={onValueChange} />
      </div>
    </Grid>
  )
}

const CustomBox = (props) => {
  const appscreen = useSelector(state => state.app.screen)

  const cH = (props.customBox.parentContentHeight ? props.customBox.parentContentHeight : (appscreen.height - appscreen.appBarSize.height*2))
  const cW = (props.customBox.parentContentWidth ? props.customBox.parentContentWidth : appscreen.width)

  const blockHeight = ((cH/3) * props.customBox.size.v) - (props.headerHeight ? props.headerHeight : 0)
  const blockWidth = (cW/4) * props.customBox.size.h
  const blockStyle = {
    outline:"1px solid #f2f2f2",
    width:blockWidth,
    height:blockHeight,
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    backgroundColor:props.customBox.theme.bgColor
  }

  if(props.customBox.uitype === "list" && MagbizGeneral.isEmpty(props.data)){
    return (
      <Grid item xs={12} style={blockStyle}>
        <span style={{fontSize:"0.8em", color:"grey"}}>Loading, Please wait...</span>
      </Grid>
    )
  }else if(props.customBox.uitype === "list" && !props.conditionChosen){
    return (
      <Grid item xs={12} style={blockStyle}>
        <span style={{fontSize:"0.8em", color:"#C5C8CD"}}>Please choose your condition</span>
      </Grid>
    )
  }else if(props.customBox.uitype === "list" && !MagbizGeneral.isEmpty(props.data) && props.data.length === 0){
    return (
      <Grid item xs={12} style={blockStyle}>
        <span style={{fontSize:"0.8em", color:"#912837"}}>{translate("no_data")}</span>
      </Grid>
    )
  }

  let fields = {}
  if(props.customBox.uitype === "document"){
    fields = MagbizUI.fetchFieldsValue(props.section, props.data)
    blockStyle.justifyContent = "flex-start"
  }

  const onFieldChange = (event) => {
    if(props.onValueChange) props.onValueChange(event.target.name, event.target.value)
  }

  const onDateTimeChange = (dateObj) => {
    if(props.onValueChange) props.onValueChange(dateObj.name, dateObj.value)
  }

  const onFieldSelect = (name, selectId) => {
    if(props.onValueChange) props.onValueChange(name, selectId)
  }

  const onFileUploadImageChange = (name, filepathurl, filepath) => {
    if(props.onValueChange) props.onValueChange(name, filepath)
  }

  const onRegisterRefList = (ref) => {
    props.customBox.ref = ref
  }

  if(props.loading){
    return (
      <Grid item xs={12} style={{...blockStyle, justifyContent:"center"}} >
        <CircularProgress size={24} style={{color:props.customBox.theme.sepColor}}/>
        <div style={{fontSize:12, color:props.customBox.theme.mainTextColor}}>Please wait...</div>
      </Grid>
    )
  }

  return (
    <Grid item xs={12} style={blockStyle} >
      {props.customBox.uitype === "list" &&
        <TableInfLoaderList
          column={props.column}
          rows={props.data}
          rowHeight={props.rowHeight}
          width={blockWidth}
          height={blockHeight - 2}
          dynamicRowHeight={props.dynamicRowHeight}
          theme={props.customBox.theme}
          onRowClick={props.onRowClick}
          onRegisterRefList={onRegisterRefList}
        />}
      {props.customBox.uitype === "map" &&
      <GoogleMapViewerV2 width={blockWidth} height={blockHeight - 2}
          mapContainerClass={props.id+'-MapContainer'} focus={props.focus} center={props.customBox.center}
          data={props.data} onMapLoaded={props.customBox.onMapLoaded} />}
      {props.customBox.uitype === "document" && <div style={{width:"100%", height:blockHeight, overflow:"auto"}}>
        {props.section && props.section.map((groupObj, groupIndex) => {
          return (
            <DocDefaultContent key={groupIndex} groupObj={groupObj} width={blockWidth} mode={props.mode} fields={fields}
              onFieldChange={onFieldChange}
              onDateTimeChange={onDateTimeChange}
              onFieldSelect={onFieldSelect}
              onFileUploadImageChange={onFileUploadImageChange}
            />
          )
        })}
      </div>}
    </Grid>
  )
}
