import React,{useState,useEffect} from "react"
import {useSelector} from 'react-redux';

import {MagbizGeneral, MagbizDateTime} from '../../utils/Magbiz'
import {useApp} from '../../utils/MagbizHook'
import GMap from '../../utils/GMap'
import {Geo} from '../../utils/BGO'
import settings from '../../settings'

export default function GoogleMapViewerV2(props){

  const [map, setMap] = useState(null)
  const [toggleZoom, setToggleZoom] = useState("")
  const [showLocation, setShowLocation] = useState("hide")
  const [showPOI, setShowPOI] = useState("hide")
  const [showZone, setShowZone] = useState("hide")
  const [showField, setShowField] = useState("show")
  const geos = useSelector(state => state.app.geos)
  const performApp = useApp()

  useEffect(() => {
    GMap.setCallback("mapLoad", function(m){
      setMap(m)
      if(props.onMapLoaded) props.onMapLoaded()
    })
    GMap.setCallback("mapClick", function(location){
      if(props.onMapClick) props.onMapClick(location)
    })
    GMap.setCallback("mapRightClick", function(location){

    })
    GMap.setCallback("markerClick", function(mobj, obj){
      //alert(obj.marker.label)
    })
    GMap.setCallback("mapZoomOrCenterChanged", function(oldLocation, location, distance, oldZoom, zoom){
      setToggleZoom(MagbizGeneral.generateRandomString(10))
      if(props.onMapZoomOrCenterChanged) props.onMapZoomOrCenterChanged(oldLocation, location, distance, oldZoom, zoom)
    })
    GMap.setCallback("polygonCoordinatesChanged", function(polygon, obj){
      mappingPolygonToField(polygon)
    })
    GMap.setCallback("polygonClick", function(polygon, obj){
      //alert(obj.field_no)
    })
    GMap.setCallback("polygonDragEnd", function(polygon, obj){
      mappingPolygonToField(polygon)
    })
    setMap(GMap.loadMap(props.mapContainerClass))

    return () => {
      GMap.clearMapLoading(props.mapContainerClass)
    }
  }, [])

  useEffect(() => {
    if(props.width && props.height){
      GMap.resize(map)
    }
  },[props.width, props.height])

  useEffect(() => {
    checkVehicle()
  },[props.data.vehicles])

  useEffect(() => {
    checkField(true)
  },[props.data.fields])

  useEffect(() => {
    checkPOI(true)
  },[props.data.pois])

  useEffect(() => {
    checkLocation(true)
  },[props.data.locations])

  useEffect(() => {
    checkZone(true)
  },[props.data.zones])

  useEffect(() => {
    if(props.data.hdata){
      drawVehicleRouteHistory(props.data.hdata)
    }else{
      drawVehicleRouteHistory([])
    }
    checkVehicle()
  },[props.data.hdata, props.data.notification])

  useEffect(() => {
    if(props.data.rpaths){
      drawRoutePath(props.data.rpaths)
    }else{
      drawRoutePath([])
    }
  },[props.data.rpaths])

  useEffect(() => {
    if(props.working){
      drawWorkingFields([{...props.working, forceColor:true, color:"#EAC627"}], {...props.data.options, ...props.data.fields_option})
    }else{
      drawWorkingFields([])
    }
  },[props.working])

  useEffect(() => {
    if(map && props.focus){
      setTimeout(function(){
        GMap.mapSetBound(map, props.focus)
      }, 100)
    }
  },[props.focus])

  useEffect(() => {
    if(map && props.center){
      setTimeout(function(){
        GMap.mapSetCenter(map, props.center.lat, props.center.lng)
        if(GMap.mapGetZoom(map) < 14){
          GMap.mapSetZoom(map, 14)
        }
      }, 100)
    }
  },[props.center])

  useEffect(() => {
    drawPlanHarvests(props.plan_harvest, {...props.data.options})
  },[props.plan_harvest])

  useEffect(() => {
    setDrawingMode(props.drawingMode)
    if(props.working){
      drawWorkingFields([{...props.working, forceColor:true, color:"#EAC627", editable:(props.drawingMode === "edit" ? true : false)}], {...props.data.options, ...props.data.fields_option})
    }
  },[props.drawingMode])

  useEffect(() => {
    checkPOI()
    checkField()
    checkLocation()
    checkZone()
    checkVehicle()
  },[toggleZoom])

  const checkVehicle = () => {
    if(!props.data.hdata){
      const option = {
        textBorder:"white",
        textBorderRadius:2,
        textBg:"rgba(0, 0, 0, 0.4)",
        textColor:"white"
      }
      if(map && GMap.mapGetZoom(map) >= 18){
        drawVehicles(props.data.vehicles, {...props.data.options, ...option} )
      }else{
        drawVehicles(props.data.vehicles, {...props.data.options, ...option, hideLabel:true})
      }
    }else{
      drawVehicles([])
    }
  }

  const checkPOI = (force) => {
    if(!map) return
    if(GMap.mapGetZoom(map) >= 12 || props.alwaysShowPOI){
      if(GMap.mapGetZoom(map) >= 18 && (showPOI !== "show" || force)){
        drawPOIs(props.data.pois, {...props.data.options})
        setShowPOI("show")
      }else if(GMap.mapGetZoom(map) < 18 && (showPOI !== "showHideLabel" || force)){
        drawPOIs(props.data.pois, {...props.data.options, hideLabel:true})
        setShowPOI("showHideLabel")
      }
    }else if(showPOI !== "hide" || force){
      drawPOIs([])
      setShowPOI("hide")
    }
  }

  const checkLocation = (force) => {
    if(!map) return
    if(GMap.mapGetZoom(map) >= 12 || props.alwaysShowLocation){
      if(GMap.mapGetZoom(map) >= 16 && (showLocation !== "show" || force)){
        drawLocations(props.data.locations, {...props.data.options, ...props.optionsLocation})
        setShowLocation("show")
      }else if(GMap.mapGetZoom(map) < 16 && (showLocation !== "showHideLabel" || force)){
        drawLocations(props.data.locations, {...props.data.options, ...props.optionsLocation, hideLabel:true})
        setShowLocation("showHideLabel")
      }
    }else if(showLocation !== "hide" || force){
      drawLocations([])
      setShowLocation("hide")
    }
  }

  const checkZone = (force) => {
    if(!map) return
    if(GMap.mapGetZoom(map) < 14){
      if(showZone !== "show" || force){
        drawZones(props.data.zones, {...props.data.options,...props.data.zones_option})
        setShowZone("show")
      }
    }else if(showZone !== "hide" || force){
      drawZones([])
      setShowZone("hide")
    }
  }

  const checkField = (force) => {
    if(!map) return
    if(GMap.mapGetZoom(map) >= 18 && (showField !== "show" || force)){
      drawFields(props.data.fields, {...props.data.options, ...props.data.fields_option})
      setShowField("show")
    }else if(GMap.mapGetZoom(map) < 18 && (showField !== "showHideLabel" || force)){
      drawFields(props.data.fields, {...props.data.options,...props.data.fields_option, hideLabel:true})
      setShowField("showHideLabel")
    }
  }

  const drawPlanHarvests = (items, options) => {
    if(map && items){
      let oldItem = null
      const ditems =  items.map((item, index) => {
        let distance = ""
        if(oldItem){
          distance = " ("+MagbizGeneral.toMoney(GMap.calculateDistance(oldItem.lat, oldItem.lng, item.lat, item.lng)/1000,1)+" km)"
        }
        item.marker = {
          label: (item.queue ? ""+item.queue+" " : "" )+distance+"",
          image: window.location.origin+"/assets/"+settings.name+"/img/markers/calendar-3.png",
          width: 32,
          height: 37,
          scale: 0.2,
          lat: item.lat,
          lng: item.lng,
          type:"plan_harvests"
        }
        oldItem = item
        return item
      })
      GMap.drawMarkers(map, ditems, {group:"plan_harvests", textSize:20, textColor:"green", textWeight:"bold", width:200, ...options})
      GMap.drawArrowPath(map, ditems, {group:"plan_harvests_path", color:"green"})
    }
  }

  const drawWorkingFields = (items, options) => {
    if(map && items){
      GMap.drawPolygons(map, items, {group:"field_working",...options})
    }
  }

  const drawFields = (items, options) => {
    if(map && items){
      GMap.drawPolygons(map, (props.working && props.working.field_no && props.working.yearly ? items.filter((item) => {
        if(item.field_no === props.working.field_no && item.yearly === props.working.yearly) return false; else return true;
      }) : items), {group:"fields",...options})
    }
  }

  const drawZones = (items, options) => {
    if(map && items){
      GMap.drawPolygons(map, items, {group:"zones",...options})
    }
  }

  const drawPOIs = (items, options) => {
    if(map && items){
      const ditems =  items.map((item, index) => {
        item.marker = {
          label: item.name,
          image: window.location.origin+"/assets/"+settings.name+"/img/markers/"+item.image,
          width: 32,
          height: 37,
          scale: 0.5,
          lat: item.lat,
          lng: item.lng,
          type:"poi"
        }
        return item
      })
      GMap.drawMarkers(map, ditems, {group:"pois", ...options})
    }
  }

  const drawLocations = (items, options) => {
    if(map && items){
      const mitems =  items.filter((item) => {
        return !Boolean(item.polygon_geofield)
      }).map((item, index) => {
        let image = window.location.origin+"/assets/"+settings.name+"/img/markers/mural.png"
        if(item.role === "plant"){
          image = window.location.origin+"/assets/"+settings.name+"/img/markers/landfill.png"
        }else if(item.role === "site"){
          image = window.location.origin+"/assets/"+settings.name+"/img/markers/kayaking.png"
        }
        item.marker = {
          label: item.name,
          caption: item.caption,
          image: image,
          width: 32,
          height: 37,
          scale: 0.7,
          lat: item.lat,
          lng: item.lng,
          type:"location"
        }
        return item
      })
      GMap.drawMarkers(map, mitems, {group:"locations", ...options})
      const pitems =  items.filter((item) => {
        return Boolean(item.polygon_geofield)
      })
      GMap.drawPolygons(map, pitems, {group:"plocations",...options})
    }
  }

  const drawVehicles = (items, options) => {
    if(map && items){
      const ditems =  items.map((item, index) => {
        item.marker = {
          label: (item.plan_time ? "("+MagbizDateTime.formatDate(item.plan_time,"TimeMin")+") " : "")+item.regist_no,
          forceLabel:(item.plan_time ? true : false),
          textColor: (item.plan_time ? "#08F96E" : ""),
          image: window.location.origin+"/assets/"+settings.name+"/"+item.vehicle_img_origin,
          width: 70,
          height: 70,
          scale: 0.7,
          rotate: parseInt(item.angle),
          lat: parseFloat(item.lat),
          lng: parseFloat(item.lng),
          type: "vehicle"
        }
        return item
      })
      GMap.drawMarkers(map, ditems, {group:"vehicles", ...options})
    }
  }

  const drawVehicleRouteHistory = (historys) => {
    if(map && historys && props.data.vehicles){
      const vehicle = (historys.length > 0  ? MagbizGeneral.findByKey(props.data.vehicles, "id", historys[0].id) : null)
      GMap.drawHData(map, historys, {group:"hdata", vehicle:vehicle, notification:props.data.notification})
    }
  }

  const drawRoutePath = (rpaths) => {
    if(map && rpaths){
      GMap.drawRoutePaths(map, rpaths, {group:"rpaths", color:"purple", strokeOpacity:0.3, strokeWeight:5})
    }
  }

  const setDrawingMode = (mode) => {
    if(map){
      performApp("APP_GEO_CHANGE", {...geos, polygon:null})
      GMap.setDrawingMode(map, mode, (polygon) => {
         mappingPolygonToField(polygon)
      })
    }
  }

  const mappingPolygonToField = (polygon) => {
    const pts = GMap.getPolygonPoints(polygon)
    const centroid = Geo.getCentroidPolygonPath(pts)
    const polygonInfo = {
      polygon_geofield:Geo.getWktPolygonFromPath(pts),
      gps_area:MagbizGeneral.round(GMap.calculatePolygonArea(polygon)/1600,3),
      lat:MagbizGeneral.round(centroid.lat,7),
      lng:MagbizGeneral.round(centroid.lng,7),
    }
    performApp("APP_GEO_CHANGE", {...geos, polygon:polygonInfo})
  }

  return (
    <div className={props.mapContainerClass} style={{ height: "100%", width: "100%" }}>
    </div>
  )

}
