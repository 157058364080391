module.exports = {
	"sidebar.section.main_menu": "เมนูหลัก",
	"sidebar.section.setting_menu": "การตั้งค่า",
	"sidebar.dashboard": "Dashboard",
	"sidebar.bookings": "การจอง - เข้าพัก",
	"sidebar.income_expenses": "รายรับ - รายจ่าย",
	"sidebar.rooms": "ห้องพัก",
	"sidebar.customers": "ลูกค้า",
	"sidebar.users": "ผู้ใช้งานระบบ",
	"sidebar.report": "รายงาน",
	"sidebar.report.income_expenses": "รายรับ - รายจ่าย",

	"dashboard.visitors_temp": "จำนวนครั้งเข้าพักชั่วคราว",
	"dashboard.visitors_overnight": "จำนวนคืนเข้าพัก",
	"dashboard.revenue": "รายได้",

	"income_expense.create": "เพิ่มรายการใหม่",
	"income_expense.detail": "ข้อมูลรายรับ - รายจ่าย",
	"income_expense.type": "ประเภท",
	"income_expense.type.all": "ทั้งหมด",
	"income_expense.type.income": "รายรับ",
	"income_expense.type.expense": "รายจ่าย",
	"income_expense.description": "รายการ",
	"income_expense.amount": "จำนวนเงิน",
	"income_expense.posting_date": "ลงวันที่",
	"income_expense.status": "สถานะ",
	"income_expense.status.all": "ทั้งหมด",
	"income_expense.status.draft": "ร่าง",
	"income_expense.status.signed": "ยืนยัน",
	"income_expense.status.cancelled": "ยกเลิก",
	"income_expense.summary_income": "รวม รายรับ",
	"income_expense.summary_expense": "รวม รายจ่าย",
	"income_expense.summary": "รวม (รายรับ - รายจ่าย)",
	"income_expense.delete": "ลบรายการ",

	"booking.title_customer": "ข้อมูลลูกค้า-ผู้เข้าพัก",
	"booking.title_booking": "รายละเอียดการจอง-เข้าพัก",
	"booking.title_payment_detail": "รายละเอียดการชำระเงิน",
	"booking.title_additional": "เงื่อนไขเพิ่มเติม",
	"booking.create": "จองห้องพัก",
	"booking.detail": "ข้อมูลการจอง - เข้าพัก",
	"booking.booking_no": "Booking",
	"booking.booking_no_abbr": "Booking",
	"booking.status_time": "สถานะอัพเดท",
	"booking.status": "สถานะ",
	"booking.status.All": "ทั้งหมด",
	"booking.status.Book": "จอง",
	"booking.status.Paid": "จ่ายเงิน",
	"booking.status.CheckIn": "Check in",
	"booking.status.Complete": "Check out",
	"booking.status.Cancelled": "ยกเลิก",
	"booking.booking_type": "การเข้าพัก",
	"booking.booking_type.overnight": "ค้างคืน",
	"booking.booking_type.temp": "ชั่วคราว",
	"booking.number": "คืน หรือ ชม",
	"booking.start_date":"เช็คอิน",
	"booking.start_time":"เวลาที่เข้าพัก",
	"booking.end_date":"เช็คเอาท์",
	"booking.end_time":"เวลาที่ออก",
	"booking.room": "ห้องพัก",
	"booking.amount": "ค่าห้องพัก",
	"booking.time": "เวลาเข้า-ออก",
	"booking.duration": "ระยะเวลาเข้าพัก",
	"booking.add_new": "รายการจอง - เข้าพักใหม่",
	"booking.update": "อัพเดทข้อมูลการจอง - เข้าพัก",
	"booking.cancel": "ยกเลิกการจอง - เข้าพัก",
	"booking.change_status": "เปลี่ยนสถานะการจอง - เข้าพัก",
	"booking.status_remark": "หมายเหตุ (สถานะ)",
	"booking.price": "ราคาห้องพัก",
	"booking.discount": "ส่วนลด",
	"booking.reserve": "เงินมัดจำ",
	"booking.breakfast": "อาหารเช้า",
	"booking.breakfast.yes" : "มี",
	"booking.breakfast.no" : "ไม่มี",
	"booking.recipient": "ผู้รับจอง",
	"booking.payment_channel": "ช่องทางการชำระ",
	"booking.payment_channel.bank": "โอนเงิน",
	"booking.payment_channel.credit_card": "บัตรเครดิต",
	"booking.payment_channel.cash": "เงินสด",
	"booking.payment_channel.voucher": "Voucher",
	"booking.payment_channel.other": "อื่นๆ",
	"booking.payment_bank": "ธนาคาร",
	"booking.payment_no": "เลขที่บัญชี / บัตร",
	"booking.payment_detail": "รายละเอียดการชำระเงิน",
	"booking.vehicle_no": "ทะเบียนรถ",
	"booking.channel": "ช่องทางการขาย",
	"booking.person": "จำนวนผู้เข้าพัก",

	"customer": "ลูกค้า",
	"customer.title_customer": "ข้อมูลลูกค้า",
	"customer.title_customer_card": "ข้อมูลบัตรประชาชน",
	"customer.customer_type" : "ประเภท",
	"customer.customer_type.All" : "ทั้งหมด",
	"customer.customer_type.member" : "ลูกค้าสมาชิก",
	"customer.customer_type.general" : "ลูกค้าทั่วไป",
	"customer.customer_type.vip" : "ลูกค้าพิเศษ",
	"customer.create": "เพิ่มลูกค้าใหม่",
	"customer.detail": "ข้อมูลลูกค้า",
	"customer.name": "ชื่อลูกค้า",
	"customer.phone": "เบอร์โทร",
	"customer.card_id":"เลขที่บัตรประชาชน",
	"customer.card":"บัตรประชาชน",
	"customer.age": "อายุ",
	"customer.gender": "เพศ",
	"customer.gender.all": "ทั้งหมด",
	"customer.gender.male": "ชาย",
	"customer.gender.female": "หญิง",
	"customer.gender.other": "ไม่ระบุ",
	"customer.remark": "หมายเหตุ",
	"customer.registered_date": "วันที่ลงทะเบียน",
	"customer.last_visited_date": "ใช้บริการล่าสุด",
	"customer.delete": "ลบข้อมูลข้อมูลลูกค้า",

	"room.title_room": "ข้อมูลห้องพัก",
	"room.title_additional": "หมายเหตุ",
	"room": "ห้องพัก",
	"room_no": "ห้องพัก",
	"room.remark": "หมายเหตุ",
	"room.create": "สร้างห้องพักใหม่",
	"room.detail": "ข้อมูลห้องพัก",
	"room.room_no": "เลขห้อง",
	"room.room_type": "ประเภท",
	"room.room_type.all": "ทุกประเภท",
	"room.room_type.standard": "Standard",
	"room.room_type.deluxe": "Deluxe",
	"room.room_type.jacuzzi": "Jacuzzi",
	"room.price": "ราคา/คืน",
	"room.price_per_hour": "ราคา/ชม",
	"room.prices_M" : "ราคา (จ.)",
	"room.prices_T" : "ราคา (อ.)",
	"room.prices_W" : "ราคา (พ.)",
	"room.prices_Th" : "ราคา (พฤ.)",
	"room.prices_F" : "ราคา (ศ.)",
	"room.prices_S" : "ราคา (ส.)",
	"room.prices_Su" : "ราคา (อา.)",
	"room.prices_Hr1" : "ราคา/ชม",
	"room.prices_Hr3" : "ราคา/3ชม",
	"room.status": "สถานะ",
	"room.status.all": "ทั้งหมด",
	"room.status.available": "เปิดใช้งาน",
	"room.status.cleaning": "ทำความสะอาด",
	"room.status.renovate": "ซ่อมแซม",
	"room.status.close": "ไม่เปิดใช้",
	"room.booking_status.available": "ว่าง",
	"room.last_visited": "สถานะล่าสุด",
	"room.delete": "ลบข้อมูลข้อมูลห้องพัก",

	"report.query_operation" : "เรียกดูข้อมูล",
	"report.print_date" : "วันที่พิมพ์",
	"report.start_date" : "จากวันที่",
	"report.end_date" : "ถึงวันที่",
	"report.start_time" : "ตั้งแต่เวลา",
	"report.end_time" : "ถึงเวลา",

	"text.night" : "คืน",
	"text.hour" : "ชม",
}
