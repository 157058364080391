const moduleName = "fmscust"

export default {
  name : moduleName,
  api : {
    server : "https://apiservices2.bestgeosystem.com/", //"http://192.168.80.146:8080/", // 
    app_module : moduleName,
  },
  title : "BestTech - Best Concrete Solution",
  description : "",
  logo : "assets/"+moduleName+"/logo.png",               // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  theme : {
    primary: {
      light: "rgba(211,47,47,0.8)",
      main: "rgba(211,47,47,0.9)",
      dark: "rgba(211,47,47,1)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(255,255,255,0.9)",
      main: "rgb(255,255,255,0.97)",
      dark: "rgb(255,255,255,1)",
      contrastText: '#424242'
    }
  },
  menu:{
    main_menu: {
  		title: "sidebar.section.main_menu",
      list: [

  		]
  	}
  },
  report_menu:{
    report: {
      list: [
      ]
    }
  },
}
