module.exports = {
	"sidebar.section.main_menu": "เมนูหลัก",
	"sidebar.section.setting_menu": "การตั้งค่า",
	"sidebar.dashboard": "Dashboard",
	"sidebar.report": "รายงาน",
	"sidebar.material_requests": "ใบเบิกอะไหล่",
	"sidebar.technicians" : "รายการช่าง",
	"sidebar.parts" : "รายการอะไหล่",

	"material_request.technician" : "ช่าง",
	"material_request.request_no" : "เลขงาน",
	"material_request.detail_status" : "รายละเอียด",
	"material_request.remark"  : "หมายเหตุ",
	"material_request.status": "สถานะ",
	"material_request.status.All": "ทั้งหมด",
	"material_request.status.Opened": "เปิดงาน",
	"material_request.status.Closed": "ทำคืนแล้ว",
	"material_request.status.Cancelled": "ยกเลิก",
	"material_request.closed_time" : "ปิดงาน",
	"material_request.opened_date" : "วันที่ทำเบิก",
	"material_request.created_time" : "เปิดงานเมื่อ",
	"material_request.opened_by" : "ผู้จ่ายอะไหล่",
	"material_request.closed_by" : "ผู้รับคืน",
	"material_request.closed_date" : "วันที่ทำคืน",
	"material_request.button.Closed" : "รับคืน",
	"material_request.item_part_no" : "Part No",
	"material_request.item_part_name" : "ชื่ออะไหล่",
	"material_request.item_received_date" : "วันที่รับ",
	"material_request.item_num" : "จำนวน",
	"material_request.item_price" : "ราคา",
	"material_request.item_return" : "ช่างทำคืน",

	"user.status": "สถานะ",
	"user.status.All": "ทั้งหมด",
	"user.status.Active": "Active",
	"user.status.Inactive": "In-active",

}
