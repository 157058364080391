const moduleName = "asp"

export default {
  name : moduleName,
  api : {
    server : "http://192.168.1.103:8083/",  //"https://sandbox.magbizcloud.com/",  //
    app_module : moduleName,
  },
  title : "ASPBKK",
  description : "ASP BANGKOK CO., LTD.",
  //logo : "assets/"+moduleName+"/logo.png",                // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  logo_head_text : "ASP Bangkok",
  theme : {
    primary: {
      //light: "#2ECC71",
      //main: "#229954",
      //dark: "#1E8449",
      light: "hsl(144, 98%, 50%)",
      main: "hsl(144, 98%, 40%)",
      dark: "hsl(144, 98%, 30%)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(255,255,255,0.9)",
      main: "rgb(255,255,255,0.95)",
      dark: "rgb(255,255,255,1)",
      contrastText: '#424242'
    },
    mytheme: {
      button: "#5F75B4",
      input: "#F4EFE7"
    }
  },
  menu:{
    main_menu: {
  		title: "sidebar.section.main_menu",
  		list: [
  			{"menu_title": "sidebar.dashboard", "menu_icon": "view-dashboard", "path": "/"+moduleName+"/dashboard", allowUserRole: ["admin"]},
        //{"menu_title": "sidebar.supplier","menu_icon": "city-alt", allowUserRole:["admin","it","account","account_h","purchase","purchase_h"],"child_routes": [
        //]},
        {"menu_title": "sidebar.purchase", "menu_icon": "assignment-returned", allowUserRole: ["admin","it","purchase","purchase_h"], "child_routes": [
          {"menu_title": "sidebar.purchase_po", "path": "/"+moduleName+"/purchase/po",},
          {"menu_title": "sidebar.contact_supplier", "path": "/"+moduleName+"/contact/supplier"},
        ]},

        {"menu_title": "sidebar.sale", "menu_icon": "money-box", allowUserRole: ["admin","sale","sale_h"], "child_routes": [
          {"menu_title": "sidebar.sale_qt", "path": "/"+moduleName+"/sale/qt",},
          {"menu_title": "sidebar.contact_customer", "path": "/"+moduleName+"/contact/customer"},
          {"menu_title": "sidebar.location_shipto", "path": "/"+moduleName+"/location/shipto"},
          //{"menu_title": "sidebar.sale_so", "path": "/"+moduleName+"/sale/so",},
        ]},
        //{"menu_title": "sidebar.customer","menu_icon": "store", allowUserRole:["admin","it","sale","sale_h","account","account_h"],"child_routes": [
          //{"menu_title": "sidebar.contact_shop_type", "path": "/"+moduleName+"/contact/shop_type"},
      //  ]},
        {"menu_title": "sidebar.contact", "menu_icon": "assignment", allowUserRole: ["admin","it","sale","sale_h","account","account_h","purchase","purchase_h"], "child_routes": [
          {"menu_title": "sidebar.contact_person", "path": "/"+moduleName+"/contact/person"},
        ]},

        {"menu_title": "sidebar.warehouse","menu_icon": "storage", allowUserRole:["admin","it","stock","stock_h"],"child_routes": [
          {"menu_title": "sidebar.product_PRDConstruction", "path": "/"+moduleName+"/product/PRDConstruction"},
          //{"menu_title": "sidebar.product_PRD_uom", "path": "/"+moduleName+"/product/PRD_uom"},
          {"menu_title": "sidebar.asset_AST", "path": "/"+moduleName+"/asset/AST"},
          {"menu_title": "sidebar.asset_SPY", "path": "/"+moduleName+"/asset/SPY"},
          {"menu_title": "sidebar.warehouse_PRD_stock", "path": "/"+moduleName+"/warehouse/PRD_stock",},
          {"menu_title": "sidebar.warehouse_AST_stock", "path": "/"+moduleName+"/warehouse/AST_stock",},
          {"menu_title": "sidebar.warehouse_SPY_stock", "path": "/"+moduleName+"/warehouse/SPY_stock",},
        ]},

        {"menu_title": "sidebar.pm", "menu_icon": "assignment", allowUserRole: ["admin","it","pm","pm_h"], "child_routes": [
          {"menu_title": "sidebar.pm_project", "path": "/"+moduleName+"/pm/project"},
          {"menu_title": "sidebar.pm_assignment", "path": "/"+moduleName+"/pm/assignment",},
          {"menu_title": "sidebar.pm_do", "path": "/"+moduleName+"/pm/do",},
        ]},

        {"menu_title": "sidebar.account", "menu_icon": "balance-wallet", allowUserRole: ["admin","it","account","account_h"], "child_routes": [
          {"menu_title": "sidebar.account_invoice", "path": "/"+moduleName+"/account/invoice"},
          {"menu_title": "sidebar.account_receipt", "path": "/"+moduleName+"/account/receipt"},
        ]},

        {"menu_title": "sidebar.hr", "menu_icon": "male-female", allowUserRole: ["admin","hr","hr_h"], "child_routes": [
          {"menu_title": "sidebar.hr_staff", "path": "/"+moduleName+"/hr/staff",},
          {"menu_title": "sidebar.hr_timesheet", "path": "/"+moduleName+"/hr/timesheet",},
          {"menu_title": "sidebar.hr_payroll", "path": "/"+moduleName+"/hr/payroll", allowUserRole: ["admin","hr_h"]},
        ]},

  		]
  	},
    operation: {
      //title: "sidebar.section.operation",
      list: [
        {"menu_title": "sidebar.operation", "menu_icon": "folder-person",  "child_routes": [
          {"menu_title": "sidebar.warehouse_goodsrequest", "menu_icon": "collection-text", "path": "/"+moduleName+"/warehouse/goodsrequest"},
          {"menu_title": "sidebar.warehouse_goodsreceipt", "menu_icon": "collection-text", "path": "/"+moduleName+"/warehouse/goodsreceipt", allowUserRole: ["admin","it","stock_h","stock"]},
          {"menu_title": "sidebar.operation_pr", "menu_icon": "collection-text", "path": "/"+moduleName+"/operation/pr",},
          {"menu_title": "sidebar.operation_eqr", "menu_icon": "collection-text", "path": "/"+moduleName+"/operation/eqr",},
          {"menu_title": "sidebar.operation_pdr", "menu_icon": "collection-text", "path": "/"+moduleName+"/operation/pdr",},
          {"menu_title": "sidebar.form_ot", "menu_icon": "collection-text", "path": "/"+moduleName+"/form/ot",},
          {"menu_title": "sidebar.form_leave", "menu_icon": "collection-text", "path": "/"+moduleName+"/form/leave",},
        ]},
      ]
    },
    settings: {
      title: "sidebar.section.settings",
      list: [
        //{"menu_title": "sidebar.settings_sale_area","path": "/"+moduleName+"/settings/sale_area",},

        {"menu_title": "sidebar.warehouse", "menu_icon": "store-24", allowUserRole:["admin","it","stock","stock_h"], "child_routes": [
          {"menu_title": "sidebar.warehouse_loc", "path": "/"+moduleName+"/warehouse/loc",},
          {"menu_title": "sidebar.product_PRD_brand", "path": "/"+moduleName+"/product/PRD_brand"},
          {"menu_title": "sidebar.warehouse_unit", "path": "/"+moduleName+"/warehouse/unit"},
        ]},

        {"menu_title": "sidebar.product", "menu_icon": "group", allowUserRole:["admin","it","stock","stock_h"], "child_routes": [
          {"menu_title": "sidebar.product_PRD_good", "path": "/"+moduleName+"/product/PRD_good"},
          {"menu_title": "sidebar.product_PRD_category", "path": "/"+moduleName+"/product/PRD_category"},
          {"menu_title": "sidebar.product_PRD_type", "path": "/"+moduleName+"/product/PRD_type"},
          //{"menu_title": "sidebar.product_PRD_model", "path": "/"+moduleName+"/product/PRD_model"},
        ]},

        {"menu_title": "sidebar.asset_asm_supplies", "menu_icon": "group", allowUserRole: ["admin","it","stock","stock_h"], "child_routes": [
          {"menu_title": "sidebar.asset_AST_good", "path": "/"+moduleName+"/asset/AST_good"},
          {"menu_title": "sidebar.asset_AST_category", "path": "/"+moduleName+"/asset/AST_category"},
          {"menu_title": "sidebar.asset_AST_type", "path": "/"+moduleName+"/asset/AST_type"},
          {"menu_title": "sidebar.asset_SPY_good", "path": "/"+moduleName+"/asset/SPY_good"},
          {"menu_title": "sidebar.asset_SPY_category", "path": "/"+moduleName+"/asset/SPY_category"},
          {"menu_title": "sidebar.asset_SPY_type", "path": "/"+moduleName+"/asset/SPY_type"},
        ]},
        {"menu_title": "sidebar.company_settings","menu_icon": "settings", allowUserRole:["admin"],"child_routes": [
          //{"menu_title": "sidebar.settings_company","path": "/"+moduleName+"/settings/company",},
          {"menu_title": "sidebar.settings_department", "path": "/"+moduleName+"/settings/department", allowUserRole: ["admin"]},
          {"menu_title": "sidebar.settings_division", "path": "/"+moduleName+"/settings/division", allowUserRole: ["admin"]},
    		]},
        //{"menu_title": "sidebar.account_settings", "menu_icon": "account-o", "path": "/"+moduleName+"/account_settings"},
      ]
    },
  },
  report_menu:{
    report: {
      list: [
        {"menu_title": "sidebar.report.detail", "menu_icon": "info-outline", "child_routes" : [
            {"menu_title": "sidebar.report.report1","path": "report/report1"},
            {"menu_title": "sidebar.report.report2","path": "report/report2"},
        ]}
      ]
    }
  }
}
