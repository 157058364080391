import React from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'

import settings from '../settings'

export class MagbizGeneral {

	static isEmpty(val) {
		if(typeof val === "undefined" || val === null || val === "" || val === 0)
			return true;
		else
			return false;
	}

	static isEmptyObject(obj){
		if(typeof obj === "undefined" || obj === null ||
			(Array.isArray(obj) && obj.length === 0) || Object.keys(obj).length === 0 ){
			return true;
		}else{
			return false;
		}
	}

	static value(val,default_value){
		if(this.isEmpty(val)){
	    if(typeof default_value === "undefined"){
	      return "";
	    }else{
	      return default_value;
	    }
	  }
		else
			return val;
	}

	static capitalize(str){
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	static left(str, n){
		if (n <= 0)
			return "";
		else if (n > String(str).length)
			return str;
		else
			return String(str).substring(0,n);
	}

	static right(str, n){
		if (n <= 0)
		   return "";
		else if (n > String(str).length)
		   return str;
		else {
		   let iLen = String(str).length;
		   return String(str).substring(iLen, iLen - n);
		}
	}

	static toMoney(n, decimals, decimal_sep, thousands_sep){
		 if(isNaN(n)) return ""
	   let c = isNaN(decimals) ? 2 : Math.abs(decimals);
	   let d = decimal_sep || '.';
	   let t = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep;
	   let sign = (n < 0) ? '-' : '';
	   let i = parseInt(n = Math.abs(n).toFixed(c)) + '';
		 let j = i.length
	   j = (j > 3) ? j % 3 : 0;
	   return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '')
	}

	static toMoneyOrBlank(n, decimals, decimal_sep, thousands_sep){
		if(n !== 0){
			return this.toMoney(n, decimals, decimal_sep, thousands_sep)
		}else{
			return ""
		}
	}

	static numberValue(str){
		if (typeof str === "number") {return str}
		if (typeof str === "undefined") {return 0}
		const value = parseFloat(str.replace(/,/g, ''))
		if(isNaN(value)){
			return 0
		}else{
			return value
		}
	}

	static nV(str){
		return this.numberValue(str)
	}

	static round(num, scale) {
	  if(!("" + num).includes("e")) {
	    return +(Math.round(num + "e+" + scale)  + "e-" + scale);
	  } else {
	    let arr = ("" + num).split("e");
	    let sig = ""
	    if(+arr[1] + scale > 0) {
	      sig = "+";
	    }
	    return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
	  }
	}

	static includes(array1, array2){
		for(let i=0;i<array2.length;++i){
			if(array1.includes(array2[i])){
				return true
			}
		}
		return false
	}

	static multiSearch(array1, text){
		if(typeof text === "undefined" || text === null) return false
		for(let i=0;i<array1.length;++i){
			if(typeof array1[i] === "undefined" || array1[i] === null) continue
			if(array1[i].toLowerCase().indexOf(text.toLowerCase()) !== -1){
				return true
			}
		}
		return false
	}

	static searchInListString(text, listtext, sep){
		if(typeof listtext === "undefined" || listtext === null) return false
		return this.multiSearch(listtext.split(sep),text)
	}

	static cookieValue(c_name, default_value)
	{
		let value;
		let tmpval1=this.getCookie(c_name);
		if (tmpval1!==null && tmpval1!==""){
			value = tmpval1;
		}else{
			value = default_value
			this.setCookie(c_name,value,365);
		}
		return value;
	}

	static setCookie(c_name,value,exdays)
	{
	  if(!this.isEmpty(value)){
	    let exdate=new Date();
	  	exdate.setDate(exdate.getDate() + exdays);
	  	let c_value=encodeURI(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());
	  	document.cookie=c_name + "=" + c_value;
	  }
	}

	static getCookie(c_name){
		let c_value = document.cookie;
		let c_start = c_value.indexOf(" " + c_name + "=");
		if (c_start === -1){
		  c_start = c_value.indexOf(c_name + "=");
		}
		if (c_start === -1){
		  c_value = null;
		}else{
		  c_start = c_value.indexOf("=", c_start) + 1;
		  let c_end = c_value.indexOf(";", c_start);
		  if (c_end === -1){
				c_end = c_value.length;
			}
			c_value = decodeURI(c_value.substring(c_start,c_end));
		}
		return c_value;
	}

	static generateRandomString(len){
		return Math.random().toString(36).substr(2, len)
	}

	static delay = (function(){
	  let timer = 0;
	  return function(callback, ms){
	    clearTimeout (timer);
	    timer = setTimeout(callback, ms);
	  };
	})();

	static sleep(ms) {
	  return new Promise((resolve) => {
	    setTimeout(resolve, ms);
	  });
	}

	// ค้นหาภายใน ArrayList ที่มีค่าของ key ที่ระบุตรงกับ value ของที่ระบุ
	static findByKey = (datas, key, value) => {
		if(!datas) return null
		const items = datas.filter(item => {
			if(item[key]+"" === value+""){
				return true
			}else {
				return false
			}
		})
		if(items.length === 1)
			return items[0]
		else
		 	return null
	}

	static removeByKey = (obj, props) => {
    if (!Array.isArray(props)) props = [props];
    return Object.keys(obj).reduce((newObj, prop) => {
        if (!props.includes(prop)) {
            newObj[prop] = obj[prop];
        }
        return newObj;
    }, {});
	}

	static filterData = (datas, filter_fields) => {
		if(!datas) return null
		if(!filter_fields) return datas
		return datas.filter(item => {
			let found=true
			for (let fkey in filter_fields) {
				if(filter_fields.hasOwnProperty(fkey) && item.hasOwnProperty(fkey)){
					const filter_value = filter_fields[fkey]+""
					if(filter_value !== "All" && filter_value !== "undefined"){
						if(filter_value !== item[fkey]+""){
							found = false
						}
					}
				}
			}
			if(!found){return false}else{return true}
		})
	}

	static applyFilterWithObject = (filter, filter_obj) => {
		if(!filter) return filter
		if(filter_obj){
			for(let k in filter_obj){
				if(filter[k]){
					filter[k].value = filter_obj[k]
				}else{
					filter[k] = {name:k, value:filter_obj[k]}
				}
			}
		}
		return filter
	}

	// ใช้แปลงข้อมูล Array List ทั่วไปหรือที่ได้จาก Server ให้อยู่ในรูปแบบ Object List ที่มี attr ชื่อ text (สามารถนำไปใช้ต่อกับ MagbizUI.formatList ได้)
	static getObjectList = (olist, key, label, option) => {
		let list = {}
    if(olist){
      olist.reduce((object, item) => {
				let obj = {text : item[label]}
				if(item.status && item[key]+"" !== "" && (!option || !option.enabledAll) ){
					obj.disabled = (item.status === "Active" || item.status === "A" || item.status === "Verified" || item.status === "Signed" ? false : true)
				}
        object[item[key]+""] = obj
        return object
      }, list )
    }
		return list
	}

	// ใช้แปลงข้อมูล Array List ทั่วไปหรือที่ได้จาก Server ให้อยู่ในรูปแบบ Object List ที่มี attr ชื่อ text (สามารถนำไปใช้ต่อกับ MagbizUI.formatList ได้)
	// ใช้กรณีที่ text กับ label ไม่เหมือนกัน (text โชว์ตอนเลือกแล้ว, label โชว์บน suggession list)
	static getObjectList2 = (olist, key, text, label, option) => {
		let list = {}
    if(olist){
      olist.reduce((object, item) => {
				let obj = {text : item[text], label : item[label]}
				if(item.status && item[key]+"" !== "" && (!option || !option.enabledAll) ){
					obj.disabled = (item.status === "Active" || item.status === "A" || item.status === "Verified" || item.status === "Signed" ? false : true)
				}
        object[item[key]+""] = obj
        return object
      }, list )
    }
		return list
	}

	// ใช้แปลงข้อมูล Array List ทั่วไปหรือที่ได้จาก Server ให้อยู่ในรูปแบบ Object List โดยคงรูปแบบข้อมูลใน Object ไว้
	static getObject = (olist, key) => {
		let list = {}
    if(olist){
      olist.reduce((object, item) => {
				let obj = item
				if(item.status){
					obj.disabled = (item.status === "Active" || item.status === "A" || item.status === "Verified" || item.status === "Signed" ? false : true)
				}
        object[item[key]+""] = obj
        return object
      }, list )
    }
		return list
	}

	//แปลง ArrayList เดิมเป็น ArrayList ใหม่ในรูปแบบ label, value
	//เมื่อใช้ ArrayList จะไมต้องใช้ MagbizUI.formatList แต่จะไม่สามารถใช้แสดงผลได้ใน Column เนื่องจากไม่มี ObjectList
	static getArrayList = (arrlist, key, label) => {
		let list = []
    if(arrlist){
			arrlist.reduce((ilist, item) => {
        ilist.push({ label : item[label] , value : item[key], disabled:(item.status === "Active" || item.status === "A" || item.status === "Verified" || item.status === "Signed" ? false : true) })
        return ilist
      }, list )
    }
		return list
	}

	// แปลง Arraylist เป็น map[string]ArrayList
	static getMapArrayList = (list, key) => {
		let map = {}
		if(list){
			for(let k in list){
				const key_data = list[k][key]
				let arr = map[key_data]
				if(arr){
					arr.push(list[k])
					map[key_data] = arr
				}else{
					map[key_data] = [list[k]]
				}
			}
		}
    return map
	}

	// แปลง Arraylist เป็น map[string]ObjectList
	static getMapObjectList = (list, key_m, key_l) => {
		let map = {}
		if(list){
			for(let k in list){
				const key_map = list[k][key_m]
				const key_list = list[k][key_l]
				let objectlist = map[key_map]
				if(objectlist){
					objectlist[key_list] = list[k]
					map[key_map] = objectlist
				}else{
					map[key_map] = {[key_list]:list[k]}
				}
			}
		}
    return map
	}

	static reduceSum = (object, subkey) => {
		if(!object || Object.keys(object).length === 0) return 0
		return Object.values(object).reduce((total, item) => {
			total += (subkey ? item[subkey] : item);
			return total
		})
	}

	// เรียงลำดับ List ด้วย Object key ที่ระบุ
	static sortByKey = (list, key, sortType) => {
		return list.sort(function(a, b) {
			if(a[key] < b[key]) { return (sortType === "desc" ? 1 : -1 ); }
			if(a[key] > b[key]) { return (sortType === "desc" ? -1 : 1 ); }
			return 0;
		})
	}

	static masterText = (master_list, master, value) => {
		if(master_list){
			if(master_list[master]){
				if(master_list[master][value]){
					return master_list[master][value].text
				}
			}
		}
		return value
	}

	static masterListText = (master_list, value) => {
		if(master_list){
			if(master_list[value]){
				return master_list[value].text
			}
		}
		return value
	}

	static formatCardNumber = (value) => {
	  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
	  const onlyNumbers = value.replace(/[^\d]/g, '')

	  return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
	    [$1, $2, $3, $4].filter(group => !!group).join(' ')
	  )
	}

	// ทำ Unique List ด้วย Object key ที่ระบุ
	/*static uniqueByKey = (list, key, allowBlank) => {
		if(typeof list === "undefined" || list === null) return []
		const ulist = list.map(item => item[key]).filter((value, index, self) => self.indexOf(value) === index).reduce((thelist, itemvalue) => {
			if(itemvalue || allowBlank){
				thelist.push({[key]:itemvalue})
			}
			return thelist
		}, [])
		return ulist
	}*/

}

export class MagbizDateTime {

	// ############## Sync Time ###########
	static ntpDiffTime = 0
	static syncNTP(){
		//do nothing now
	}
	static currentSyncTime(){
		return new Date((new Date()).valueOf() + MagbizDateTime.ntpDiffTime)
	}
	// ############## Sync Time ###########

	static nowDate(){
		return this.formatDate(this.currentSyncTime(), "DBDateTime").substr(0,10)
	}

	static nowDateTime(){
		return this.formatDate(this.currentSyncTime(), "DBDateTime")
	}

	static nowTimeMin(){
		return this.formatDate(this.currentSyncTime(), "TimeMin")
	}

	static nextDate(day){
		return this.formatDate(this.dateAdd(this.currentSyncTime(), day*3600*24), "DBDateTime").substr(0,10)
	}

	static nextDateFrom(source, day){
		return this.formatDate(this.dateAdd(source, day*3600*24), "DBDateTime").substr(0,10)
	}

	static nextDateTime(sec){
		return this.formatDate(this.dateAdd(this.currentSyncTime(), sec), "DBDateTime")
	}

	static lastDateOfCurrentMonth(){
		const today = this.currentSyncTime()
		const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0)
		return this.formatDate(lastDayOfMonth, "DBDateTime").substr(0,10)
	}

	static firstDateOfCurrentMonth(){
		const today = this.currentSyncTime()
		const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
		return this.formatDate(firstDay, "DBDateTime").substr(0,10)
	}

	static nowFormat(f){
		return moment().format(f)
	}

	static dateObjFromString(dateStr){
	  try{
	    if(!dateStr || dateStr === "0000-00-00" || dateStr === "0000-00-00 00:00:00" ){
	      return "Empty date string";
	    }
	    let dt = null;
	    if(dateStr.indexOf("-") !== -1){
	      let a=dateStr.split(" ");
	      let d=a[0].split("-");
	      let t= [0,0,0];
	      if(a.length > 1){
	        t = a[1].split(":");
					if(t.length === 2){
						t[2] = "00"
					}
	      }
	      dt = new Date(d[0],(d[1]-1),d[2],t[0],t[1],t[2]);
	    }else if(dateStr.indexOf("/") !== -1){
	      let a=dateStr.split(" ");
	      let d=a[0].split("/");
	      let t= [0,0,0];
	      if(a.length > 1){
	        t = a[1].split(":");
					if(t.length === 2){
						t[2] = "00"
					}
	      }
	      dt = new Date(d[2],(d[1]-1),d[0],t[0],t[1],t[2]);
	    }else{
	      return "Invlid date string";
	    }
	    if(MagbizGeneral.isEmpty(dt)){
	      return "Unknown Date Format";
	    }else{
	      return dt;
	    }
	  }catch(err){
	    return "Date Parsing Error";
	  }
	}

	static formatTimeMin(value){
		return MagbizDateTime.formatDate(value, "TimeMin","")
	}

	static formatDate(dateStr, format, lang){
	  let dateF="";
	  if(typeof format === "undefined"){
	    format = "Default";
	  }

	  try{

	    let UserException = function(message) {
	       this.message = message;
	       this.name = 'UserException';
	    }

	    let dt;
			if(typeof dateStr === "undefined"){
				return ""
	    }else if(typeof dateStr === "string"){

	      dt = this.dateObjFromString(dateStr);
	      if(typeof dt === "string"){
	        if(dt === "Empty date string"){
	          return "";
	        }else{
	          throw new UserException(dt);
	        }
	      }

	    }else if (typeof dateStr === "number"){
	      dt = new Date(dateStr);
	    }else{
	      dt = dateStr;
	    }

	    if(!MagbizGeneral.isEmpty(lang)){
	      if(lang === "Auto"){
	        lang = "";
	      }
				if(lang === "th") {lang = "Thai"}
				if(lang === "en") {lang = "Eng"}
	      format = lang+format;
	    }

			if(format === "ThaiDate"){
				dateF = moment(dt).locale('th').format("DD MMMM YYYY")
	    }else if(format === "ThaiShortDateTime"){
				dateF = moment(dt).locale('th').format("DD MMM YY HH:mm")
				if(dateF.endsWith(" 00:00")){
					dateF = moment(dt).locale('th').format("DD MMM YY")
				}
	    }else if(format === "ThaiShortDate"){
				dateF = moment(dt).locale('th').format("DD MMM YY")
	    }else if(format === "ThaiMonth"){
				dateF = moment(dt).locale('th').format("MMMM YYYY")
	    }

			if(format === "EngDate"){
				dateF = moment(dt).format("MMMM DD, YYYY")
	    }else if(format === "EngShortDate"){
				dateF = moment(dt).format("MMM DD, YY")
	    }else if(format === "EngShortDateTime"){
				dateF = moment(dt).format("MMM DD, YY HH:mm")
				if(dateF.endsWith(" 00:00")){
					dateF = moment(dt).format("MMM DD, YY")
				}
	    }else if(format === "EngMonth"){
				dateF = moment(dt).format("MMMM YYYY")
	    }

			if(format === "DBDateTime"){
	      dateF = moment(dt).format("YYYY-MM-DD HH:mm:ss")
			}else if(format === "Date"){
	      dateF = moment(dt).format("DD/MM/YYYY")
	    }else if(format === "DateTime"){
	      dateF = moment(dt).format("DD/MM/YYYY HH:mm")
				if(dateF.endsWith(" 00:00")){
					dateF = moment(dt).format("DD/MM/YYYY")
				}
	    }else if(format === "DateTimeSec"){
	      dateF = moment(dt).format("DD/MM/YYYY HH:mm:ss")
	    }else if(format === "ShortDate"){
	      dateF = moment(dt).format("DD/MM/YY")
	    }else if(format === "ShortDateTime"){
	      dateF = moment(dt).format("DD/MM/YY HH:mm")
				if(dateF.endsWith(" 00:00")){
					dateF = moment(dt).format("DD/MM/YY")
				}
			}else if(format === "Time"){
				dateF = moment(dt).format("HH:mm:ss")
	    }else if(format === "TimeMin"){
	      dateF = moment(dt).format("HH:mm")
	    }else if(format === "Default"){
	      dateF = moment(dt).format("DD/MM/YYYY")
	    }
	    //console.log(dateStr+"("+(typeof dateStr)+") "+format+" "+lang);
	  }catch(err){
	    console.log("MagbizDateTime.formatDate : "+err.message +" ["+dateStr+"("+(typeof dateStr)+") "+format+" "+lang+"]");
	  }
	  return dateF;
	}

	static dateDiff(dateMore, dateLess){
	  return this.diffDate(dateMore, dateLess)/86400/1000
	}

	static dateAdd(source, second){
		let dt1 = source
		if(typeof source === "string"){
			dt1 = this.dateObjFromString(source)
		}
	  return new Date(dt1.valueOf() + second*1000)
	}

	static diffDate(dateMore, dateLess){
	  try{
	    let dt1 = dateMore;
	    let dt2 = dateLess;
	    if(typeof dateMore === "string"){
	      dt1 = this.dateObjFromString(dateMore);
	    }
	    if(typeof dateLess === "string"){
	      dt2 = this.dateObjFromString(dateLess);
	    }
	    return (dt1-dt2);
	  }catch(err){
	    console.log("MagbizDateTime.diffDate : "+err.message)
	  }
	}

	static isTimeFormat(tval){
	  if(tval.length !== 5 || tval.substring(2, 3) !== ":"){
	    return false;
	  }
	  return true;
	}

	static getTimeFromSecond(sec, text_day,text_hour,text_min,text_sec){
		let obj = {};
		if(sec < 60){
			obj.sec = sec;
		}else if(sec < 3600){
			obj.sec = sec%60;
			obj.min = Math.floor(sec/60);
		}else if(sec < 3600 * 24){
			obj.sec = sec%60;
			obj.min = Math.floor((sec%3600)/60);
			obj.hour = Math.floor(sec/3600);
		}else {
			obj.sec = sec%60;
			obj.min = Math.floor((sec%3600)/60);
			obj.hour = Math.floor((sec%(3600 * 24))/3600);
			obj.day = Math.floor(sec/(3600 * 24));
		}

		obj.text_total_time="";
		if(obj.day && text_day){ obj.text_total_time +=  obj.day + " " + text_day + " "; }
		if(obj.hour && text_hour){ obj.text_total_time += obj.hour + " " + text_hour+ " "; }
		if(obj.min && text_min && !obj.day){ obj.text_total_time += obj.min + " " + text_min+ " "; }
		if(obj.sec && text_sec && !obj.hour){ obj.text_total_time += obj.sec + " " + text_sec+ " "; }

		return obj;
	}

	static getTimeHourFromSecond(sec){
		let obj = {sec:0, min:0, hour:0};
		if(sec < 60){
			obj.sec = sec;
		}else if(sec < 3600){
			obj.sec = sec%60;
			obj.min = Math.floor(sec/60);
		}else if(sec < 3600 * 24){
			obj.sec = sec%60;
			obj.min = Math.floor((sec%3600)/60);
			obj.hour = Math.floor(sec/3600);
		}else {
			obj.sec = sec%60;
			obj.min = Math.floor((sec%3600)/60);
			obj.hour = Math.floor(sec/3600);
		}
		if(obj.hour < 10){
			obj.hour = "0"+obj.hour
		}
		return obj.hour+":"+MagbizGeneral.right("00"+obj.min,2)+":"+MagbizGeneral.right("00"+obj.sec,2);
	}

	static generateMonthly(numOfMonth, advanceMonth){
	  const thisMonth = moment().add(1+advanceMonth, 'months')
	  let months = [], oldYear = ""
	  for (let i = 0; i < numOfMonth; i++) {
	    thisMonth.subtract(1, 'months')
			let year = thisMonth.format('YYYY')
			if(year !== oldYear){
				months.push({label: year, value : year})
			}
			oldYear = year
	    months.push({label:thisMonth.format('MMM YYYY'), value : thisMonth.format('YYYYMM')})
	  }
	  return months
	}

}

export class MagbizUI {

	static formatDateGeneral = (value) => {
		let date_text = MagbizDateTime.formatDate(value,"Date");
		const time_text = MagbizDateTime.formatDate(value,"TimeMin");
		return date_text + (time_text === "00:00" ? "" : " "+time_text)
	}

	static formatDate = (value, tlang) => {
		let date_text = MagbizDateTime.formatDate(value,"ShortDate",tlang({id:'lang'}));
		const time_text = MagbizDateTime.formatDate(value,"TimeMin");
		if(date_text === MagbizDateTime.formatDate(Date.now(),"ShortDate",tlang({id:'lang'}))){
			date_text = tlang({id:'today'})
		}else if(date_text === MagbizDateTime.formatDate(MagbizDateTime.dateAdd(Date.now(), -3600 * 24),"ShortDate",tlang({id:'lang'}))){
			date_text = tlang({id:'yesterday'})
		}
		return date_text + (time_text === "00:00" ? "" : " "+time_text)
	}

	static formatDateT = (value, translate) => {
		let date_text = MagbizDateTime.formatDate(value,"ShortDate",translate('lang'));
		const time_text = MagbizDateTime.formatDate(value,"TimeMin");
		if(date_text === MagbizDateTime.formatDate(Date.now(),"ShortDate",translate('lang'))){
			date_text = translate('today')
		}else if(date_text === MagbizDateTime.formatDate(MagbizDateTime.dateAdd(Date.now(), -3600 * 24),"ShortDate",translate('lang'))){
			date_text = translate('yesterday')
		}
		return date_text + (time_text === "00:00" ? "" : " "+time_text)
	}

	static formatDateWithDesc = (value, translate) => {
		if(!Boolean(value)) return ""
		const diffdate = MagbizDateTime.dateDiff(MagbizDateTime.nowDate(), value.substr(0,10))
		let display = MagbizDateTime.formatDate(value,"ShortDateTime",translate('lang'))
		if(diffdate === 0){
			display += " ("+translate("today")+")"
		}else if(diffdate === 1){
			display += " ("+translate("yesterday")+")"
		}else if(diffdate > 1){
			display += " ("+diffdate+" "+translate("day_ago")+")"
		}
		return display
	}

	// แปลง Object List เป็น Array List โดย Object List ต้องมี attr ชื่อ text
	static formatList = (theList, sort, option) => {
		let klist = Object.keys(theList)
		if(sort === "key") klist = klist.sort()
		let list = klist.reduce((list, key) => {
			if(!theList[key].notForUser && key !== "All"){
				list.push({ label : (theList[key].label ? theList[key].label : theList[key].text), text:theList[key].text, value : key, color:theList[key].color, disabled: (theList[key].disabled ? (option && option.activeAll ? false : true) : false) });
			}
			return list
		}, [])
		if(sort === "label"){
			list = list.sort(function(a, b) {
				if(a.label < b.label) { return (option && option.sortType === "desc" ? 1 : -1 ); }
				if(a.label > b.label) { return (option && option.sortType === "desc" ? -1 : 1 ); }
				return 0;
			})
		}
		return list
	}

	static formatMultiSelect = (value, theList, label, sep) => {
		if(!theList) return ""
		const values = value.split("|")
		const theresult = values.reduce((result, key) => {

			if(theList[key] && theList[key][label]){
				result += (result === "" ? "" : sep)+" "+theList[key][label]
			}
			return result
		}, "")
		return theresult
	}

	static getSelectMenuItem = (menu) => {
		if(menu.defaultValue){
			for(let k in menu.list){
				if(menu.list[k].key === menu.defaultValue)
					return menu.list[k]
			}
		}
		return menu.list[0]
	}

	// ใช้สร้าง ListItem บริเวณ Sidebar (MenuItemList) แบบ Static
	static createMenuFilter = (title, menu_item_obj, menu_source_list) => {
		if(!menu_source_list) return {title: title, list:[]}
		let source_list = menu_source_list
		if (!Array.isArray(menu_source_list)){
			source_list = Object.keys(menu_source_list).reduce((list, key) => {
				return [...list, {label: menu_source_list[key].text, value: key, color: menu_source_list[key].color}]
			}, [])
		}
		const menu_item = {
			title: title,
			list: source_list.reduce((list, item) => {
				list.push({...menu_item_obj, key:item.value, title: item.label, color: (item.color ? item.color : "#BDBDBD")})
				return list
			}, [] )
		}
		return menu_item
	}

	// ใช้สร้าง ListItem บริเวณ Sidebar (MenuItemList) แบบ Dynamic
	static createListItem = (title, item_obj, source_list, key, title_key) => {
		const the_item = {
			title: title,
			list: (source_list ? source_list.reduce((list, item) => {
				list.push({...item_obj, ...item, key:item[key], title: item[title_key], color: (item.color ? item.color : "#BDBDBD")})
				return list
			}, [] ) : [])
		}
		return the_item
	}

	static createStatusColumnItem = (itemValue, list_object) => {
		if(typeof list_object[itemValue] === "undefined") {return null}
		return (
			<div style={{width:'100%',textAlign:"center",fontSize:8}}>
				{list_object && itemValue &&
				<div style={{marginLeft:"5%", width:"90%", borderRadius:"0.25rem", backgroundColor:list_object[itemValue].color, padding:"5px 0px", color:"white"}}>
					{list_object[itemValue].text}
				</div>}
			</div>
		)
	}

	// column created_user_name, approved_user_name ใช้ร่วมกับ TableListView
	static columnTable_CreateApproveUser = (translate) => {
		return {label: translate("created_approved_user_name"), dataKey: 'user', width: 130, hideWidth: 980, cellContentRenderer:(({cellData,columnIndex=null}) => {
			return (
				<div style={{width:'100%', lineHeight:1.2}}>
					<div style={{fontSize:10}}>{(cellData.created_user_name ? cellData.created_user_name : "")}</div>
					<div style={{fontSize:10, fontWeight:"bold", color:"#106CA7"}}>{(cellData.approved_user_name ? cellData.approved_user_name : "")}</div>
				</div>
			)
		}), cellContentKeys:["created_user_name", "approved_user_name"] }
	}

	// column create/update time ใช้ร่วมกับ TableListView
	static columnTable_LastCreatedUpdated = (translate) => {
		return {label: translate("created_date")+" / "+translate("last_updated"), dataKey: 'created_updated_time', width: 100, cellContentRenderer:(({cellData,columnIndex=null}) => {
			return (
				<div style={{width:'100%',fontSize:11}}>
					<div style={{color:"black"}}>{MagbizUI.formatDateT(cellData.created_time, translate)}</div>
					{cellData.updated_time && cellData.updated_time !== cellData.created_time &&
					<div style={{color:"grey"}}>{MagbizUI.formatDateT(cellData.updated_time, translate)}</div>}
				</div>
			)
		}), cellContentKeys:["created_time", "updated_time"], sortKey:"updated_time" }
	}

	// column update time ใช้ร่วมกับ TableListView
	static columnTable_LastUpdated = (translate, isHide) => {
		return {label: translate("last_updated"), dataKey: 'updated_time', width: 100,
				columnCalculation:(cellData) => MagbizUI.formatDateT(cellData.updated_time, translate),
				hideCondition:() => (isHide),
				sortKey:"updated_time" }
	}

	// column updated_time/last_access ใช้ร่วมกับ TableListView (สำหรับ User)
	static columnTable_LastUpdatedAccessTime = (translate) => {
		return {label: translate("last_updated")+" / "+translate("last_accessed"), dataKey: 'last_updated_accessed_time', width: 100, cellContentRenderer:(({cellData,columnIndex=null}) => {
			return (
				<div style={{width:'100%',fontSize:11}}>
					<div style={{color:"black"}}>{MagbizUI.formatDateT(cellData.updated_time, translate)}</div>
					{cellData.updated_time &&
					<div style={{color:"grey"}}>{MagbizUI.formatDateT(cellData.last_access_time, translate)}</div>}
				</div>
			)
		}), cellContentKeys:["updated_time", "last_access_time"] }
	}

	// ใช้แปลงข้อมูล Master ที่ได้จาก Server ให้อยู่ในรูปแบบ List (label, value) โดยข้อมูล Master ต้องแบ่งด้วย | และอาจจะมี klist ได้
	static getMasterObject = (master) => {
		if(!master) return {}
		return master.reduce((object, item) => {
			const list = item.list.split("|")
			if(item.klist){
				const klist = item.klist.split("|")
				let clist = null
				if(item.clist){
					clist = item.clist.split("|")
				}
				object[item.name] = list.map((text, index) => {
					let obj = { label : text , value : klist[index]}
					if(clist){
						obj["color"] = clist[index]
					}
					return obj
				})
				object[item.name+"_object"] = object[item.name].reduce((obj, oitem) => {
					obj[oitem.value] = {text:oitem.label, color:oitem.color}
					return obj
				}, {})
			}else{
				object[item.name] = list.map((text, index) => {
					return { label : text , value : text }
				})
			}
			return object
		}, {} )
	}

	static isFilterReady = (filter) => {
		for(let k in filter){
      if(filter[k].required){
        if(!filter[k].value){
          return false
        }
      }
    }
		return true
	}

	static uniqueArrayList = (arrArg) => {
		const result = []
		const map = new Map()
		for (const item of arrArg) {
	    if(!map.has(item.value)){
	        map.set(item.value, true);    // set any value to Map
	        result.push(item);
	    }
		}
		return result
	}

	static getDataFromFilterItem = (filter) => {
		if(!filter) return null
		return Object.values(filter).reduce((objects, item) => {
			if(item){objects[item.name] = item.value}
			return objects
		}, {})
	}

	static getDataSearched = (datas, searchable, search_text) => {
		if(search_text === "" || datas === null) return datas
    let dataRows = []
    if(datas){
      dataRows = datas.filter(item => {
				if(searchable && search_text !== ""){
		      let found=false
		      for(let i=0;i<searchable.length;++i){
		        if(item[searchable[i]].toLowerCase().includes(search_text.toLowerCase())){
		          found = true
		        }
		      }
		      if(!found) return false
		    }
		    return true
      })
    }
    return dataRows
  }

	static gridHeight = (page_height, page_padding, container_padding) => {
		const full = page_height-(page_padding*2)-(container_padding*2)
		const half = (full-(container_padding*2))/2
		const tri = (full-(container_padding*4))/3
		return {
			full:(full > 200 ? full : 200),
			half: (half > 150 ? half : 150),
			tri: (tri > 100 ? tri : 100)
		}
	}

	static createStatusButton = (status, title, props, detail, status_obj, master) => {
		let obj = {
			name:"status_"+status, text:title,
			operation: () => { MagbizEvent.onChangeStatus(props, detail, status, title, master)}
		}
		if(status_obj && status_obj[status].color){
			obj.color = status_obj[status].color
		}
		return obj
	}

	static textFromHTML = (str) => {
		let text = str

		if(text && text.startsWith && (text.startsWith("<a") || text.startsWith("<span") || text.startsWith("<div"))){
			var a = document.createElement('div');
	    a.innerHTML = text
	    for (let c = a.childNodes, i = c.length; i--; ) {
	      if (c[i].nodeType === 1) {
					text = c[i].innerHTML
					break
				}
	    }
		}

		if (typeof text === 'string' || text instanceof String){
			text = text.replaceAll("<br/>",String.fromCharCode(10))
		}
		return text
	}

	static fetchFieldsValue = (groups, detail) => {
    const fetchGroupValue = (objects, group) => {
      if(group.fields){
        const fields = Object.values(group.fields).reduce((objects1, ofield) => {
         let field = {...ofield}
         if(detail){
           field.value = detail[field.name]
           if(field.onAssign){
             field.onAssign(field)
           }
         }
         objects1[field.name] = field
         return objects1
        }, {})
        return {...objects, ...fields}
      }else{
        return objects
      }
    }
    let fields = groups.reduce((objects, group) => {
      return fetchGroupValue(objects, group)
    }, {})
    return fields
  }

	static mappingList = (mapping, item) => {
		return mapping.reduce((object, mitem) => {
      if(mitem.name){
        object[mitem.map] = item[mitem.name]
      }else if(mitem.value){
        object[mitem.map] = mitem.value(item)
      }else if(mitem.options){
        object[mitem.map] = mitem.options(item)
      }else if(mitem.lineoptions){
        object[mitem.map] = mitem.lineoptions(item)
      }
      if(mitem.color){
        object[mitem.map+"_color"] = (typeof mitem.color === "string" ? mitem.color : mitem.color(item) )
      }
      if(mitem.bgColor){
        object[mitem.map+"_bgColor"] = (typeof mitem.bgColor === "string" ? mitem.bgColor : mitem.bgColor(item) )
      }
      if(mitem.icon){
        object[mitem.map+"_icon"] = (typeof mitem.icon === "string" ? mitem.icon : mitem.icon(item) )
      }
      if(mitem.icontype){
        object[mitem.map+"_icontype"] = (typeof mitem.icontype === "string" ? mitem.icontype : mitem.icontype(item) )
      }
      return object
    }, {})
	}

	static checkRoleAuthorized = (action, fields, mode, userdata, permission) => {
		let allow = false
		if(mode === "view" && Boolean(userdata) && fields) {
			if(userdata.role === "admin" || !permission[action]){
				allow = true
			}else{
				if(userdata.role && permission[action].role && MagbizGeneral.includes(permission[action].role.split("|"), userdata.role.split("|"))){
					allow = true
				}
				if(userdata.user_authorized && permission[action].authorized && MagbizGeneral.includes([permission[action].authorized], userdata.user_authorized.split("|"))){
					allow = true
				}
			}
		}
		if(allow && action.startsWith("status_")){
			if(action.replace("status_","") === fields.status){
				allow = false
			}
		}
		return allow
	}

	// permissoin defualt คือไม่ให้สิทธิ์
	// roleSkipStatus ให้สิทธิ์ตาม Role ทันทีโดยไม่ต้องดู status
	// denyRole ไม่ให้สิทธิ์ตาม Role ทันทีโดยไม่ต้องดู status
	// isMyCompany ถ้ามี parameter นี้ จะให้สิทธิ์เฉพาะที่ company_id องค์กรเดียวกับข้อมูล
	// role ให้สิทธิ์ตาม Role (admin ได้สิทธิ์อัตโนมัติ)
	// authorized ให้สิทธิ์ตาม Authorized (admin ได้สิทธิ์อัตโนมัติ)
	// 		denyStatus ไม่ให้สิทธิ์ตาม status ( หลังผ่านเงื่อนไขสิทธิ์ตาม Company,Role,Authorized แล้ว)
	//  	allowStatus ไม่ให้สิทธิ์ตาม status ( หลังผ่านเงื่อนไขสิทธิ์ตาม Company,Role,Authorized,denyStatus แล้ว)
	// allowSpecial ให้สิทธิ์ทันทีแบบ Force ตามเงื่อนไขใน Object
	static permissionRoleAuthorized = (action, fields, mode, userdata, permission) => {

		if(mode === "view" && Boolean(userdata) && userdata.role && fields) {

			// ######### Permission Item
			if(!permission[action]){
				return true
			}

			// ######### Role Force Level
			// allow role ทันที โดยไม่ต้องตรวจสอบ status
			if(permission[action].roleSkipStatus && MagbizGeneral.includes(userdata.role.split("|"), permission[action].roleSkipStatus.split("|"))){
				return true
			}
			// deny role ทันที
			if(permission[action].denyRole && MagbizGeneral.includes(userdata.role.split("|"), permission[action].denyRole.split("|"))){
				return false
			}

			// ######### Special Check Level
			// ตรวจสอบองค์กรเดียวกับ User หรือไม่
			if(permission[action].isMyCompany){
				const isMyCompany = (fields.org_id === userdata.company_id)
				if(!isMyCompany){
					return false
				}
			}
			// Check Repeat Status เช็ค status ที่ซ้ำเดิม
			if(action.startsWith("status_")){
				if(action.replace("status_","") === fields.status){
					return false
				}
			}
			// ให้สิทธิ์ทันทีถ้าตรงเงื่อนไข Special
			if(permission[action].allowSpecial){
				for (const as of permission[action].allowSpecial) {
					if(as.status === fields.status && MagbizGeneral.includes(userdata.role.split("|"), as.role.split("|")) ) return true
				}
			}

			// ######### Role & Authorized Level
			//กรณี Admin มีสิทธิ์ทั้งหมด ข้ามการตวจสอบนี้
			//ถ้าไม่ใช่ Admin มีสิทธิ์ตาม role หรือ authorized
			if(userdata.role !== "admin"){
				if(permission[action].role || permission[action].authorized){
					let allow = false
					if(permission[action].role){
						if(MagbizGeneral.includes(userdata.role.split("|"), permission[action].role.split("|"))){
							allow = true
						}
						if(MagbizGeneral.includes(["Owner"], permission[action].role.split("|"))){
							if(fields.owner_id === userdata.id){
								allow = true
							}
						}
						if(MagbizGeneral.includes(["Perform"], permission[action].role.split("|"))){
							if(fields.perform_id === userdata.id){
								allow = true
							}
						}
						if(MagbizGeneral.includes(["Creator"], permission[action].role.split("|"))){
							if(fields.created_user_id === userdata.id){
								allow = true
							}
						}
						if(MagbizGeneral.includes(["OwnerUser"], permission[action].role.split("|"))){
							if(fields.owner_id === userdata.username){
								allow = true
							}
						}
						if(MagbizGeneral.includes(["PerformUser"], permission[action].role.split("|"))){
							if(fields.perform_id === userdata.username){
								allow = true
							}
						}
						if(MagbizGeneral.includes(["CreatorUser"], permission[action].role.split("|"))){
							if(fields.created_user_id === userdata.username){
								allow = true
							}
						}
					}
					if(permission[action].authorized && userdata.user_authorized){
						if(MagbizGeneral.includes(userdata.user_authorized.split("|"), permission[action].authorized.split("|"))){
							allow = true
						}
					}
					if(!allow) return false
				}
			}

			// ######### Status Level
			//เช็ค deny status
			if(permission[action].denyStatus){
				if(MagbizGeneral.includes([fields.status], permission[action].denyStatus.split("|"))){
					return false
				}
			}
			// เช็ค allow status
			if(permission[action].allowStatus){
				if(!MagbizGeneral.includes([fields.status], permission[action].allowStatus.split("|"))){
					return false
				}
			}

			return true
		}

		return false
	}

	static permissonDocumentStatus = (action, fields, mode, userdata, permission) => {
		if (!MagbizUI.checkRoleAuthorized(action, fields, mode, userdata, permission)) return false
		switch(action) {
			 case "edit":{
				 if(fields.status === "Draft") return true
				 return false
			 }
			 case "delete":{
				 if(fields.status === "Cancelled") return true
				 return false
			 }
			 case "status_Signed":{
				 if(fields.status === "Draft") return true
				 return false
			 }
			 case "status_Draft":{
				 if(fields.status === "Cancelled") return true
				 return false
			 }
			 case "status_Cancelled":{
				 if(fields.status !== "Cancelled"){
					 if(MagbizGeneral.includes([userdata.role], ["admin","it"])) return true
					 if(fields.status === "Draft" && userdata.id === fields.created_user_id) return true
				 }
				 return false
			 }
			 default: {return true}
		}
	}

	static permissonBestServiceStatus = (action, fields, mode, userdata, permission) => {
		if (!MagbizUI.checkRoleAuthorized(action, fields, mode, userdata, permission)) return false
		switch(action) {
			 case "edit":{
				 if(fields.status !== "Verified" && fields.status !== "Cancelled" &&
			 			(MagbizGeneral.includes([userdata.role], ["admin","it","cs"]) || userdata.id === fields.owner_id || userdata.id === fields.perform_id || userdata.id === fields.created_user_id) ) return true
				 return false
			 }
			 case "delete":{
				 if(fields.status === "Cancelled" && MagbizGeneral.includes([userdata.role], ["admin","it"])) return true
				 return false
			 }
			 case "status_Noted":{
				 if(fields.status === "Opened" && (userdata.id === fields.owner_id || fields.owner_id === 0) ) return true
				 return false
			 }
			 case "status_Rejected":{
				 if(fields.status === "Opened" && userdata.id === fields.owner_id ) return true
				 return false
			 }
			 case "status_Worked":{
				 if(fields.status === "Noted" && userdata.id === fields.owner_id) return true
				 return false
			 }
			 case "status_CancelNoted":{
				 if(fields.status === "Noted" && userdata.id === fields.owner_id) return true
				 return false
			 }
			 case "status_Failed":{
				 if(fields.status === "Worked" && userdata.id === fields.owner_id) return true
				 return false
			 }
			 case "status_Closed":{			// For admin|cs|it
				 if(fields.status === "Worked") return true
				 return false
			 }
			 case "status_Verified":{		// For admin|cs|it
				 if(fields.status === "Closed" || fields.status === "Opened") return true
				 return false
			 }
			 case "status_Opened":{			// For admin|cs|it
				 if(fields.status === "Draft" || fields.status === "Cancelled" || fields.status === "Verified") return true
				 return false
			 }
			 case "status_Cancelled":{
				 if(userdata.role === "admin" || userdata.id === fields.created_user_id) return true
				 return false
			 }
			 default: {return true}
		}
	}

	static permissonLeaveStatus = (action, fields, mode, userdata, permission) => {
		if (!MagbizUI.checkRoleAuthorized(action, fields, mode, userdata, permission)) return false
		switch(action) {
			 case "edit":{
				 if((fields.status === "Opened" || fields.status === "Rejected") && userdata.id === fields.created_user_id ) return true
				 if((fields.status === "Opened" || fields.status === "Rejected") && userdata.id === fields.owner_id ) return true
				 if((fields.status === "Signed" || fields.status === "Rejected") && MagbizGeneral.includes([userdata.role], ["admin","hr","hr_admin"]) ) return true
				 return false
			 }
			 case "delete":{
				 if(fields.status === "Cancelled" && userdata.role === "admin") return true
				 return false
			 }
			 case "status_Signed":{
				 if((fields.status === "Opened" || fields.status === "Rejected") && (userdata.id === fields.owner_id || userdata.role === "admin") ) return true
				 return false
			 }
			 case "status_Rejected":{
				 if(fields.status === "Opened" && (userdata.id === fields.owner_id || userdata.role === "admin") ) return true
				 return false
			 }
			 case "status_Closed":{
				 if((fields.status === "Signed" || fields.status === "Opened") && MagbizGeneral.includes([userdata.role], ["admin","hr","hr_admin"])) return true
				 return false
			 }
			 case "status_NotVerified":{
				 if((fields.status === "Signed" || fields.status === "Opened") && MagbizGeneral.includes([userdata.role], ["admin","hr","hr_admin"])) return true
				 return false
			 }
			 case "status_Cancelled":{
				 if(userdata.id === fields.created_user_id || userdata.role === "admin") return true
				 return false
			 }
			 default: {return true}
		}
	}

	static permissonAStatus = (action, fields, mode, userdata, permission) => {
		if (!MagbizUI.checkRoleAuthorized(action, fields, mode, userdata, permission)) return false
		switch(action) {
			 case "delete":{
				 if(fields.status === "N") return true
				 return false
			 }
			 default: {return true}
		}
	}

	static permissonActiveStatus = (action, fields, mode, userdata, permission) => {
		if (!MagbizUI.checkRoleAuthorized(action, fields, mode, userdata, permission)) return false
		switch(action) {
			 case "delete":{
				 if(fields.status === "Inactive") return true
				 return false
			 }
			 default: {return true}
		}
	}

	static getChartTimeSeries = (data, haveAnnotation) => {
		const chartdata = data.map(item => {
			const [dateStr, ...rest] = item
			let prest = rest
			if(haveAnnotation){
				prest = rest.map((itm) => {
					if(itm === "null") return null
					return itm
				})
			}
			const date = new Date(dateStr)
			return [date, ...prest]
		})
		return chartdata
	}

}

export class MagbizEvent{

	static onChangeStatus = (props, detail, new_status, new_status_action_text, master) => {
		if(!props.info[detail.name]) return
		const tlang = props.intl.formatMessage
		Swal.fire({
			title: tlang({id:"confirmation"}) + " ?",
			text: tlang({id:"question_confirm_operation_status"}) + " : " + (new_status_action_text ? new_status_action_text : tlang({"id":"status."+new_status}) ) ,
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: tlang({id:"no"}),
			confirmButtonText: tlang({id:"yes_confirm"})
		}).then((result) => {
			if (result.value) {
				let fields = {
					 id : props.info[detail.name].id,
					 status : new_status
				}
				if(master){
					fields.master = master
				}
				props.actionPerformInfo("INFO_OPERATION", {action:detail.actionPerform.status, body:fields, fdata:detail.name+"_operation", callback:((success) => {
					if(success){
						props.actionPerformInfo("INFO_OPERATION", {action:detail.actionPerform.load, body:fields, fdata:detail.name})
					}
				})})
			}
		})
	}

	static alert = (text) => {
		Swal.fire({
			//title: title,
			text: text ,
			icon: 'info',
			confirmButtonColor: '#3085d6',
			confirmButtonText: "OK"
		}).then((result) => {
			//console.log("OK")
		})
	}

	static alertWait = async (text, icon) => {
		await Swal.fire({
			//title: title,
			text: text ,
			icon: (icon ? icon : 'info'),
			confirmButtonColor: '#3085d6',
			confirmButtonText: "OK"
		})
	}

	static confirm = async (text, translate) => {
		const result = await Swal.fire({
		  //title: 'Are you sure?',
		  text: text,
		  icon: 'question',
		  showCancelButton: true,
		  confirmButtonColor: settings.theme.primary.dark,
		  confirmButtonText: translate("yes_confirm"),
		  cancelButtonColor: 'grey',
		  cancelButtonText: translate("cancel")
		})
		return result.dismiss === "cancel" ? false : true
	}

}

export class MagbizStorage{

	static writeLSObject = (object_name, key, value) => {
		let object = {}
		if(localStorage.hasOwnProperty(object_name)){
			object = JSON.parse(localStorage.getItem(object_name))
		}
		object[key] = value
		localStorage.setItem(object_name, JSON.stringify(object))
		return object
	}

	static writeLS = (field_name, value) => {
		localStorage.setItem(field_name, value)
	}

	static readLSObject = (object_name, default_value) => {
		if(localStorage.hasOwnProperty(object_name)){
			return JSON.parse(localStorage.getItem(object_name))
		}
		return (default_value ? default_value : {})
	}

	static readLS = (field_name, default_value) => {
		if(localStorage.hasOwnProperty(field_name)){
			return localStorage.getItem(field_name)
		}
		return (default_value ? default_value : "")
	}

}

export class MagbizUser{

	static checkAuthorized = (item, userdata) => {
		let permissionShow = true
		if(item.allowUser || item.allowUserRole || item.allowAuthorized || item.allowOnlyUser || item.allowShop){
			permissionShow = false
			if(item.allowUser && item.allowUser.indexOf(userdata.user_type) !== -1){
				permissionShow = true
			}else if(item.allowUserRole && MagbizGeneral.includes(item.allowUserRole, userdata.role.split("|"))){
				permissionShow = true
			}else if(item.allowAuthorized && userdata.user_authorized && MagbizGeneral.includes(item.allowAuthorized, userdata.user_authorized.split("|"))){
				permissionShow = true
			}else if(item.allowOnlyUser && item.allowOnlyUser.indexOf(userdata.username) !== -1){
				permissionShow = true
			}
		}
		if(item.allowOrg && permissionShow){
			permissionShow = false
			if(MagbizGeneral.includes(item.allowOrg, [parseInt(userdata.company_id)])){
				permissionShow = true
			}
		}
		if(item.allowDataConfig && permissionShow){
			permissionShow = false
			if(userdata.data_config){
				const dataKeys = Object.keys(userdata.data_config)
				if(MagbizGeneral.includes(item.allowDataConfig, dataKeys)){
					permissionShow = true
				}
			}
		}
		if(permissionShow){
			if(item.denyUserRole && MagbizGeneral.includes(item.denyUserRole, userdata.role.split("|"))){
				permissionShow = false
			}
			if(item.denyOrg && MagbizGeneral.includes(item.denyOrg, [parseInt(userdata.company_id)])){
				permissionShow = false
			}
			// item.denies is array of Object {name, items}
			if(item.denies){
				for(let i=0;i<item.denies.length;++i){
					if(MagbizGeneral.includes(item.denies[i].items, [userdata[item.denies[i].name]])){
						permissionShow = false
						break
					}
				}
			}
		}
		return permissionShow
	}

}
