import React from "react";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

import {MagbizGeneral} from '../../utils/Magbiz'
import settings from '../../settings'

const styles = theme => ({
  root: {
    padding: 0,
    verticalAlign:"top"
  },
  nakedInput: {
    fontSize: "0.7rem",
    lineHeight:"20px",
    minHeight:20,
    padding: "1px 5px",
    width: "calc(100% - 10px)",
    backgroundColor:"#fafafa",
    borderBottom: "1px solid #f2f2f2",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 2,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 2px rgba(0,123,255,.25)"
    }
  },
  multiline: {},
  disabled: {
    color: "black",
    backgroundColor:"transparent",
  },
  disabledNoLine: {
    color: "black",
    backgroundColor:"transparent",
    borderBottom: "none",
  },
});

class KTInputField extends React.Component {

  state = {
    value: this.props.defaultValue+""
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.disabled !== this.props.disabled) {
      return true
    }else if (nextProps.defaultValue !== this.props.defaultValue) {
      this.setState({value: nextProps.defaultValue+""})
      return false
    }else if (nextState !== this.state) {
      return true
    }
    return false
  }

  _onChange = (event) => {
    let newvalue = event.target.value
    if(this.props.uppercase && newvalue){
      newvalue = newvalue.toUpperCase()
    }
    if(typeof this.props.maxLength !== "undefined" && newvalue){
      newvalue = newvalue.substr(0,this.props.maxLength)
    }
    this.setState({value: newvalue})
  }

  _onBlur = (event) => {
    if(this.props.onChange){
      this.props.onChange(event)
    }
  }

  render(){

    const { classes } = this.props
    const props = this.props

    let valueWithFormat = this.state.value
    if(this.props.type.startsWith("decimal")){
      if(valueWithFormat === "0"){
        valueWithFormat = ""
      }else if(valueWithFormat !== "" && props.disabled){
        valueWithFormat = MagbizGeneral.toMoney(parseFloat(valueWithFormat.replace(",","")), parseInt(this.props.type.split(",")[1]))
      }
    }else if(this.props.type === "int"){
      if(valueWithFormat === "0"){
        valueWithFormat = ""
      }
    }

    let inputPropsStyle = {};
    if(props.uppercase){
      inputPropsStyle.textTransform = "uppercase"
    }
    if (props.dotField) {
      inputPropsStyle.borderBottom = "1px solid #BDBDBD";
    }
    if (props.underlineColor) {
      inputPropsStyle.borderBottom = "1px solid " + props.underlineColor;
    }
    if (props.fontSize) {
      inputPropsStyle.fontSize = props.fontSize;
    }
    if (props.lineHeight) {
      inputPropsStyle.lineHeight = props.lineHeight;
    }
    if (props.padding) {
      inputPropsStyle.padding = props.padding;
    }
    if(props.mytheme){
      if(!props.disabled){
        inputPropsStyle.backgroundColor = settings.theme.mytheme.input;
      }
    }else{
      if(props.required && valueWithFormat === ""){
        inputPropsStyle.backgroundColor = "rgb(255, 230, 230)";
      }
    }

    if (props.center) {
      inputPropsStyle.textAlign = "center";
    }
    if (props.align) {
      inputPropsStyle.textAlign = props.align;
    }
    if (props.multiline) {
      inputPropsStyle.overflow = "hidden"
      inputPropsStyle.paddingTop = 2
      inputPropsStyle.paddingBottom = 3
      inputPropsStyle.lineHeight = "20px"
      //inputPropsStyle.paddingTop = 5
    }
    if (props.nolineOnDisabled) {
      if(props.disabled){
        inputPropsStyle.borderBottom = "none";
      }
    }
    if(props.pointer){
      inputPropsStyle.cursor = "pointer"
    }
    if (props.color) {
      inputPropsStyle.color = props.color;
    }

    return (
        <InputBase
          name={props.name}
          placeholder={props.placeholder}
          multiline={props.multiline}
          value={valueWithFormat}
          disabled={props.disabled}
          type={this.props.type === "password" ? "password" : "text"}
          style={{
            width: props.width ? props.width : "100%"
          }}
          inputProps={{ style: inputPropsStyle }}
          classes={{
            root: classes.root,
            input: classes.nakedInput,
            multiline: classes.multiline,
            disabled: (this.props.fieldOnTable ? classes.disabledNoLine : classes.disabled)
          }}
          onChange={event => this._onChange(event) }
          onBlur={event => this._onBlur(event) }
        />
    );
  }

}

export default withStyles(styles)(KTInputField);
