import React,{Fragment, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Swal from 'sweetalert2'
import {Grid, CircularProgress} from '@material-ui/core';

import {translate} from '../../lang'
import {MagbizGeneral, MagbizEvent, MagbizDateTime} from '../../utils/Magbiz'
//import {useInfoOperation} from '../../utils/MagbizHook'
//import settings from '../../settings'
import {KTButton} from '../../KT'
import DocDefaultContent from './DocDefaultContent'

const FormView = React.memo(props => {

  const module = props.module
  const info = useSelector(state => state.info[module.name])
  const action = useSelector(state => state.info.action[module.name])
  const [fields, setFields] = useState(null)
  const [timestamp, setTimestamp] = useState(null)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setFields(fetchFieldsValue(info))
    setTimestamp(MagbizDateTime.nowDateTime())
  }, [info])

  const fetchFieldsValue = (detail) => {
    const fetchGroupValue = (objects, group) => {
      if(group.fields){
        const sfields = Object.values(group.fields).reduce((objects1, ofield) => {
         let field = {...ofield}
         if(detail){
           if(field.type === "date" && detail[field.name] && detail[field.name].length > 10){
             field.value = detail[field.name].substr(0,10)
           }else if(field.type === "time" && !detail[field.name] && field.datefield && detail[field.datefield]){
             field.value = MagbizGeneral.right(detail[field.datefield],8)
           }else{
             field.value = detail[field.name]
           }
           if(props.onAssign){
             props.onAssign(field)
           }
         }
         objects1[field.name] = field
         return objects1
        }, {})
        return {...objects, ...sfields}
      }else{
        return objects
      }
    }
    let sfields = Object.values(module.group_fields).reduce((objects, group) => {
      return fetchGroupValue(objects, group)
    }, {})
    return sfields
  }

  const setFieldValue = async (name, value) => {
    let sfields = {...fields}
    sfields[name].value = value
    if(props.onChange){
      const results = await props.onChange(name, sfields)
      sfields = results.reduce((objects, item) => {
          objects = {...objects, [item.name] : {...objects[item.name], value: item.value} }
          return objects
      }, sfields)
    }
    setFields(sfields)
    setTimestamp(MagbizDateTime.nowDateTime())
  }

  const getItemValue = (item) => {
    let value = item.value
    if(item.type.startsWith("decimal")){
      if(typeof item.value === "string"){
        value = parseFloat(item.value.replace(",",""))
        if(isNaN(value)) value = 0
      }
    }else if(item.type === "int"){
      if(typeof item.value === "string"){
        value = parseInt(item.value.replace(",","").replace(/[^\d.-]/g, ''))
        if(isNaN(value)) value = 0
      }
    }else if(item.type === "time"){
      if(typeof item.value === "string"){
        if(item.value.length === 5){
          value = item.value + ":00"
        }
      }
      if(!value){
        value = "00:00:00"
      }
    }
    return value
  }

  const saveData = () => {
    let incomplete = false
    let sfields =  Object.values(fields).reduce((object, item) => {
      if(item.noSave) return object
      const value = getItemValue(item)
      if(item.required){
        if(value === "" || value === 0 || value === null || (item.type === "time" && value === "00:00:00") ){
          incomplete = true
        }
      }
      if(item.datefield && typeof object[item.datefield] !== "undefined"){
        return {...object, [item.datefield] : object[item.datefield]+" "+value}
      }
      return {...object, [item.name] : value}
    }, {})
    if(incomplete){
      MagbizEvent.alert(translate("error.incomplete_form"))
      return null
    }

    if(module.additional_field){
      sfields = {...sfields,...module.additional_field}
    }
    return sfields
  }

  const onFieldChange = async (event) => {
    const target = event.target
    await setFieldValue(target.name, target.value)
  }

  const onDateTimeChange = async (dateObj) => {
    await setFieldValue(dateObj.name, dateObj.value)
  }

  const onFieldSelect = async (name, selectId) => {
    await setFieldValue(name, selectId)
  }

  const onSave = async () => {
    const result = await Swal.fire({
      title: translate("confirmation"),
      text: translate("question_confirm_save"),
      type: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: translate("no"),
      confirmButtonText: translate("yes_confirm")
    })
    if(result && result.value){
      setSaving(true)
      const results = await props.onSave(saveData())
      setSaving(false)
      if(!MagbizGeneral.isEmptyObject(results)){
        const sfields = results.reduce((objects, item) => {
            objects = {...objects, [item.name] : {...objects[item.name], value: item.value} }
            return objects
        }, fields)
        setFields(sfields)
        setTimestamp(MagbizDateTime.nowDateTime())
      }
    }
  }

  const containerStyle = {
    width:props.width,
    height:props.height,
    backgroundColor:"white",
    border:"1px solid #f2f2f2",
    paddingTop:5,
    paddingBottom:5,
    paddingLeft:2,
    paddingRight:2
  }

  if(!fields || !timestamp) return (
    <div style={containerStyle}>
      <Grid container direction="column" justify="center" alignItems="center" style={{width:containerStyle.width,height:containerStyle.height}} >
        <CircularProgress />
        <div style={{fontSize:16}}>Please wait...</div>
      </Grid>
    </div>
  )

  return (
    <div style={containerStyle}>
      <Grid container width={props.width} justify="center" >
        {module.group_fields && Object.values(module.group_fields).map((groupObj, groupIndex) => {
          return (
            <Fragment key={groupIndex} >
              <DocDefaultContent groupObj={groupObj} width={props.width} mode={props.mode} fields={fields}
                onFieldChange={onFieldChange}
                onDateTimeChange={onDateTimeChange}
                onFieldSelect={onFieldSelect}
              />
            </Fragment>
          )
        })}
        {props.onSave && props.mode !== "view" && !props.disabledPerform &&
        <KTButton size="small" loading={action || saving} onClick={onSave} style={{width:props.width-20}}>{translate("save")}</KTButton>}
      </Grid>
    </div>
  )
})

export default FormView
