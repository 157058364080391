const moduleName = "tec"

export default {
  name : moduleName,
  api : {
    server : "http://api.magbizcloud.com:8892/",
    app_module : "tec",
  },
  title : "TEC",
  description : "TEC Management System",
  logo : "assets/"+moduleName+"/logo.png",               // 128*128 Color Logo
  logo_head : "assets/"+moduleName+"/logo-head.png",      // 200*34 Color Logo
  theme : {
    primary: {
      light: "rgba(66,165,245,0.8)",
      main: "rgba(66,165,245,1)",
      dark: "rgba(47,152,238,1)",
      contrastText: '#fff'
    },
    secondary: {
      light: "rgb(255,255,255,0.9)",
      main: "rgb(255,255,255,0.95)",
      dark: "rgb(255,255,255,1)",
      contrastText: '#424242'
    }
  },
  menu:{
    main_menu: {
  		title: "sidebar.section.main_menu",
  		list: [
  			//{
  			//	"menu_title": "sidebar.dashboard",
  			//	"menu_icon": "view-dashboard",
  			//	"path": "/"+moduleName+"/dashboard",
  			//	"child_routes": null
  			//},
        {
  				"menu_title": "sidebar.material_requests",
  				"menu_icon": "settings-square",
  				"path": "/"+moduleName+"/material_requests",
  				"child_routes": null
  			},
  		]
  	},
    setting_menu: {
      allowUser:["admin"],
  		title: "sidebar.section.setting_menu",
  		list: [
  			{
  				"menu_title": "sidebar.technicians",
  				"menu_icon": "accounts-list",
  				"path": "/"+moduleName+"/technicians",
          allowUser: ["admin"],
  			},
        {
  				"menu_title": "sidebar.parts",
  				"menu_icon": "collection-bookmark",
  				"path": "/"+moduleName+"/parts",
          allowUser: ["admin"],
  			},
  		]
  	}
  }
}
