import React, {Fragment,Suspense,useEffect,useState,useRef} from 'react';
import { BrowserRouter as Router, Switch} from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import Measure from 'react-measure';
import { Provider as ReduxProvider, useSelector} from 'react-redux';
import {Helmet} from "react-helmet";
import Fullscreen from 'react-fullscreen-crossbrowser';

import moment from 'moment';
import 'moment/locale/th';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';

import {MuiThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import settings,{AppRoute} from  './settings';
import appLang from './lang';
import {appTheme, DivSW} from './theme/appTheme';
import { configureStore } from './redux';

import {useUser, useApp} from './utils/MagbizHook'

import TopNavBar from  './navigation/TopNavBar';
import Drawer from  './navigation/Drawer';
import MobileMenu from  './navigation/MobileMenu';
import {KTLoading} from  './KT';
import {MagbizEvent} from './utils/Magbiz'
import api from './api';

import Login from './pages/Login';

moment.defaultFormat = "YYYY-MM-DD HH:mm:ss";
export const store = configureStore()

//Disable certain warning text
/*const backup = console.warn
console.warn = function filterWarnings(msg) {
  const supressedWarnings = ['google.maps.event.addDomListener() is deprecated, use the standard addEventListener() method instead'];
  if (!supressedWarnings.some(entry => msg.includes(entry))) {
    backup.apply(console, arguments);
  }
}*/

const MainModule = () => {

  const performApp = useApp()
  const appscreen = useSelector(state => state.app.screen)
  const lang = useSelector(state => state.app.lang)

  const [leftDrawer, setLeftDrawer] = useState(false)
  const mobileMoreAnchorEl = useRef(null)

  const onMobileMenuOpen = (event) => {
    mobileMoreAnchorEl.current = event.currentTarget
  }

  const onMobileMenuClose = (event) => {
    mobileMoreAnchorEl.current = null
  }

  const onToggleDrawer = (open) => {
    setLeftDrawer(open)
  }

  const onItemSelected = (item) => {
    setLeftDrawer(false)
  }

  const onAppbarResize = (contentRect) => {
    performApp('APP_SETUP_SCREEN', {...appscreen ,
      appBarSize:{width: contentRect.bounds.width, height: contentRect.bounds.height},
    })
  }

  const onPageResize = (contentRect) => {
    performApp('APP_SETUP_SCREEN', {...appscreen ,
      width:contentRect.bounds.width, height:contentRect.bounds.height+appscreen.appBarSize.height
    })
  }

  //console.log("Render MainModule : ", leftDrawer)

  return (
    <Fullscreen enabled={appscreen.isFullscreen}
        onChange={isFullscreenEnabled => performApp("APP_SETUP_SCREEN", {...appscreen , isFullscreen:isFullscreenEnabled})} >
      <MuiPickersUtilsProvider utils={MomentUtils} locale={lang} moment={moment}>
        <Router>
          <Switch>
            <DivSW>
              <Measure bounds onResize={onAppbarResize} >
                {({ measureRef }) => <div ref={measureRef}>
                  <TopNavBar onToggleDrawer={onToggleDrawer} onMobileMenuOpen={onMobileMenuOpen} />
                </div>}
              </Measure>
              <MobileMenu anchor={mobileMoreAnchorEl.current} onClose={onMobileMenuClose} />
              <Drawer visible={leftDrawer} onItemSelected={onItemSelected} />
              <Measure bounds onResize={onPageResize} >
                {({ measureRef }) =>
                <div ref={measureRef} style={{overflow:"hidden", width: '100%', height: 'calc(100vh - '+(appscreen.appBarSize.height)+'px)', backgroundColor:settings.theme.secondary.main}}>
                  <Suspense fallback={<div></div>}>
                    <AppRoute module_name={settings.name} />
                  </Suspense>
                </div>}
              </Measure>
            </DivSW>
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </Fullscreen>
  )
}

const AppWithTheme = () => {

  const performUser = useUser()
  const userdata = useSelector(state => state.user.userdata)
  const [preLoad, setPreload] = useState("loading")

  useEffect(() => {
    doPreLoading()
  }, [])

  useEffect(() => {
    if(preLoad){
      if(preLoad !== "loading" && preLoad !== "loaded"){
        window.location = preLoad
      }
    }
  }, [preLoad])

  const doPreLoading = async () => {
    if(settings.name === "survtrex" && window.location.href.indexOf("?LGNID#") !== -1){
      const paths = window.location.href.split("?")
      if(paths.length > 0 && paths[paths.length - 1].startsWith("LGNID#")){
        if(!userdata){
          const lgnid = paths[paths.length - 1].replace("LGNID#","")
          const username = lgnid.split("-")[0]
          const result = await api("user/loginsmartfarmsession", {lgnid:lgnid, username:username})
          if(!result.error){
            performUser('USER_LOGIN_SUCCESS', result.info)
            setPreload(paths[0])
          }else{
            await MagbizEvent.alertWait(result.error, "error")
            setPreload(paths[0])
          }
        }else{
          setPreload(paths[0])
        }
      }
    }if(settings.name === "fms" && window.location.href.indexOf("?LGNID#") !== -1){
      const paths = window.location.href.split("?")
      if(paths.length > 0 && paths[paths.length - 1].startsWith("LGNID#")){
        if(!userdata){
          const lgnid = paths[paths.length - 1].replace("LGNID#","")
          const username = lgnid.split("-")[0]
          const result = await api("user/loginfromsurvtrex", {lgnid:lgnid, username:username})
          if(!result.error){
            performUser('USER_LOGIN_SUCCESS', result.info)
            setPreload(paths[0])
          }else{
            await MagbizEvent.alertWait(result.error, "error")
            setPreload(paths[0])
          }
        }else{
          setPreload(paths[0])
        }
      }
    }else if((settings.name === "dsa" || settings.name === "dsa-sandbox") && window.location.href.indexOf("?MLGNID#") !== -1){
      const paths = window.location.href.split("?")
      if(paths.length > 0 && paths[paths.length - 1].startsWith("MLGNID#")){
        if(!userdata){
          const mlgnid = paths[paths.length - 1].replace("MLGNID#","")
          const username = mlgnid.split("-")[0]
          const result = await api("user/loginfrommobilesession", {mlgnid:mlgnid, username:username})
          if(!result.error){
            performUser('USER_LOGIN_SUCCESS', result.info)
            setPreload(paths[0].replace("[query]","?"))
          }else{
            await MagbizEvent.alertWait(result.error, "error")
            setPreload(paths[0])
          }
        }else{
          setPreload(paths[0].replace("[query]","?"))
        }
      }
    }else{
      setPreload("loaded")
    }
  }

  if(preLoad === "loading"){
    return (
      <div style={{fontSize:24, color:"#888", width:"100%", lineHeight: window.innerHeight+"px", height: window.innerHeight, textAlign:"center"}}>Loading...</div>
    )
  }else if(preLoad !== "loaded"){
    return (
      <div style={{fontSize:24, color:"#888", width:"100%", lineHeight: window.innerHeight+"px", height: window.innerHeight, textAlign:"center"}}>Please wait...</div>
    )
  }

  //console.log("Render AppWithTheme : ", userdata)

  return (
    <MuiThemeProvider theme={appTheme}>
      <Fragment>
        <CssBaseline />
        <Helmet>
            <title>{settings.title} - {settings.description}</title>
            <link rel="shortcut icon" href={window.location.origin+"/assets/"+settings.name+"/icon.png"} />
        </Helmet>
        <KTLoading />
        {(userdata || window.location.href.indexOf("/"+settings.name+"/sites/") !== -1 ? <MainModule /> : <Login />)}
        <NotificationContainer />
      </Fragment>
    </MuiThemeProvider>
  )

}

const AppWithIntl = () => {
  const lang = useSelector(state => state.app.lang)
  const {locale, messages} = appLang[lang]

  return (
    <IntlProvider locale={locale} messages={messages}>
      <AppWithTheme />
    </IntlProvider>
  )
}

const App = () => {
  return (
    <ReduxProvider store={store}>
      <AppWithIntl />
    </ReduxProvider>
  )
}

export default App
