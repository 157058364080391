import React from 'react';

import {
    ExcelExport,
    ExcelExportColumn,
    //ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export';
//import { aggregateBy, process } from '@progress/kendo-data-query';

import {KTButton, KTIcon} from '../../KT'

/*
const aggregates = [ { field: 'UnitPrice', aggregate: 'sum' } ];
const group = [ { field: 'Discontinued', aggregates: aggregates } ];
const data = process(products, {
    group: group
}).data;
const total = aggregateBy(products, aggregates);

const CustomGroupHeader = (props) => (`Discontinued: ${props.value}`);

const CustomGroupFooter = (props) => (`SUM:  ${(props.aggregates.UnitPrice.sum).toFixed(2)}`);

const CustomFooter = (props) =>
    (`Total ${props.column.title}:  ${total.UnitPrice.sum}`);*/

class KTDownloadExcel extends React.Component {
    _exporter;
    export = () => {
        this._exporter.save();
    }
    render() {
        if(!Boolean(this.props.columns)) return null
        let data = this.props.data
        if(this.props.summary && data){
          for(let k=0;k<this.props.summary.length;++k){
            let value = 0
            const key = this.props.summary[k].key
            if(this.props.summary[k].type === "sum"){
              value = this.props.data.reduce((sum, item) => {
                return sum + item[key]
              }, 0)
            }
            data.push({[this.props.summary[k].labelKey]:this.props.summary[k].label, [this.props.summary[k].key]:value})
          }
        }
        return (
            <div>
                <KTButton size="small" onClick={this.export} style={{fontSize:"0.7rem", textTransform:"none"}}  >
                  <span><KTIcon vicon="file-excel" vicontype="MaterialCommunityIcons" size={"0.7rem"} color="white" />&nbsp;</span>Excel
                </KTButton>

                <ExcelExport
                    data={data}
                    fileName={this.props.filename}
                    ref={(exporter) => { this._exporter = exporter; }}
                >
                  {this.props.columns.map((item, index) => {
                    if(this.props.datatype === "report"){
                      const cwidth = this.props.docwidth * parseFloat(item.width) / 100
                      let cellOptions = {}
                      if(item.type === "date"){
                        cellOptions = {format:"DD/MM/YYYY"}
                      }else if(item.type === "int"){
                        cellOptions = {format:"####"}
                      }else if(item.type === "decimal,2"){
                        cellOptions = {format:"#,###,##0.00"}
                      }else if(item.type === "decimal,3"){
                        cellOptions = {format:"#,###,##0.000"}
                      }else if(item.type === "decimal,0"){
                        cellOptions = {format:"#,###,##0"}
                      }
                      return (
                        <ExcelExportColumn key={index} field={"field"+index} title={(item.name ? item.name.replace("<br/>"," ") : "")} width={cwidth} cellOptions={cellOptions} />
                      )
                    }else{
                      return (
                        <ExcelExportColumn key={index} field={item.dataKey} title={(item.label ? item.label.replace("<br/>"," "): "")} width={item.width} />
                      )
                    }
                  })}
                </ExcelExport>
            </div>
        );
    }
}

export default KTDownloadExcel
